import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import moment from 'moment/moment';


import './Resources/dashboard.css';
import axios from './../axios.js';
import Navbar from './Supportingfiles/navbar';
import Sidebar from './Supportingfiles/sidebar';
//import Create_customers from './create_customers';
import {useRef as useref} from 'react';
import {useReactToPrint} from 'react-to-print';


import Chart from "chart.js/auto";
import { Line, Bar } from "react-chartjs-2";

function Admin_customer_emicard(){



    const imgpath = 'https://api.pragatiprathmik.com/storage/';


 
{/* TRANSACTIONS TABLE COLUMNS MANAGEMENT VARIABLES START */}
const[cd_col1_val,setcd_col1_val] = useState(0);//column1
const[cd_col2_val,setcd_col2_val] = useState(0);//column2
const[cd_col3_val,setcd_col3_val] = useState(0);//column3
const[cd_col4_val,setcd_col4_val] = useState(0);//column4
const[cd_col5_val,setcd_col5_val] = useState(0);//column5
const[cd_col6_val,setcd_col6_val] = useState(0);//column6
const[cd_col7_val,setcd_col7_val] = useState(0);//column7
const[cd_col8_val,setcd_col8_val] = useState(0);//column8
const[cd_col9_val,setcd_col9_val] = useState(0);//column9
const[cd_col10_val,setcd_col10_val] = useState(0);//column10
const[cd_col11_val,setcd_col11_val] = useState(0);//column11
{/* const[cd_col12_val,setcd_col12_val] = useState(0);//column12
const[cd_col13_val,setcd_col13_val] = useState(0);//column13
const[cd_col14_val,setcd_col14_val] = useState(0);//column14 */}




//Header
const[haddress,sethaddress]=useState();const updatehaddress = (event) => { sethaddress(event.target.value);}; const[haddress_er,sethaddress_er]=useState();
const[hcity,sethcity]=useState();const updatehcity = (event) => { sethcity(event.target.value);}; const[hcity_er,sethcity_er]=useState();
const[hstate,sethstate]=useState();const updatehstate = (event) => { sethstate(event.target.value);}; const[hstate_er,sethstate_er]=useState();
const[hpincode,sethpincode]=useState();const updatehpincode = (event) => { sethpincode(event.target.value);}; const[hpincode_er,sethpincode_er]=useState();
const[hemail,sethemail]=useState();const updatehemail = (event) => { sethemail(event.target.value);}; const[hemail_er,sethemail_er]=useState();
const[hcontact,sethcontact]=useState();const updatehcontact = (event) => { sethcontact(event.target.value);}; const[hcontact_er,sethcontact_er]=useState();


const[branch_code,setbranch_code]=useState();
const[date,setdate]=useState();
const[group_name,setgroup_name]=useState();
const[account_number,setaccount_number]=useState();
const[first_name,setfirst_name]=useState();
const[last_name,setlast_name]=useState();
const[contact,setcontact]=useState();
const[coapplicant_name,setcoapplicant_name]=useState();
const[loan_amount,setloan_amount]=useState();
const[scheme_code,setscheme_code]=useState();
const[scheme_name,setscheme_name]=useState();
const[duration,setduration]=useState();
const[processing_fee,setprocessing_fee]=useState();

    //Invoice Print Function
    const componentRef = useref();
    const handlePrint = useReactToPrint({

      content: () => componentRef.current,
    });


    function printhere(){

      setcd_col8_val(1); 
      handlePrint();

    }
 //Checking Sessions
 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logtype=sessionStorage.getItem("logType00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");
 const loan_number=sessionStorage.getItem("LNO00");


 /* PAGE VARIABLES */





//Variables for pagination
const [currentPage, setCurrentPage] = useState(1);
const [pageNumber, setpageNumber] = useState(1);



 //Error Variable
 const[errorvar,seterrorvar] = useState();

 //FILTER VARIABLES
//filter_val
const[filterval,setfilterval]=useState(0);

 
//Variables
const[data,setData] = useState([]);//SetData Variable


//ERROR VARIABLES 
const[error_msg,seterror_msg]=useState();


 useEffect(()=>{
   
  getData();
  
  },[])
 





//******************* GET LOAN APPLICATIONS DATA FUNCTION START  ********************//
function getData(){
 

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((loan_number!=null)||(loan_number!=" "))){
 
 axios.post("crm-admin-get-loan-emis-statement", {
   Log_id:logid,
   Token:token,
   
   //Code Edits for pagination
   'page' : pageNumber,

   //Filters
   'Filter_val' :filterval,
   'Loan_number' :loan_number

   })
 .then((response) => {

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("logName00");

     window.location.replace("/");
  }
  //Success Data Fetched
  if(response.data.successvar==1){
   //Setting Data
   setData(response.data.data);
   setbranch_code(response.data.branch_code);
   setgroup_name(response.data.group_name);
   setaccount_number(response.data.account_number);
   setfirst_name(response.data.first_name);
   setlast_name(response.data.last_name);
   setcontact(response.data.contact);
   setscheme_code(response.data.scheme_code);
   setscheme_name(response.data.scheme_name);
   setloan_amount(response.data.loan_amount);
   setprocessing_fee(response.data.processing_fee);
   setduration(response.data.duration);
   setcoapplicant_name(response.data.coapplicant_name);
   setdate(response.data.date);

  }

  //FILTER DATA
  //Validation Errors
  if(response.data.validatorerror){

    seterrorvar("Validation error!");

  }

  //Data Violation
  if(response.data.error==102){ 
  
     //Setting Filter Violation Errors
     seterrorvar("Violation Error");


  }


  //Cannot Fetch Data
  else{
   seterrorvar("Cannot Fetch Data !");
  }
 
 
 });
 
 }
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("logName00");

   window.location.replace("/");
 
  }
 
 }
  
 //******************* GET LOAN APPLICATIONS DATA FUNCTION END  ********************//


return(
    <>
<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Pragati Prathmik Sakh Sahkari Samiti Maryadit | Admin Dashboard</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>
    <div className="container-scroller">

     
    <div className="container-fluid page-body-wrapper">


       <Navbar />
        {/*} partial */}
        <div className="main-panel">

        

          <div className="content-wrapper pb-0">

          <div className="row">
              <div className="col-12 grid-margin create-form" id="create-form">
             {/*} <Create_cash /> */}
              </div>
          </div>


            <div className="page-header flex-wrap">
            <div className="header-left"></div>
              <div className="header-right d-flex flex-wrap mt-2 mt-sm-0">
                <div className="d-flex align-items-center">
                  <a href="#">
                    <p className="m-0 pe-3">ADMIN PANEL</p>
                  </a>
                  <a className="ps-3 me-4" href="#">
                    <p className="m-0">CUSTOMER EMI CARD</p>
                  </a>
                </div>
             
              </div>
            </div>

         
            <button class="btn btn-warning" onClick={()=>{
            document.getElementById("upd-header-card").style.display="block";
            }}>update header</button>
             <button type="button" style={{backgroundColor:"#196fb0", marginLeft:"2rem"}} class="btn btn-secondary btn-icon-text" onClick={handlePrint}> Print <i class="mdi mdi-file-document"></i>
             </button>
             <br/>
          

           {/* UPDATE HEADER INFO SECTION START */}
            <div class="card" id="upd-header-card" style={{display:"none"}}>
              
              <div class="card-body" style={{textAlign:"left"}}>
                <h4 class="card-title">Update Header Information</h4>
                <div class="form-sample">
                  <p class="card-description">update header information</p>
                  
                  
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Header Address</label>
                        <div class="col-sm-9">
                          <input type="text" class="form-control" id="haddress" value={haddress} onChange={updatehaddress} />
                          <code>{haddress_er}</code>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Header City</label>
                        <div class="col-sm-9">
                          <input type="text" class="form-control" id="hcity" value={hcity} onChange={updatehcity} />
                          <code>{hcity_er}</code>
                        </div>
                      </div>
                    </div>
                    </div>
                    <div class="row">
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Header State</label>
                        <div class="col-sm-9">
                          <input type="text" class="form-control" id="hstate" value={hstate} onChange={updatehstate} />
                          <code>{hstate_er}</code>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Header Pincode</label>
                        <div class="col-sm-9">
                          <input type="text" class="form-control" id="hpincode" value={hpincode} onChange={updatehpincode} />
                          <code>{hpincode_er}</code>
                        </div>
                      </div>
                    </div>
                    </div>
                    <div class="row">
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Header Email</label>
                        <div class="col-sm-9">
                          <input type="text" class="form-control" id="hemail" value={hemail} onChange={updatehemail} />
                          <code>{hemail_er}</code>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Header Contact</label>
                        <div class="col-sm-9">
                          <input type="text" class="form-control" id="hcontact" value={hcontact} onChange={updatehcontact} />
                          <code>{hcontact_er}</code>
                        </div>
                      </div>
                    </div>
                    </div>

                  
                  
                   {/*} <button class="btn btn-lg btn-success" style={{fontWeight:"bold"}} onClick={()=>{
                       sethaddress(document.getElementById("haddress").value);
                       sethcity(document.getElementById("hcity").value);
                       sethstate(document.getElementById("hstate").value);
                       sethpincode(document.getElementById("hpincode").value);
                       sethemail(document.getElementById("hemail").value);
                       sethcontact(document.getElementById("hcontact").value);
                    }}>Update</button> */}

                    <button class="btn btn-lg btn-outline-danger" style={{fontWeight:"bold"}} onClick={()=>{ 
                      document.getElementById("upd-header-card").style.display="none";
                    }}>
                      Close
                    </button>
                   

                 
               
                </div>
              </div>
            </div>
            {/* UPDATE HEADER INFO SECTION END */}

       

              {/* CASH TRANSACTIONS TABLE SECTION START */}
                  
             <div className="col-lg-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">

                  {/* Print Section Start */}
                  <div class="" style={{padding:"1rem"}}  id="invoice" ref={componentRef}>

                  {/* SANCTION LETTER HEADER START */}
                  <div class="offer-letter-head" style={{borderBottom:"0.2rem solid #038d8e"}}>
                  <div style={{flexDirection:"row", display:"flex"}}>
                  <div style={{width:"25%"}} >
                    <img src="images/crmlogo.png" alt="profile" style={{width:"16rem", height:"16rem"}} />
                  </div>
                  <div class="row" style={{width:"75%"}}>

                    <div class="row col-md-12">
                    <h1 style={{color:"#038d8e", marginBottom:"0", textAlign:"center"}}><b>Pragati Prathmik Sakh Sahkari Samiti Maryadit</b></h1>
                    
                    <p style={{marginTop:"0", textAlign:"center"}}><b>Address:</b> {haddress} <br/>
                    {hcity} ({hstate}), ({hpincode}) <br/><br/>
                    <b>Email:</b> {hemail}, <b>Contact:</b> {hcontact}</p>

                  
                    </div>
                    </div>
                    </div>
                    </div>
                    <br/>
                    <h3 style={{textAlign:"center"}}><b>EMI STATEMENT</b></h3>
                    <div className="table-responsive mt-3 mb-3">
                    <table class="table table-hover table-bordered" style={{textAlign:"center"}}>
                    <tbody>
                    <tr>
                        <td><b>Branch Code:</b></td>
                        <td>{branch_code}</td>
                        <td><b>Date:</b></td>
                        <td>{date}</td>
                    </tr>
                    <tr>
                        <td><b>Customer Name:</b></td>
                        <td>{first_name} {last_name}</td>
                        <td><b>Group Name:</b></td>
                        <td>{group_name}</td>
                    </tr>
                    <tr>
                        <td><b>Contact:</b></td>
                        <td>{contact}</td>
                        <td><b>Coapplicant Name:</b></td>
                        <td>{coapplicant_name}</td>
                    </tr>
                    <tr>
                        <td><b>Account Number:</b></td>
                        <td>{account_number}</td>
                        <td><b>Scheme Name:</b></td>
                        <td>{scheme_name}</td>
                    </tr>
                    <tr>
                        <td><b>Loan Amount:</b></td>
                        <td>{loan_amount}</td>
                        <td><b>Scheme Code:</b></td>
                        <td>{scheme_code}</td>
                    </tr>
                    <tr>
                        <td><b>EMIs:</b></td>
                        <td>{duration}</td>
                        <td><b>File Charge(Processing Fees):</b></td>
                        <td>{processing_fee}</td>
                    </tr>
                    </tbody>
                    </table>
                    </div>

                    

                {/*}    <h4 className="card-title">Loan Payments</h4>
                    <p className="card-description">manage your loan payments here
                    </p> */}

                   
                    <div className="table-responsive mb-3 mt-3">
                      <table className="table table-hover table-bordered">
                        <thead>
                          <tr >
                          <th><b>#</b></th>
                          <th style={{fontWeight:"bold"}} hidden={cd_col1_val==1} >Sn</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col2_val==1} >Tr No.</th>
                        {/*}    <th  style={{fontWeight:"bold"}} hidden={cd_col2_val==1} >Loan No.</th> */}
                            <th  style={{fontWeight:"bold"}} hidden={cd_col3_val==1} >EMI Date</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col3_val==1} >Payment Date</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col4_val==1} >Status</th>
                           <th  style={{fontWeight:"bold"}}  hidden={cd_col5_val==1} >Mode</th> 
                           <th  style={{fontWeight:"bold"}} hidden={cd_col6_val==1} >Principle</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col6_val==1} >Interest</th> 
                            <th  style={{fontWeight:"bold"}} hidden={cd_col6_val==1} >GST</th>
                          {/*}  <th  style={{fontWeight:"bold"}} hidden={cd_col6_val==1} >Interest</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col6_val==1} >Discount</th> */}
                            <th  style={{fontWeight:"bold"}} hidden={cd_col6_val==1} >EMI</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col6_val==1} >Staff Sign</th>
                          {/*}  <th  style={{fontWeight:"bold"}} hidden={cd_col7_val==1} >Total</th> */}
                        {/*}   <th  style={{fontWeight:"bold"}} hidden={cd_col11_val==1} >Edit</th> */}
                         {/*}   <th  style={{fontWeight:"bold"}} hidden={cd_col8_val==1} >Updated By</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col8_val==1} >Updated At</th> */}

                           {/*} <th  style={{fontWeight:"bold"}} hidden={cd_col10_val==1} >View</th> */}
                          {/*}  <th  style={{fontWeight:"bold"}} hidden={cd_col11_val==1} >Edit</th> */}
                          </tr>
                        </thead>
                        <tbody>
                        {
                       data.map((curElm,ind)=>{

                         return(
                         <>
                        <tr key={ind}>
                        <td>{ind + 1}</td>
                          <td>{curElm.EMI_sn}</td>
                          <td>{curElm.Tr_no}</td>
                      {/*}    <td>{curElm.Loan_number}</td> */}
                          <td>{curElm.EMI_date}</td>
                          <td>{curElm.Payment_date}</td>
                          <td><label className="badge badge-success" hidden={curElm.EMI_status=="due"}>paid</label><label className="badge badge-danger" hidden={curElm.EMI_status=="paid"}>due</label></td>
                          <td>{curElm.Mode}</td>
                         {/*} <td>loan emi received in cash</td> */}
                         <td>{curElm.Emi_principle}</td>
                          <td>{curElm.Emi_interest}</td>
                          <td>{curElm.Emi_gst}</td>
                          <td>{curElm.EMI}</td>
                         
                         {/*} <td>000.00</td>
                          <td>3600.00</td>
                          <td>4000.00</td> */}
                     {/*}     <td hidden={cd_col8_val==1}>{curElm.Updated_by} <span hidden={curElm.C_account_type=="admin"}>(EMP ID: {curElm.Updated_by_user})</span><span hidden={curElm.C_account_type=="employee"}>(ADMIN ID: {curElm.Updated_by_user})</span></td>
                          <td hidden={cd_col8_val==1}>{curElm.Date_updated} ({curElm.Time_updated})</td>
                       */}   
                       <td></td>
                         {/*} <td><i className="mdi mdi-pencil-box text-warning" style={{fontSize:"1.4rem"}}></i></td> */}
                        </tr>
                        </>
                         )
                         }
					   
	                  		 )
                         }
                    
                         
                        </tbody>
                      </table>
                    </div>
                    
               

                   {/* Signature Section Start */}
                    <div className="table-responsive mt-3 mb-3">
                    <table class="table table-hover table-bordered" style={{textAlign:"center"}}>
                    <tbody>
                    <tr>
                        <td><b>Member Signature:</b></td>
                        <td><b>Guaranter Signature:</b></td>
                        <td><b>Branch Seal with Signature:</b></td>
                    </tr>
                    <tr>
                        <td><br/><br/><br/></td>
                        <td><br/><br/><br/></td>
                        <td><br/><br/><br/></td>
                    </tr>
                    </tbody>
                    </table>
                    </div>

                  </div>
                </div>
              </div>
              {/* Signature Section End */}
             
              <br/><br/><br/>

            {/* CASH TRANSACTIONS TABLE SECTION END */}
            </div>
             {/* Print Section End */}

            
          </div>
          {/*} content-wrapper ends */}
          {/*} partial:partials/_footer.html */}
       {/*}   <footer className="footer">
            <div className="d-sm-flex justify-content-center justify-content-sm-between">
              <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2021 <a href="https://www.bootstrapdash.com/" target="_blank">BootstrapDash</a>. All rights reserved.</span>
              <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i className="mdi mdi-heart text-danger"></i></span>
            </div>
          </footer> */}
          {/*} partial */}
        </div>
        {/*} main-panel ends */}
      </div>
      {/*} page-body-wrapper ends */}
    </div>
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>
</>
);

//}
}
export default Admin_customer_emicard;