import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';

import './Resources/dashboard.css';
import axios from './../axios.js';
import Navbar from './Supportingfiles/navbar';
import Sidebar from './Supportingfiles/sidebar';
//import Create_loanrequests from './create_newaccounts';
import moment from 'moment/moment';



function Employee_edit_customer(){


 //Checking Sessions
 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logtype=sessionStorage.getItem("logType00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");
 const logname=sessionStorage.getItem("logName00");
 const editId=sessionStorage.getItem("EditId00");
 const branch_id=sessionStorage.getItem("branchId00");


 //SETTING DATA VARIABLES
 const[customer_id,setcustomer_id]=useState();
 const[branch_code,setbranch_code]=useState();
 const[account_number,setaccount_number]=useState();
 const[group_name,setgroup_name]=useState();
 const[first_name,setfirst_name]=useState();const updatefirst_name = (event) => { setfirst_name(event.target.value);}; const[first_name_er,setfirst_name_er]=useState();
 const[last_name,setlast_name]=useState();const updatelast_name = (event) => { setlast_name(event.target.value);}; const[last_name_er,setlast_name_er]=useState();
 const[username,setusername]=useState();const updateusername = (event) => { setusername(event.target.value);}; const[username_er,setusername_er]=useState();
 const[confirm_username,setconfirm_username]=useState();const updateconfirm_username = (event) => { setconfirm_username(event.target.value);}; const[confirm_username_er,setconfirm_username_er]=useState();
 const[password,setpassword]=useState();const updatepassword = (event) => { setpassword(event.target.value);}; const[password_er,setpassword_er]=useState();
 const[confirm_password,setconfirm_password]=useState();const updateconfirm_password = (event) => { setconfirm_password(event.target.value);}; const[confirm_password_er,setconfirm_password_er]=useState();
 const[email,setemail]=useState();const updateemail = (event) => { setemail(event.target.value);}; const[email_er,setemail_er]=useState();
 const[contact,setcontact]=useState();const updatecontact = (event) => { setcontact(event.target.value);}; const[contact_er,setcontact_er]=useState();
 const[gender,setgender]=useState();const updategender = (event) => { setgender(event.target.value);}; const[gender_er,setgender_er]=useState();
 const[date_of_birth,setdate_of_birth]=useState();const updatedate_of_birth = (event) => { setdate_of_birth(event.target.value);}; const[date_of_birth_er,setdate_of_birth_er]=useState();
 const[whatsapp,setwhatsapp]=useState();const updatewhatsapp = (event) => { setwhatsapp(event.target.value);}; const[whatsapp_er,setwhatsapp_er]=useState();
 const[alternate_contact,setalternate_contact]=useState();const updatealternate_contact = (event) => { setalternate_contact(event.target.value);}; const[alternate_contact_er,setalternate_contact_er]=useState();
 const[address,setaddress]=useState();const updateaddress = (event) => { setaddress(event.target.value);}; const[address_er,setaddress_er]=useState();
 const[city,setcity]=useState();const updatecity = (event) => { setcity(event.target.value);}; const[city_er,setcity_er]=useState();
 const[state,setstate]=useState();const updatestate = (event) => { setstate(event.target.value);}; const[state_er,setstate_er]=useState();
 const[country,setcountry]=useState();const updatecountry = (event) => { setcountry(event.target.value);}; const[country_er,setcountry_er]=useState();
 const[pincode,setpincode]=useState();const updatepincode = (event) => { setpincode(event.target.value);}; const[pincode_er,setpincode_er]=useState();
 const[document_file_name,setdocument_file_name]=useState();const updatedocument_file_name = (event) => { setdocument_file_name(event.target.value);}; const[document_file_name_er,setdocument_file_name_er]=useState();
 const[document_file_link,setdocument_file_link]=useState();const updatedocument_file_link = (event) => { setdocument_file_link(event.target.value);}; const[document_file_link_er,setdocument_file_link_er]=useState();
 const[employee_remarks,setemployee_remarks]=useState();const updateemployee_remarks = (event) => { setemployee_remarks(event.target.value);}; const[employee_remarks_er,setemployee_remarks_er]=useState();
 const[type,settype]=useState();const updatetype = (event) => { settype(event.target.value);}; const[type_er,settype_er]=useState();



 //ERROR VARIABLES 
 const[error_msg,seterror_msg]=useState();

//image upload
//File Upload
const [selectedFile, setSelectedFile] = React.useState(null);
const handleFileChange = (event) => {  const file = event.target.files[0];  setSelectedFile(file); };
const[employee_image_link_er,setemployee_image_link_er]=useState();

//image upload
//File Upload
const [selectedFile1, setSelectedFile1] = React.useState(null);
const handleFileChange1 = (event) => {  const file1 = event.target.files[0];  setSelectedFile1(file1); };
const[employee_docs_link_er,setemployee_docs_link_er]=useState();


 useEffect(()=>{
   
  getData();
  
  },[])
 

//******************* GET CUSTOMER DATA FUNCTION START  ********************//
function getData(){
 

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&(editId>0)&&(branch_id>0)){
 
 axios.post("crm-get-customer-details-by-id", {
   Log_id:logid,
   Token:token,
   Edit_id:editId,
   Branch_id:branch_id


   })
 .then((response) => {

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("logName00");
     sessionStorage.removeItem("EditId00");
     window.location.replace("/");
  }

  //Data Not Found
  if((response.data.error)==103){  seterror_msg("Data Not Found for Branch Id !");}

  //Success Data Fetched
  if(response.data.successvar==1){
   //Setting Data
   setcustomer_id(response.data.customer_id);
   //setbranch_id(response.data.branch_id);
   setbranch_code(response.data.branch_code);
   setgroup_name(response.data.group_name);
   setfirst_name(response.data.first_name);
   setlast_name(response.data.last_name);
   setusername(response.data.username);
   setcontact(response.data.contact);
   setemail(response.data.email);
   settype(response.data.type);
   setwhatsapp(response.data.whatsapp);
   setalternate_contact(response.data.alternate_contact);
   setdate_of_birth(response.data.date_of_birth);
   setgender(response.data.gender);
   setaddress(response.data.address);
   setcity(response.data.city);
   setstate(response.data.state);
   setcountry(response.data.country);
   setpincode(response.data.pincode);
   setaccount_number(response.data.account_number);
   setdocument_file_name(response.data.document_file_name);
   setdocument_file_link(response.data.document_file_link);
   //setcustomer_image_link((response.data.customer_image_link).slice(7));
   //setcustomer_docs_link(response.data.customer_docs_link);
   setemployee_remarks(response.data.employee_remarks);


  }

  //FILTER DATA
  //Validation Errors
  if(response.data.validatorerror){ seterror_msg("Validation error!"); }

  //Data Violation
  if(response.data.error==102){  seterror_msg("Violation Error"); }

 
 
 });
 
 }
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("logName00");
   sessionStorage.removeItem("EditId00");
   window.location.replace("/");
 
  }
 
 }
  
 //******************* GET EMPLOYEE DATA FUNCTION END  ********************//

 


/***************** EDIT CUSTOMER FUNCTION START  ******************/
function edit_customer(){

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((logname!=null)||(logname!=" "))&&(branch_id>0)&&(editId>0)){

    //Setting Error Variables
    setfirst_name_er(" ");setlast_name_er(" ");setusername_er(" ");setconfirm_username_er(" ");setpassword_er(" ");setconfirm_password_er(" ");
    setemail_er(" ");setcontact_er(" ");setdate_of_birth_er(" ");setgender_er(" ");setwhatsapp_er(" ");setalternate_contact_er(" ");setaddress_er(" ");setcity_er(" ");setstate_er(" ");setcountry_er(" ");setpincode_er(" ");
    setdocument_file_name_er(" ");setdocument_file_link_er(" ");seterror_msg(" ");settype_er(" ");
   
    
//Fetching Form Data
let noerror = 0;
const $first_name = document.getElementById("first_name").value; if($first_name==""){ setfirst_name_er("First Name is required !");noerror = noerror+1; }//first_name
const $last_name = document.getElementById("last_name").value; if($last_name==""){ setlast_name_er("Last Name is required !");noerror = noerror+1; }//last_name
const $username = document.getElementById("username").value; if($username==""){ setusername_er("Username is required !");noerror = noerror+1; }//username
//const $confirm_username = document.getElementById("confirm_username").value; if($confirm_username==""){ setconfirm_username_er("Confirm Username is required !");noerror = noerror+1; }//confirm_username
const $password = document.getElementById("password").value; if($password==""){ setpassword_er("Password is required !");noerror = noerror+1; }//password
const $confirm_password = document.getElementById("confirm_password").value; if($confirm_password==""){ setconfirm_password_er("Confirm Password is required !");noerror = noerror+1; }//confirm_password
const $email = document.getElementById("email").value; if($email==""){ setemail_er("Email is required !");noerror = noerror+1; }//email
const $contact = document.getElementById("contact").value; if($contact==""){ setcontact_er("Contact is required !");noerror = noerror+1; }//contact
const $gender = document.getElementById("gender").value; if($gender==""){ setgender_er("Gender is required !");noerror = noerror+1; }//gender
const $date_of_birth = document.getElementById("date_of_birth").value; if($date_of_birth==""){ setdate_of_birth_er("Date of Birth is required !");noerror = noerror+1; }//date_of_birth
const $whatsapp = document.getElementById("whatsapp").value; if($whatsapp==""){ setwhatsapp_er("Whatsapp is required !");noerror = noerror+1; }//whatsapp
const $alternate_contact = document.getElementById("alternate_contact").value; if($alternate_contact==""){ setalternate_contact_er("Alternate Contact is required !");noerror = noerror+1; }//alternate_contact
const $address = document.getElementById("address").value; if($address==""){ setaddress_er("Address is required !");noerror = noerror+1; }//address
const $city = document.getElementById("city").value; if($city==""){ setcity_er("City is required !");noerror = noerror+1; }//city
const $state = document.getElementById("state").value; if($state==""){ setstate_er("State is required !");noerror = noerror+1; }//state
const $pincode = document.getElementById("pincode").value; if($pincode==""){ setpincode_er("Pincode is required !");noerror = noerror+1; }//pincode
const $document_file_name = document.getElementById("document_file_name").value; if($document_file_name==""){ setdocument_file_name_er("Document File Name is required !");noerror = noerror+1; }//document_file_name
const $document_file_link = document.getElementById("document_file_link").value; if($document_file_link==""){ setdocument_file_link_er("Document File Link is required !");noerror = noerror+1; }//document_file_link
const $employee_remarks = document.getElementById("employee_remarks").value; if($employee_remarks==""){ setemployee_remarks_er("Employee Remarks is required !");noerror = noerror+1; }//employee_remarks
//const $type = document.getElementById("type").value; if($type==""){ settype_er("type is required !");noerror = noerror+1; }//type


//No primary errors
if(noerror==0){


  //Sending Data through axios
  axios.post("crm-edit-customer-details", { 
  Log_id:logid,
  Token:token,
  Branch_id:branch_id,
  First_name:$first_name,
  Last_name:$last_name,
  Username:$username,
  Confirm_username:$username,
  Password:$password,
  Confirm_password:$confirm_password,
  Email:$email,
  Contact:$contact,
  Gender:$gender,
  Date_of_birth:moment($date_of_birth).format('YYYY-MM-DD'),
  Whatsapp:$whatsapp,
  Alternate_contact:$alternate_contact,
  Address:$address,
  City:$city,
  State:$state,
  Country:"India",
  Pincode:$pincode,
  Document_file_name:$document_file_name,
  Document_file_link:$document_file_link,
  Employee_remarks:$employee_remarks,
  Customer_image_link:selectedFile,
  Customer_consent_link:selectedFile1,
  Type:"regular",
  "Edit_id":editId

  },{
    headers: { "Content-Type": "multipart/form-data" },
    }
    )
  
  .then((response) => {

  //Validation Errors
  if(response.data.validatorerror){
  setfirst_name_er(response.data.validatorerror.First_name);
  setlast_name_er(response.data.validatorerror.Last_name);
  setusername_er(response.data.validatorerror.Username);
  setconfirm_username_er(response.data.validatorerror.Confirm_username);
  setpassword_er(response.data.validatorerror.Password);
  setconfirm_password_er(response.data.validatorerror.Confirm_password);
  setcontact_er(response.data.validatorerror.Contact);
  setemail_er(response.data.validatorerror.Email);
  setgender_er(response.data.validatorerror.Gender);
  setdate_of_birth_er(response.data.validatorerror.Date_of_birth);
  setalternate_contact_er(response.data.validatorerror.Alternate_contact);
  setwhatsapp_er(response.data.validatorerror.Whatsapp);
  setaddress_er(response.data.validatorerror.Address);
  setcity_er(response.data.validatorerror.City);
  setstate_er(response.data.validatorerror.State);
  setcountry_er(response.data.validatorerror.Country);
  setpincode_er(response.data.validatorerror.Pincode);
  setdocument_file_name_er(response.data.validatorerror.Document_file_name);
  setdocument_file_link_er(response.data.validatorerror.Document_file_link);
  setemployee_image_link_er(response.data.validatorerror.Customer_image_link);
  setemployee_docs_link_er(response.data.validatorerror.Customer_consent_link);
  setemployee_remarks_er(response.data.validatorerror.Employee_remarks);
  settype_er(response.data.validatorerror.Type);
  seterror_msg("Account Request Not Created !");
  
  
  }
  //Invalid Entries
  if(response.data.error==102){ 
  setfirst_name_er(response.data.first_name_er);
  setlast_name_er(response.data.last_name_er);
  setusername_er(response.data.username_er);
  setconfirm_username_er(response.data.confirm_username_er);
  setpassword_er(response.data.password_er);
  setconfirm_password_er(response.data.confirm_password_er);
  setcontact_er(response.data.contact_er);
  setemail_er(response.data.email_er);
  setgender_er(response.data.gender_er);
  setdate_of_birth_er(response.data.date_of_birth_er);
  setalternate_contact_er(response.data.alternate_contact_er);
  setwhatsapp_er(response.data.whatsapp_er);
  setaddress_er(response.data.address_er);
  setcity_er(response.data.city_er);
  setstate_er(response.data.state_er);
  setcountry_er(response.data.country_er);
  setpincode_er(response.data.pincode_er);
  setdocument_file_name_er(response.data.document_file_name_er);
  setdocument_file_link_er(response.data.document_file_link_er);
  setemployee_image_link_er(response.data.Employee_image_link_er);
  setemployee_docs_link_er(response.data.employee_docs_link_er);
  setemployee_remarks_er(response.data.employee_remarks_er);
  settype_er(response.data.type_er);
  seterror_msg("Account Request Not Created !");
  
  
  }
  
  //Branch Name or Branch Code Already Exists
  if(response.data.error==103){  seterror_msg(response.data.error_msg); return; }
  
  //Success
  if(response.data.successvar==1){ document.getElementById("success-card").style.display="block"; }

  //failure
  if(response.data.successvar==6){ seterror_msg("Account Request Not Created !"); }

  //Session Expired
  if(response.data.successvar==100){ seterror_msg("Session Expired !");  }


  }    );
  
  }

}
  //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
 sessionStorage.removeItem("logStatus00");
 sessionStorage.removeItem("logId00");
 sessionStorage.removeItem("logToken00");
 sessionStorage.removeItem("logName00");
 
 window.location.replace("/");
 
 
 }    

}

/***************** EDIT CUSTOMER FUNCTION END  ******************/


return(
    <>

<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Pragati Prathmik Sakh Sahkari Samiti Maryadit | Employee Dashboard</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>
    <div className="container-scroller">

   {/*}   <Sidebar /> */}
    <div className="container-fluid page-body-wrapper">


       <Navbar />
        {/*} partial */}
        <div className="main-panel">

        

          <div className="content-wrapper pb-0">

          

                 {/*  SUCCESS CARD START */}
                 <div class="d-flex justify-content-center">
               <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="success-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-success">Customer Information Updated Successfully !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to continue.</p>
                      <a href="/employee-dashboard-customers"><button class="mt-3 btn btn-outline-success">Continue</button></a>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/*  SUCCESS CARD END */}


          <div className="page-header flex-wrap">
            <div className="header-left"></div>
              <div className="header-right d-flex flex-wrap mt-2 mt-sm-0">
                <div className="d-flex align-items-center">
                  <a href="#">
                    <p className="m-0 pe-3">EMPLOYEE PANEL</p>
                  </a>
                  <a className="ps-3 me-4" href="#">
                    <p className="m-0">EDIT CUSTOMER DETAILS</p>
                  </a>
                </div>
          {/*}     <button type="button" className="btn btn-primary mt-2 mt-sm-0 btn-icon-text"  onClick={()=>{ 
               const createForm = document.getElementById('create-form');
               const currentDisplay = createForm.style.display;
               createForm.style.display = currentDisplay === 'none' ? 'block' : 'none'; }}>
                  <i className="mdi mdi-plus-circle"></i>New Loan Request</button>  */}
              </div>
            </div>


                <div class="card">
              
                  <div class="card-body" style={{textAlign:"left"}}>
                    <h4 class="card-title">Edit Customer Details</h4>
                    <div class="form-sample">
                      <p class="card-description">edit customer details here</p>
                       <code>{error_msg}</code>
                       <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Customer Group</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Group Name" id="group_name" value={group_name} />
                              <small class="text-muted" style={{fontSize:"0.7rem"}}>this field cannot be changed</small>
                            </div>
                          </div>
                        </div>  
                        </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">First Name</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="First Name" id="first_name" value={first_name} onChange={updatefirst_name} />
                              <code>{first_name_er}</code>
                              <small class="text-muted" style={{fontSize:"0.7rem"}}>this field cannot be changed</small>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Last Name</label>
                            <div class="col-sm-9">
                            <input type="text" class="form-control" placeholder="Last Name" id="last_name" value={last_name} onChange={updatelast_name} />
                            <code>{last_name_er}</code>
                            <small class="text-muted" style={{fontSize:"0.7rem"}}>this field cannot be changed</small>
                            </div>
                          </div>
                        </div>   
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Username</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="My Username" id="username" value={username} />
                              <code>{username_er}</code>
                              <small class="text-muted" style={{fontSize:"0.7rem"}}>this field cannot be changed</small>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Customer Id</label>
                            <div class="col-sm-9">
                            <input type="text" class="form-control" placeholder="CUST999" value={customer_id} />
                            <small class="text-muted" style={{fontSize:"0.7rem"}}>this field cannot be changed</small>
                         
                            </div>
                          </div>
                        </div>   
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Account Number</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" value={account_number} />
                              <small class="text-muted" style={{fontSize:"0.7rem"}}>this field cannot be changed</small>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Branch Code</label>
                            <div class="col-sm-9">
                            <input type="text" class="form-control" value={branch_code} />
                            <small class="text-muted" style={{fontSize:"0.7rem"}}>this field cannot be changed</small>
                            </div>
                          </div>
                        </div>   
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Password</label>
                            <div class="col-sm-9">
                              <input type="password" class="form-control" placeholder="My Password" id="password" value={password} onChange={updatepassword} />
                              <code>{password_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Confirm Password</label>
                            <div class="col-sm-9">
                            <input type="password" class="form-control" placeholder="My Password" id="confirm_password" value={confirm_password} onChange={updateconfirm_password}  />
                            <code>{confirm_password_er}</code>
                            </div>
                          </div>
                        </div>   
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Email</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="myemail@gmail.com" id="email" value={email} onChange={updateemail} />
                              <code>{email_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Contact</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="91 9000000000" id="contact" value={contact} onChange={updatecontact} />
                              <code>{contact_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Gender</label>
                            <div class="col-sm-9">
                              <select class="form-control" style={{padding:"1rem"}} id="gender" value={gender} onChange={updategender}>
                              <option value="">-- select gender --</option>
                              <option value="male">male</option>
                              <option value="female">female</option>
                              <option value="undisclosed">undisclosed</option>
                              </select>
                              <code>{gender_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Date of Birth</label>
                            <div class="col-sm-9">
                              <input type="date" class="form-control" id="date_of_birth" value={date_of_birth} onChange={updatedate_of_birth} />
                              <code>{date_of_birth_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">WhatsApp</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="91 9000000000" id="whatsapp" value={whatsapp} onChange={updatewhatsapp} />
                              <code>{whatsapp_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Alternate Contact</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="91 9000000000" id="alternate_contact" value={alternate_contact} onChange={updatealternate_contact} />
                              <code>{alternate_contact_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>
                      <div class="form-group">
                        <label for="exampleTextarea1">Address</label>
                        <textarea  class="form-control" rows="2" placeholder="My address" id="address" value={address} onChange={updateaddress} />
                        <code>{address_er}</code>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">City</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Satna" id="city" value={city} onChange={updatecity} />
                              <code>{city_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">State</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Madhya Pradesh" id="state" value={state} onChange={updatestate} />
                              <code>{state_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Country</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="India" id="country" value={country} onChange={updatecountry} />
                              <code>{country_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Pincode</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="482001" id="pincode" value={pincode} onChange={updatepincode} />
                              <code>{pincode_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Documents File Name</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Customer 1234" id="document_file_name" value={document_file_name} onChange={updatedocument_file_name} />
                              <code>{document_file_name_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Documents Link</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="https://mydrivelink.com/mydrive" id="document_file_link" value={document_file_link} onChange={updatedocument_file_link} />
                              <code>{document_file_link_er}</code>   
                            </div>
                          </div>
                        </div>                     
                      </div>
                      <div class="col-md-12">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Upload Customer Image (Passport Size)</label>
                            <div class="col-sm-3">
                              <input type="file" class="form-control"  id="logo_link"  onChange={handleFileChange} multiple />
                              {selectedFile && <p>Selected file: {selectedFile.name}</p>}
                              <code>{employee_image_link_er}</code>
                           
                            </div>
                          <div class="col-sm-5">
                          <p>Allowed File format (.png, .jpg), Max. file Size Allowed 1mb. </p>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Upload Customer Consent</label>
                            <div class="col-sm-3">
                              <input type="file" class="form-control"  id="logo_link1"  onChange={handleFileChange1} multiple />
                              {selectedFile1 && <p>Selected file: {selectedFile1.name}</p>}
                              <code>{employee_docs_link_er}</code>
                           
                            </div>
                          <div class="col-sm-5">
                          <p>Allowed File format (.pdf), Max. file Size Allowed 1mb. </p>
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="exampleTextarea1">Employee Remarks for Customer</label>
                        <textarea  class="form-control" rows="2" placeholder="Employee Remarks for customer" id="employee_remarks" value={employee_remarks} onChange={updateemployee_remarks} />
                        <code>{employee_remarks_er}</code>
                     </div>

                
                      
                        <button class="btn btn-lg btn-success" style={{fontWeight:"bold"}} onClick={edit_customer}>Submit</button>
                      
                       
    
                     
                   
                    </div>
                  </div>
                </div>

                </div>
          {/*} content-wrapper ends */}
          {/*} partial:partials/_footer.html */}
       {/*}   <footer className="footer">
            <div className="d-sm-flex justify-content-center justify-content-sm-between">
              <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2021 <a href="https://www.bootstrapdash.com/" target="_blank">BootstrapDash</a>. All rights reserved.</span>
              <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i className="mdi mdi-heart text-danger"></i></span>
            </div>
          </footer> */}
          {/*} partial */}
        </div>
        {/*} main-panel ends */}
      </div>
      {/*} page-body-wrapper ends */}
    </div>
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>
    </>

);
}
//}

export default Employee_edit_customer;