import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import moment from 'moment/moment';


import './Resources/dashboard.css';
import axios from './../axios.js';
import Navbar from './Supportingfiles/navbar';
import Sidebar from './Supportingfiles/sidebar';
//import Create_transaction_history from './create_newaccounts';



import Chart from "chart.js/auto";
import { Line, Bar } from "react-chartjs-2";

function Employee_transaction_history(){




    
 //Checking Sessions
 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logtype=sessionStorage.getItem("logType00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");
 const logname=sessionStorage.getItem("logName00");
 const branch_id=sessionStorage.getItem("branchId00");

 /* PAGE VARIABLES */


 
{/* TRANSACTIONS TABLE COLUMNS MANAGEMENT VARIABLES START */}
const[cd_col1_val,setcd_col1_val] = useState(0);//column1
const[cd_col2_val,setcd_col2_val] = useState(0);//column2
const[cd_col3_val,setcd_col3_val] = useState(0);//column3
const[cd_col4_val,setcd_col4_val] = useState(0);//column4
const[cd_col5_val,setcd_col5_val] = useState(0);//column5
const[cd_col6_val,setcd_col6_val] = useState(0);//column6
const[cd_col7_val,setcd_col7_val] = useState(0);//column7
const[cd_col8_val,setcd_col8_val] = useState(0);//column8
const[cd_col9_val,setcd_col9_val] = useState(0);//column9
const[cd_col10_val,setcd_col10_val] = useState(0);//column10
const[cd_col11_val,setcd_col11_val] = useState(0);//column11
{/* const[cd_col12_val,setcd_col12_val] = useState(0);//column12
const[cd_col13_val,setcd_col13_val] = useState(0);//column13
const[cd_col14_val,setcd_col14_val] = useState(0);//column14 */}





//Variables for pagination
const [currentPage, setCurrentPage] = useState(1);
const [pageNumber, setpageNumber] = useState(1);



 //Error Variable
 const[errorvar,seterrorvar] = useState();

//FILTER VARIABLES
const[filterval,setfilterval] = useState(0);
const[filter_start_date,setfilter_start_date] = useState('1970-01-01'); const updatefilter_start_date = (event) => { setfilter_start_date(event.target.value);}; const[filter_start_date_er,setfilter_start_date_er]=useState();
const[filter_end_date,setfilter_end_date] = useState('2100-12-12'); const updatefilter_end_date = (event) => { setfilter_end_date(event.target.value);}; const[filter_end_date_er,setfilter_end_date_er]=useState();
const[filter_trno,setfilter_trno] = useState(); const updatefilter_trno = (event) => { setfilter_trno(event.target.value);}; const[filter_trno_er,setfilter_trno_er]=useState();
const[filter_transaction,setfilter_transaction] = useState(); const updatefilter_transaction = (event) => { setfilter_transaction(event.target.value);}; const[filter_transaction_er,setfilter_transaction_er]=useState();
const[filter_amount,setfilter_amount] = useState(); const updatefilter_amount = (event) => { setfilter_amount(event.target.value);}; const[filter_amount_er,setfilter_amount_er]=useState();
const[filter_mode,setfilter_mode] = useState(); const updatefilter_mode = (event) => { setfilter_mode(event.target.value);}; const[filter_mode_er,setfilter_mode_er]=useState();
const[filter_updated_by,setfilter_updated_by] = useState(); const updatefilter_updated_by = (event) => { setfilter_updated_by(event.target.value);}; const[filter_updated_by_er,setfilter_updated_by_er]=useState();
const[filter_updated_on,setfilter_updated_on] = useState(); const updatefilter_updated_on = (event) => { setfilter_updated_on(event.target.value);}; const[filter_updated_on_er,setfilter_updated_on_er]=useState();
const[filter_type,setfilter_type] = useState(); const updatefilter_type = (event) => { setfilter_type(event.target.value);}; const[filter_type_er,setfilter_type_er]=useState();


 
//Variables
const[data,setData] = useState([]);//SetData Variable


//ERROR VARIABLES 
const[error_msg,seterror_msg]=useState();


 useEffect(()=>{
   
  getData();
  
  },[])
 





//******************* GET TRANSACTIONS DATA FUNCTION START  ********************//
function getData(){
 

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&(branch_id>0)){
 
 axios.post("crm-employee-get-transactions-history", {
   Log_id:logid,
   Token:token,
   
   //Code Edits for pagination
   'page' : pageNumber,
   'Branch_id' : branch_id,

   //Filters
   'Filter_val' :filterval,
   'Filter_start_date' : moment(filter_start_date).format('YYYY-MM-DD'),
   'Filter_end_date' :moment(filter_end_date).format('YYYY-MM-DD'),
   'Filter_trno' :filter_trno,
   'Filter_transaction' :filter_transaction,
   'Filter_amount' :filter_amount,
   'Filter_type' :filter_type,
   'Filter_updated_by' :filter_updated_by,
   'Filter_mode' :filter_mode,


   })
 .then((response) => {

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("logName00");

     window.location.replace("/");
  }
  //Success Data Fetched
  if(response.data.successvar==1){
   //Setting Data
   setData(response.data.data.data);
  }

  //FILTER DATA
  //Validation Errors
  if(response.data.validatorerror){

    setfilter_start_date_er(response.data.validatorerror.Filter_start_date);
    setfilter_end_date_er(response.data.validatorerror.Filter_end_date);
    setfilter_trno_er(response.data.validatorerror.Filter_trno);
    setfilter_transaction_er(response.data.validatorerror.Filter_transaction);
    setfilter_amount_er(response.data.validatorerror.Filter_amount);
    setfilter_type_er(response.data.validatorerror.Filter_type);
    setfilter_updated_by_er(response.data.validatorerror.Filter_updated_by);
   // setfilter_updated_on_er(response.data.validatorerror.Filter_updated_on);
    setfilter_mode_er(response.data.validatorerror.Filter_mode);
    seterrorvar("Validation error!");

  }

  //Data Violation
  if(response.data.error==102){ 
  
     //Setting Filter Violation Errors
     setfilter_start_date_er(response.data.filter_start_date_er);
     setfilter_end_date_er(response.data.filter_end_date_er);
     setfilter_trno_er(response.data.filter_trno_er);
     setfilter_transaction_er(response.data.filter_transaction_er);
     setfilter_amount_er(response.data.filter_amount_er);
     setfilter_type_er(response.data.filter_type_er);
     setfilter_updated_by_er(response.data.filter_updated_by_er);
    // setfilter_updated_on_er(response.data.filter_updated_on_er);
     setfilter_mode_er(response.data.filter_mode_er);
     seterrorvar("Violation Error");


  }


  //Cannot Fetch Data
  else{
   seterrorvar("Cannot Fetch Data !");
  }
 
 
 });
 
 }
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("logName00");

   window.location.replace("/");
 
  }
 
 }
  
 //******************* GET TRANSACTIONS DATA FUNCTION END  ********************//


return(
    <>
<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Pragati Prathmik Sakh Sahkari Samiti Maryadit | Employee Dashboard</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>
    <div className="container-scroller">

      <Sidebar />
    <div className="container-fluid page-body-wrapper">


       <Navbar />
        {/*} partial */}
        <div className="main-panel">

        

          <div className="content-wrapper pb-0">

          <div className="row">
              <div className="col-12 grid-margin create-form" id="create-form">
             {/*} <Create_cash /> */}
              </div>
          </div>


            <div className="page-header flex-wrap">
            <div className="header-left"></div>
              <div className="header-right d-flex flex-wrap mt-2 mt-sm-0">
                <div className="d-flex align-items-center">
                  <a href="#">
                    <p className="m-0 pe-3">EMPLOYEE PANEL</p>
                  </a>
                  <a className="ps-3 me-4" href="#">
                    <p className="m-0">TRANSACTION HISTORY </p>
                  </a>
                </div>
             
              </div>
            </div>

            {/*} chart row starts here */}
            <div className="row">

          
            </div>

            

              {/* CASH TRANSACTIONS TABLE SECTION START */}
                  
             <div className="col-lg-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Transaction History</h4>
                    <p className="card-description">
                    </p>

                    <code>{error_msg}</code>
                      
                      {/* FILTER SECTION START */}
                      <hr/>
                      <div id="filter-top-card" class="row col-12" style={{textAlign:"left",display:"none"}}>
                      <div class="d-flex justify-content-between">
                      <div>
                      <h4 style={{padding:'1rem'}}>Filters</h4>
                     </div>
                     <div>
              
                     <button type="button" class="btn btn-social-icon" onClick={()=>{ document.getElementById("filter-top-card").style.display="none"; }}>
                     <i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
                     </button>
                     </div>
                     </div>
  
                  <div class="row">
                  <div class="col-md-4">
                    <div class="form-group row">
                      <label class="col-sm-4 col-form-label">Start Date</label>
                      <div class="col-sm-8">
                        <input type="date" class="form-control" placeholder="" id="filter_start_date" value={filter_start_date} onChange={updatefilter_start_date}  />
                        <code>{filter_start_date_er}</code>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group row">
                      <label class="col-sm-4 col-form-label">End Date</label>
                      <div class="col-sm-8">
                        <input type="date" class="form-control" id="filter_end_date" value={filter_end_date} onChange={updatefilter_end_date}  />
                        <code>{filter_end_date_er}</code>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group row">
                      <label class="col-sm-4 col-form-label">Tr No.</label>
                      <div class="col-sm-8">
                        <input type="text" class="form-control" placeholder="120"   id="filter_trno" value={filter_trno} onChange={updatefilter_trno}  />
                        <code>{filter_trno_er}</code>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                <div class="col-md-4">
                    <div class="form-group row">
                      <label class="col-sm-4 col-form-label">Transaction</label>
                      <div class="col-sm-8">
                        <select class="form-control" style={{padding:"1rem"}}   id="filter_transaction" value={filter_transaction} onChange={updatefilter_transaction} >
                        <option value="">All</option>
                        <option value="cash entry by admin">cash entry by admin</option>
                        <option value="cash drawings by admin">cash drawings by admin</option>
                        <option value="cash entry by employee">cash entry by employee</option>
                        <option value="cash drawings by employee">cash drawings by employee</option>
                        <option value="income by admin">income by admin</option>
                        <option value="expense by admin">expense by admin</option>
                        <option value="income by employee">income by employee</option>
                        <option value="expense by employee">expense by employee</option>
                        <option value="emi collection">emi collection</option>
                        <option value="salary payment">salary payment</option>
                        <option value="loan closure">loan closure</option>
                        <option value="loan disbursal">loan disbursal</option>
                        <option value="processing fee received">processing fee received</option>
                        <option value="PF received">PF received</option>
                        <option value=" Insurance received">  insurance received</option>
                        </select>
                        <code>{filter_transaction_er}</code>
                      </div>
                    </div>
                  </div>
                      <div class="col-md-4">
                    <div class="form-group row">
                      <label class="col-sm-4 col-form-label">Amount</label>
                      <div class="col-sm-8">
                        <input type="text" class="form-control" placeholder="2400"   id="filter_amount" value={filter_amount} onChange={updatefilter_amount}  />
                        <code>{filter_amount_er}</code>
                      </div>
                    </div>
                    </div>

                    <div class="col-md-4">
                    <div class="form-group row">
                      <label class="col-sm-4 col-form-label">Type</label>
                      <div class="col-sm-8">
                        <input type="text" class="form-control" placeholder="expense"   id="filter_type" value={filter_type} onChange={updatefilter_type}  />
                        <code>{filter_type_er}</code>
                      </div>
                    </div>
                    </div>
                    </div>
                    <div class="row">
                    <div class="col-md-4">
                    <div class="form-group row">
                      <label class="col-sm-4 col-form-label">Updated By</label>
                      <div class="col-sm-8">
                        <input type="text" class="form-control" placeholder="Arun"   id="filter_updated_by" value={filter_updated_by} onChange={updatefilter_updated_by}  />
                        <code>{filter_updated_by_er}</code>
                      </div>
                    </div>
                    </div>
                    <div class="col-md-4">
                    <div class="form-group row">
                      <label class="col-sm-4 col-form-label">Mode</label>
                      <div class="col-sm-8">
                      <select class="form-control" style={{padding:"1rem"}} id="filter_mode" value={filter_mode} onChange={updatefilter_mode}>
                      <option value=""> -- select mode -- </option>
                      <option value="cash"> cash</option>
                      <option value="bank"> bank</option>
                      </select>
                      <code>{filter_mode_er}</code>
                      </div>
                    </div>
                  </div> 
                {/*}    <div class="col-md-4">
                    <div class="form-group row">
                      <label class="col-sm-4 col-form-label">Updated On</label>
                      <div class="col-sm-8">
                        <input type="date" class="form-control"  id="filter_updated_on" value={filter_updated_on} onChange={updatefilter_updated_on}  />
                        <code>{filter_updated_on_er}</code>
                      </div>
                    </div>
                    </div> */}
                    <div class="col-md-4">
                      <button class="btn btn-primary" onClick={()=>{ setfilterval(1); getData(); }}>Apply Filters</button>
                    </div>
                    </div>
                  
                
                
              
        
                      <hr/>                  
                      </div> 
                      
                    {/*}   FILTER SECTION END */}
                    
                    <div class="col-12" style={{marginBottom:"2rem"}}>
                        <div class="template-demo d-flex justify-content-between">
                        <div></div>

                        {/* COLUMNS MANAGEMENT DROPDOWN START */}
                          <div class="dropdown">
                          <button class="btn btn-warning" onClick={()=>{ document.getElementById("filter-top-card").style.display="block"; }}><i class="mdi mdi-filter-outline"></i></button>
                         {/*} <button class="btn btn-primary dropdown-toggle" style={{marginLeft:"1rem"}} type="button" id="dropdownMenuIconButton1" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="mdi mdi-table-large"></i>
                            </button>*/}
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuIconButton1">
                              <h6 class="dropdown-header">Columns</h6>
                              <hr/>
                              
                              {/* Column 1 */}
                              <li><div class="d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Sn</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c1_yes" onClick={()=>{setcd_col1_val(1); document.getElementById("cd_c1_yes").style.display="none"; document.getElementById("cd_c1_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c1_no" style={{ display:"none"}} onClick={()=>{ setcd_col1_val(0); document.getElementById("cd_c1_yes").style.display="block"; document.getElementById("cd_c1_no").style.display="none"; }}></i>
                              </div></div></li> 
                               {/* Column 2 */}
                               <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Tr No.</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c2_yes"  onClick={()=>{setcd_col2_val(1); document.getElementById("cd_c2_yes").style.display="none"; document.getElementById("cd_c2_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c2_no" style={{ display:"none"}} onClick={()=>{ setcd_col2_val(0); document.getElementById("cd_c2_yes").style.display="block"; document.getElementById("cd_c2_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 3 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Date</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c3_yes"  onClick={()=>{setcd_col3_val(1); document.getElementById("cd_c3_yes").style.display="none"; document.getElementById("cd_c3_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c3_no" style={{ display:"none"}} onClick={()=>{ setcd_col3_val(0); document.getElementById("cd_c3_yes").style.display="block"; document.getElementById("cd_c3_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 4 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Transaction</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c4_yes"  onClick={()=>{setcd_col4_val(1); document.getElementById("cd_c4_yes").style.display="none"; document.getElementById("cd_c4_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c4_no" style={{ display:"none"}} onClick={()=>{ setcd_col4_val(0); document.getElementById("cd_c4_yes").style.display="block"; document.getElementById("cd_c4_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 5 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Particular</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c5_yes"  onClick={()=>{setcd_col5_val(1); document.getElementById("cd_c5_yes").style.display="none"; document.getElementById("cd_c5_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c5_no" style={{ display:"none"}} onClick={()=>{ setcd_col5_val(0); document.getElementById("cd_c5_yes").style.display="block"; document.getElementById("cd_c5_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 6 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Amount In</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c6_yes"  onClick={()=>{setcd_col6_val(1); document.getElementById("cd_c6_yes").style.display="none"; document.getElementById("cd_c6_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c6_no" style={{ display:"none"}} onClick={()=>{ setcd_col6_val(0); document.getElementById("cd_c6_yes").style.display="block"; document.getElementById("cd_c6_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 7 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Amount Out</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c7_yes" onClick={()=>{setcd_col7_val(1); document.getElementById("cd_c7_yes").style.display="none"; document.getElementById("cd_c7_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c7_no" style={{ display:"none"}} onClick={()=>{ setcd_col7_val(0); document.getElementById("cd_c7_yes").style.display="block"; document.getElementById("cd_c7_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 8 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Updated By</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c8_yes" onClick={()=>{setcd_col8_val(1); document.getElementById("cd_c8_yes").style.display="none"; document.getElementById("cd_c8_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c8_no" style={{ display:"none"}} onClick={()=>{ setcd_col8_val(0); document.getElementById("cd_c8_yes").style.display="block"; document.getElementById("cd_c8_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 9 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Updated At</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c9_yes" onClick={()=>{setcd_col9_val(1); document.getElementById("cd_c9_yes").style.display="none"; document.getElementById("cd_c9_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c9_no" style={{ display:"none"}} onClick={()=>{ setcd_col9_val(0); document.getElementById("cd_c9_yes").style.display="block"; document.getElementById("cd_c9_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 10 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10 text-warning">Edit</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c10_yes" onClick={()=>{setcd_col10_val(1); document.getElementById("cd_c10_yes").style.display="none"; document.getElementById("cd_c10_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c10_no" style={{ display:"none"}} onClick={()=>{ setcd_col10_val(0); document.getElementById("cd_c10_yes").style.display="block"; document.getElementById("cd_c10_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 11 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10 text-danger">Delete</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c11_yes" onClick={()=>{setcd_col11_val(1); document.getElementById("cd_c11_yes").style.display="none"; document.getElementById("cd_c11_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c11_no" style={{ display:"none"}} onClick={()=>{ setcd_col11_val(0); document.getElementById("cd_c11_yes").style.display="block"; document.getElementById("cd_c11_no").style.display="none"; }}></i>
                              </div></div></li> 
                              
                           
                            {/*}  <div class="dropdown-divider"></div>
                              <button class="btn btn-danger">Close</button>*/}
                            </div> 
                          </div>
                          {/* COLUMNS MANAGEMENT DROPDOWN END */}

                          </div>
                          </div>


                    <div className="table-responsive">
                      <table className="table table-hover table-bordered">
                        <thead>
                          <tr >
                            <th><b>#</b></th>
                            <th style={{fontWeight:"bold"}} hidden={cd_col1_val==1} >Sn</th>
                            <th style={{fontWeight:"bold"}} hidden={cd_col1_val==1} >Tr. No.</th>
                            <th style={{fontWeight:"bold"}} hidden={cd_col1_val==1} >Date</th>
                            <th style={{fontWeight:"bold"}} hidden={cd_col1_val==1} >Transaction</th>
                            <th style={{fontWeight:"bold"}} hidden={cd_col1_val==1} >Type</th>
                            <th style={{fontWeight:"bold"}} hidden={cd_col1_val==1} >Mode</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col2_val==1} >Particular</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col4_val==1} >Remarks</th> 
                            <th  style={{fontWeight:"bold"}} hidden={cd_col4_val==1} >Amount</th> 
                            <th  style={{fontWeight:"bold"}} hidden={cd_col9_val==1} >Updated By</th>
                         {/*}   <th  style={{fontWeight:"bold"}} hidden={cd_col5_val==1} >Initial Entry</th> */}
                            <th  style={{fontWeight:"bold"}} hidden={cd_col9_val==1} >Updated At</th>
                          </tr>
                        </thead>
                        <tbody>
                        {
                       data.map((curElm,ind)=>{

                         return(
                         <>
                         <tr key={ind}>
                          <td>{ind + 1}</td>
                          <td>{curElm.Sn}</td>
                          <td>{curElm.Tr_no}</td>
                          <td>{curElm.Date}</td>
                         <td>
                         <label className="badge badge-primary" hidden={(curElm.Transaction=="cash entry by admin")||(curElm.Transaction=="cash drawings by admin")||(curElm.Transaction=="cash drawings by employee")||(curElm.Transaction=="income by admin")||(curElm.Transaction=="expense by admin")||(curElm.Transaction=="income by employee")||(curElm.Transaction=="expense by employee")||(curElm.Transaction=="loan disbursal")||(curElm.Transaction=="emi collection")||(curElm.Transaction=="salary payment")||(curElm.Transaction=="loan_closure")||(curElm.Transaction=="processing fee received")||(curElm.Transaction=="PF received")||(curElm.Transaction=="insurance received")}>{curElm.Transaction}</label>
                          <label className="badge badge-success" hidden={(curElm.Transaction=="cash entry by employee")||(curElm.Transaction=="cash drawings by admin")||(curElm.Transaction=="cash drawings by employee")||(curElm.Transaction=="income by admin")||(curElm.Transaction=="income by employee")||(curElm.Transaction=="expense by admin")||(curElm.Transaction=="expense by employee")||(curElm.Transaction=="loan disbursal")||(curElm.Transaction=="emi collection")||(curElm.Transaction=="salary payment")||(curElm.Transaction=="loan_closure")||(curElm.Transaction=="processing fee received")||(curElm.Transaction=="PF received")||(curElm.Transaction=="insurance received")}>{curElm.Transaction}</label>
                          <label className="badge badge-dark" hidden={(curElm.Transaction=="cash entry by employee")||(curElm.Transaction=="cash drawings by admin")||(curElm.Transaction=="cash drawings by employee")||(curElm.Transaction=="income by admin")||(curElm.Transaction=="income by employee")||(curElm.Transaction=="expense by admin")||(curElm.Transaction=="expense by employee")|(curElm.Transaction=="cash entry by admin")||(curElm.Transaction=="emi collection")||(curElm.Transaction=="salary payment")||(curElm.Transaction=="loan_closure")||(curElm.Transaction=="processing fee received")||(curElm.Transaction=="PF received")||(curElm.Transaction=="insurance received")}>{curElm.Transaction}</label>
                          <label className="badge badge-danger" hidden={(curElm.Transaction=="loan disbursal")||(curElm.Transaction=="cash drawings by employee")||(curElm.Transaction=="income by admin")||(curElm.Transaction=="income by employee")||(curElm.Transaction=="expense by admin")||(curElm.Transaction=="expense by employee")||(curElm.Transaction=="cash entry by admin")||(curElm.Transaction=="cash entry by employee")||(curElm.Transaction=="emi collection")||(curElm.Transaction=="salary payment")||(curElm.Transaction=="loan_closure")||(curElm.Transaction=="processing fee received")||(curElm.Transaction=="PF received")||(curElm.Transaction=="insurance received")}>{curElm.Transaction}</label>
                          <label className="badge badge-info" hidden={(curElm.Transaction=="cash drawings by admin")||(curElm.Transaction=="cash drawings by employee")||(curElm.Transaction=="loan disbursal")||(curElm.Transaction=="expense by admin")||(curElm.Transaction=="expense by employee")||(curElm.Transaction=="cash entry by admin")||(curElm.Transaction=="cash entry by employee")||(curElm.Transaction=="emi collection")||(curElm.Transaction=="salary payment")||(curElm.Transaction=="loan_closure")||(curElm.Transaction=="processing fee received")||(curElm.Transaction=="PF received")||(curElm.Transaction=="insurance received")}>{curElm.Transaction}</label>
                          <label className="badge badge-secondary" hidden={(curElm.Transaction=="cash drawings by admin")||(curElm.Transaction=="cash drawings by employee")||(curElm.Transaction=="loan disbursal")||(curElm.Transaction=="income by admin")||(curElm.Transaction=="income by employee")||(curElm.Transaction=="cash entry by admin")||(curElm.Transaction=="cash entry by employee")||(curElm.Transaction=="emi collection")||(curElm.Transaction=="salary payment")||(curElm.Transaction=="loan_closure")||(curElm.Transaction=="processing fee received")||(curElm.Transaction=="PF received")||(curElm.Transaction=="insurance received")}>{curElm.Transaction}</label>
                          <label className="badge badge-warning" hidden={(curElm.Transaction=="cash drawings by admin")||(curElm.Transaction=="income by admin")||(curElm.Transaction=="income by employee")||(curElm.Transaction=="loan disbursal")||(curElm.Transaction=="expense by admin")||(curElm.Transaction=="expense by employee")||(curElm.Transaction=="cash entry by admin")||(curElm.Transaction=="cash entry by employee")||(curElm.Transaction=="emi collection")||(curElm.Transaction=="salary payment")||(curElm.Transaction=="loan_closure")||(curElm.Transaction=="processing fee received")||(curElm.Transaction=="PF received")||(curElm.Transaction=="insurance received")}>{curElm.Transaction}</label>
                           <label className="badge badge-primary" style={{backgroundColor:"#771cbc"}} hidden={(curElm.Transaction=="cash drawings by admin")||(curElm.Transaction=="income by admin")||(curElm.Transaction=="income by employee")||(curElm.Transaction=="loan disbursal")||(curElm.Transaction=="expense by admin")||(curElm.Transaction=="expense by employee")||(curElm.Transaction=="cash entry by admin")||(curElm.Transaction=="cash entry by employee")||(curElm.Transaction=="cash drawings by employee")||(curElm.Transaction=="salary payment")||(curElm.Transaction=="loan_closure")||(curElm.Transaction=="processing fee received")||(curElm.Transaction=="PF received")||(curElm.Transaction=="insurance received")}>{curElm.Transaction}</label>
                          <label className="badge badge-primary" style={{backgroundColor:"#ba8511", border:"0.1rem solid #ba8511"}} hidden={(curElm.Transaction=="cash drawings by admin")||(curElm.Transaction=="income by admin")||(curElm.Transaction=="income by employee")||(curElm.Transaction=="loan disbursal")||(curElm.Transaction=="expense by admin")||(curElm.Transaction=="expense by employee")||(curElm.Transaction=="cash entry by admin")||(curElm.Transaction=="cash entry by employee")||(curElm.Transaction=="cash drawings by employee")||(curElm.Transaction=="emi collection")||(curElm.Transaction=="loan_closure")||(curElm.Transaction=="processing fee received")||(curElm.Transaction=="PF received")||(curElm.Transaction=="insurance received")}>{curElm.Transaction}</label>
                          <label className="badge badge-primary" style={{backgroundColor:"#ce0088", border:"0.1rem solid #ba8511"}} hidden={(curElm.Transaction=="cash drawings by admin")||(curElm.Transaction=="income by admin")||(curElm.Transaction=="income by employee")||(curElm.Transaction=="loan disbursal")||(curElm.Transaction=="expense by admin")||(curElm.Transaction=="expense by employee")||(curElm.Transaction=="cash entry by admin")||(curElm.Transaction=="cash entry by employee")||(curElm.Transaction=="cash drawings by employee")||(curElm.Transaction=="emi collection")||(curElm.Transaction=="salary payment")||(curElm.Transaction=="processing fee received")||(curElm.Transaction=="PF received")||(curElm.Transaction=="insurance received")}>{curElm.Transaction}</label>
                          <label className="badge badge-primary" style={{backgroundColor:"#389669", border:"0.1rem solid #389669"}} hidden={(curElm.Transaction=="cash drawings by admin")||(curElm.Transaction=="income by admin")||(curElm.Transaction=="income by employee")||(curElm.Transaction=="loan disbursal")||(curElm.Transaction=="expense by admin")||(curElm.Transaction=="expense by employee")||(curElm.Transaction=="cash entry by admin")||(curElm.Transaction=="cash entry by employee")||(curElm.Transaction=="cash drawings by employee")||(curElm.Transaction=="emi collection")||(curElm.Transaction=="salary payment")||(curElm.Transaction=="loan disbursal")||(curElm.Transaction=="PF received")||(curElm.Transaction=="insurance received")}>{curElm.Transaction}</label>
                          <label className="badge badge-primary" style={{backgroundColor:"#389669", border:"0.1rem solid #389669"}} hidden={(curElm.Transaction=="cash drawings by admin")||(curElm.Transaction=="income by admin")||(curElm.Transaction=="income by employee")||(curElm.Transaction=="loan disbursal")||(curElm.Transaction=="expense by admin")||(curElm.Transaction=="expense by employee")||(curElm.Transaction=="cash entry by admin")||(curElm.Transaction=="cash entry by employee")||(curElm.Transaction=="cash drawings by employee")||(curElm.Transaction=="emi collection")||(curElm.Transaction=="salary payment")||(curElm.Transaction=="processing fee received")||(curElm.Transaction=="loan disbursal")||(curElm.Transaction=="insurance received")}>{curElm.Transaction}</label>
                          <label className="badge badge-primary" style={{backgroundColor:"#389669", border:"0.1rem solid #389669"}} hidden={(curElm.Transaction=="cash drawings by admin")||(curElm.Transaction=="income by admin")||(curElm.Transaction=="income by employee")||(curElm.Transaction=="loan disbursal")||(curElm.Transaction=="expense by admin")||(curElm.Transaction=="expense by employee")||(curElm.Transaction=="cash entry by admin")||(curElm.Transaction=="cash entry by employee")||(curElm.Transaction=="cash drawings by employee")||(curElm.Transaction=="emi collection")||(curElm.Transaction=="salary payment")||(curElm.Transaction=="processing fee received")||(curElm.Transaction=="PF received")||(curElm.Transaction=="loan disbursal")}>{curElm.Transaction}</label>
                          </td>
                         <td> {curElm.Type}</td>
                         <td>{curElm.Mode}</td>
                         <td>{curElm.Particular}</td>
                         <td>{curElm.Updation_remarks}</td>
                         <td>{curElm.Amount}</td>
                         <td>{curElm.Created_by} <span hidden={curElm.Account_type=="admin"}>(EMP ID: {curElm.User_id})</span><span hidden={curElm.Account_type=="employee"}>(ADMIN ID: {curElm.User_id})</span></td>
                         <td>{curElm.Date_created} ({curElm.Time_created})</td>
                          
                        </tr>
                        </>
                         )
                         }
					   
	                  		 )
                         }
                   
                        {/*
                       data.map((curElm,ind)=>{

                        return(
                        <>
                        <tr key={curElm.Id}>
                            <td hidden={(logtype == "user")||(cd_col1_val==1)}>{curElm.Admin_sn}</td>
                            <td hidden={(logtype == "admin")||(cd_col1_val==1)}>{curElm.User_sn}</td>
                            <td hidden={cd_col2_val==1} >{curElm.Tr_no}</td>
                            <td hidden={cd_col3_val==1}>{curElm.Date}</td>
                            <td hidden={cd_col4_val==1}><label className="badge badge-danger" hidden={(curElm.Type == "entry")||(curElm.Type == "contra entry")||(curElm.Type == "income")||(curElm.Type == "expense")||(curElm.Type == "outstanding income payment")||(curElm.Type == "outstanding expense payment")||(curElm.Type == "sales")||(curElm.Type == "salesreturn")||(curElm.Type == "purchases")||(curElm.Type == "purchasereturns")||(curElm.Type == "creditor payment")||(curElm.Type == "debtor payment")}>{curElm.Type}</label>
                            <label className="badge badge-success" hidden={(curElm.Type == "drawings")||(curElm.Type== "contra entry")||(curElm.Type == "income")||(curElm.Type == "expense")||(curElm.Type == "outstanding income payment")||(curElm.Type == "outstanding expense payment")||(curElm.Type == "sales")||(curElm.Type == "salesreturn")||(curElm.Type == "purchases")||(curElm.Type == "purchasereturns")||(curElm.Type == "creditor payment")||(curElm.Type == "debtor payment")}>{curElm.Type}</label>
                            <label className="badge badge-info" hidden={(curElm.Type == "drawings")||(curElm.Type== "entry")||(curElm.Type == "income")||(curElm.Type == "expense")||(curElm.Type == "outstanding income payment")||(curElm.Type == "outstanding expense payment")||(curElm.Type == "sales")||(curElm.Type == "salesreturn")||(curElm.Type == "purchases")||(curElm.Type == "purchasereturns")||(curElm.Type == "creditor payment")||(curElm.Type == "debtor payment")}>{curElm.Type}</label>
                            <label className="badge badge-warning" hidden={(curElm.Type == "drawings")||(curElm.Type== "entry")||(curElm.Type == "contra entry")||(curElm.Type == "income")||(curElm.Type == "outstanding income payment")||(curElm.Type == "outstanding expense payment")||(curElm.Type == "sales")||(curElm.Type == "salesreturn")||(curElm.Type == "purchases")||(curElm.Type == "purchasereturns")||(curElm.Type == "creditor payment")||(curElm.Type == "debtor payment")}>{curElm.Type}</label>
                            <label className="badge badge-primary" hidden={(curElm.Type == "drawings")||(curElm.Type== "entry")||(curElm.Type == "expense")||(curElm.Type == "contra entry")||(curElm.Type == "outstanding income payment")||(curElm.Type == "outstanding expense payment")||(curElm.Type == "sales")||(curElm.Type == "salesreturn")||(curElm.Type == "purchases")||(curElm.Type == "purchasereturns")||(curElm.Type == "creditor payment")||(curElm.Type == "debtor payment")}>{curElm.Type}</label>
                            <label className="badge badge-primary" style={{backgroundColor:"#065f69",border:"none"}} hidden={(curElm.Type == "drawings")||(curElm.Type== "entry")||(curElm.Type == "expense")||(curElm.Type == "contra entry")||(curElm.Type == "income")||(curElm.Type == "outstanding expense payment")||(curElm.Type == "sales")||(curElm.Type == "salesreturn")||(curElm.Type == "purchases")||(curElm.Type == "purchasereturns")||(curElm.Type == "creditor payment")||(curElm.Type == "debtor payment")}>{curElm.Type}</label>
                            <label className="badge badge-primary" style={{backgroundColor:"#c99c20",border:"none"}} hidden={(curElm.Type == "drawings")||(curElm.Type== "entry")||(curElm.Type == "expense")||(curElm.Type == "contra entry")||(curElm.Type == "outstanding income payment")||(curElm.Type == "income")||(curElm.Type == "sales")||(curElm.Type == "salesreturn")||(curElm.Type == "purchases")||(curElm.Type == "purchasereturns")||(curElm.Type == "creditor payment")||(curElm.Type == "debtor payment")}>{curElm.Type}</label>
                            <label className="badge badge-primary" style={{backgroundColor:"#b4179d",border:"none"}} hidden={(curElm.Type == "drawings")||(curElm.Type== "entry")||(curElm.Type == "expense")||(curElm.Type == "contra entry")||(curElm.Type == "outstanding income payment")||(curElm.Type == "income")||(curElm.Type == "outstanding expense payment")||(curElm.Type == "salesreturn")||(curElm.Type == "purchases")||(curElm.Type == "purchasereturns")||(curElm.Type == "creditor payment")||(curElm.Type == "debtor payment")}>{curElm.Type}</label>
                            <label className="badge badge-primary" style={{backgroundColor:"#08a1b5",border:"none"}} hidden={(curElm.Type == "drawings")||(curElm.Type== "entry")||(curElm.Type == "expense")||(curElm.Type == "contra entry")||(curElm.Type == "outstanding income payment")||(curElm.Type == "income")||(curElm.Type == "outstanding expense payment")||(curElm.Type == "sales")||(curElm.Type == "purchases")||(curElm.Type == "purchasereturns")||(curElm.Type == "creditor payment")||(curElm.Type == "debtor payment")}>{curElm.Type}</label>
                            <label className="badge badge-primary" style={{backgroundColor:"#df6150",border:"none"}} hidden={(curElm.Type == "drawings")||(curElm.Type== "entry")||(curElm.Type == "expense")||(curElm.Type == "contra entry")||(curElm.Type == "outstanding income payment")||(curElm.Type == "income")||(curElm.Type == "outstanding expense payment")||(curElm.Type == "salesreturn")||(curElm.Type == "sales")||(curElm.Type == "purchasereturns")||(curElm.Type == "creditor payment")||(curElm.Type == "debtor payment")}>{curElm.Type}</label>
                            <label className="badge badge-primary" style={{backgroundColor:"#f48ab2",border:"none"}} hidden={(curElm.Type == "drawings")||(curElm.Type== "entry")||(curElm.Type == "expense")||(curElm.Type == "contra entry")||(curElm.Type == "outstanding income payment")||(curElm.Type == "income")||(curElm.Type == "outstanding expense payment")||(curElm.Type == "sales")||(curElm.Type == "purchases")||(curElm.Type == "salesreturn")||(curElm.Type == "creditor payment")||(curElm.Type == "debtor payment")}>{curElm.Type}</label>
                            <label className="badge badge-primary" style={{backgroundColor:"#f5ea02",border:"none"}} hidden={(curElm.Type == "drawings")||(curElm.Type== "entry")||(curElm.Type == "expense")||(curElm.Type == "contra entry")||(curElm.Type == "outstanding income payment")||(curElm.Type == "income")||(curElm.Type == "outstanding expense payment")||(curElm.Type == "sales")||(curElm.Type == "purchases")||(curElm.Type == "salesreturn")||(curElm.Type == "purchasereturns")||(curElm.Type == "debtor payment")}>{curElm.Type}</label>
                            <label className="badge badge-primary" style={{backgroundColor:"#3d3482",border:"none"}} hidden={(curElm.Type == "drawings")||(curElm.Type== "entry")||(curElm.Type == "expense")||(curElm.Type == "contra entry")||(curElm.Type == "outstanding income payment")||(curElm.Type == "income")||(curElm.Type == "outstanding expense payment")||(curElm.Type == "sales")||(curElm.Type == "purchases")||(curElm.Type == "salesreturn")||(curElm.Type == "purchasereturns")||(curElm.Type == "creditor payment")}>{curElm.Type}</label></td>

                            <td hidden={cd_col5_val==1}>{curElm.Particular}</td>
                            <td hidden={cd_col6_val==1}>{curElm.Amount_in}</td>
                            <td hidden={cd_col7_val==1}>{curElm.Amount_out}</td>
                            {/*<td>{curElm.Amount_in+curElm.Amount_out}</td> 
                            <td hidden={cd_col8_val==1}>{curElm.Updated_by}</td>
                            <td hidden={cd_col9_val==1}>{curElm.Date_updated} ({curElm.Time_updated})</td>
                            <td hidden={cd_col10_val==1}><i className="mdi mdi-pencil-box text-warning" style={{fontSize:"1.4rem"}} onClick={()=>{ 
                        //Setting Cash Id and Redirect to Edit
                      sessionStorage.setItem("CashId00",curElm.Id);sessionStorage.setItem("Trno00",curElm.Tr_no);getcounterdatabyId();document.getElementById('edit-form').style.display = 'block'; }}></i></td>
                            <td hidden={cd_col11_val==1}><i className="mdi mdi-delete-forever text-danger" style={{fontSize:"1.4rem"}} onClick={()=>{ 
                        //Setting Counter Id for Delete
                      sessionStorage.setItem("DCashId00",curElm.Id);      sessionStorage.setItem("DTrno00",curElm.Tr_no); document.getElementById('delete-card').style.display = 'block'; }}></i></td>
                          </tr>
                          </>
                        )
                        }
					   
		                  	)
                        }
                        */}
                          
                         
                        </tbody>
                      </table>
                    </div>
                    
                    {/* PAGINATION SECTION START */}
                    <div>
                       <div class="col-12" style={{marginBottom:"2rem",marginTop:"1rem"}}>
                        <div class="template-demo d-flex justify-content-between">
                        <div></div>

                       
                          <div class="dropdown">
                          <button class="btn" onClick={()=>{ const pgNumber=pageNumber-1; setpageNumber(pgNumber); getData();  }}><i class="mdi mdi-arrow-left-drop-circle text-success"></i></button>
                          <button class="btn" onClick={()=>{ const pgNumber=pageNumber+1; setpageNumber(pgNumber); getData();  }}><i class="mdi mdi-arrow-right-drop-circle text-success"></i></button>
                          </div>
                          </div>
                          </div>
                    </div>
                    {/* PAGINATION SECTION END */}
                  </div>
                </div>
              </div>

            {/* CASH TRANSACTIONS TABLE SECTION END */}

            
          </div>
          {/*} content-wrapper ends */}
          {/*} partial:partials/_footer.html */}
       {/*}   <footer className="footer">
            <div className="d-sm-flex justify-content-center justify-content-sm-between">
              <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2021 <a href="https://www.bootstrapdash.com/" target="_blank">BootstrapDash</a>. All rights reserved.</span>
              <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i className="mdi mdi-heart text-danger"></i></span>
            </div>
          </footer> */}
          {/*} partial */}
        </div>
        {/*} main-panel ends */}
      </div>
      {/*} page-body-wrapper ends */}
    </div>
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>
</>
);

//}
}
export default Employee_transaction_history;