import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';

import './Resources/dashboard.css';
import axios from './../axios.js';
import Navbar from './Supportingfiles/navbar';
import Sidebar from './Supportingfiles/sidebar';
//import Create_loanrequests from './create_newaccounts';
import { useLocation } from 'react-router-dom';

import {useRef as useref} from 'react';
import {useReactToPrint} from 'react-to-print';


function Employee_view_loan_application(){

   //const imgpath = 'http://127.0.0.1:8000/storage/';
  //const imgpath = 'https://pragatiprathmik.com/api/';
  const imgpath = 'https://api.pragatiprathmik.com/storage/';

  

    //Invoice Print Function
    const componentRef = useref();
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });

    

 //Checking Sessions
 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logtype=sessionStorage.getItem("logType00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");
 const logname=sessionStorage.getItem("logName00");
 const editId=sessionStorage.getItem("EditId00");
 const branch_id=sessionStorage.getItem("branchId00");

 
 //SETTING DATA VARIABLES
 const[application_no,setapplication_no]=useState();
 const[customer_id,setcustomer_id]=useState();
 const[branch_code,setbranch_code]=useState();
 const[account_number,setaccount_number]=useState();
 const[first_name,setfirst_name]=useState();
 const[last_name,setlast_name]=useState();
 const[email,setemail]=useState();
 const[contact,setcontact]=useState();
 const[scheme_code,setscheme_code]=useState();
 const[scheme_name,setscheme_name]=useState();
 const[loan_amount,setloan_amount]=useState();
 const[interest_rate,setinterest_rate]=useState();
 const[pf_per,setpf_per]=useState();
 const[pf_amount,setpf_amount]=useState();
 const[death_insurance,setdeath_insurance]=useState();
 const[health_insurance,sethealth_insurance]=useState();
 const[gst_per,setgst_per]=useState();
 const[gst_amount,setgst_amount]=useState();
 const[total_additions,settotal_additions]=useState();
 const[processing_fee,setprocessing_fee]=useState();
 const[loan_interest,setloan_interest]=useState();
 const[duration,setduration]=useState();
 const[minimum_monthly_income,setminimum_monthly_income]=useState();
 const[repayable_amount,setrepayable_amount]=useState();
 const[emi,setemi]=useState();
 const[monthly_income,setmonthly_income]=useState();
 const[account_type,setaccount_type]=useState();
 const[cibil_score,setcibil_score]=useState();
 const[date_of_cibil,setdate_of_cibil]=useState();
 const[loan_purpose,setloan_purpose]=useState();
 const[bank_name,setbank_name]=useState();
 const[bank_person_name,setbank_person_name]=useState();
 const[ifsc_code,setifsc_code]=useState();
 const[bank_account_number,setbank_account_number]=useState();
 const[application_remarks,setapplication_remarks]=useState();
 const[document_file_name,setdocument_file_name]=useState();
 const[document_file_link,setdocument_file_link]=useState();
 const[cibil_proof_image,setcibil_proof_image]=useState();
 const[self_certification_image_link,setself_certification_image_link]=useState();
 const[loan_status,setloan_status]=useState();
 const[application_status,setapplication_status]=useState();
 const[employee_remarks,setemployee_remarks]=useState();
 const[loan_remarks,setloan_remarks]=useState();
 const[emis_paid,setemis_paid]=useState();
 const[emis_due,setemis_due]=useState();
 const[group_name,setgroup_name]=useState();
 const[date_of_birth,setdate_of_birth]=useState();
 const[gender,setgender]=useState();
 const[address,setaddress]=useState();
 const[city,setcity]=useState();
 const[state,setstate]=useState();
 const[pincode,setpincode]=useState();
 const[country,setcountry]=useState();
 const[coapplicant_name,setcoapplicant_name]=useState();
 const[coapplicant_fathers_name,setcoapplicant_fathers_name]=useState();
 const[coapplicant_cibil_score,setcoapplicant_cibil_score]=useState();
 const[coapplicant_cibil_date,setcoapplicant_cibil_date]=useState();
 const[coapplicant_contact,setcoapplicant_contact]=useState();
 const[coapplicant_relation,setcoapplicant_relation]=useState();
 const[coapplicant_address,setcoapplicant_address]=useState();
 const[disbursal_date,setdisbursal_date]=useState();
 const[disbursement_status,setdisbursement_status]=useState();
 const[customer_image_path,setcustomer_image_path]=useState();

//Header
const[haddress,sethaddress]=useState();const updatehaddress = (event) => { sethaddress(event.target.value);}; const[haddress_er,sethaddress_er]=useState();
const[hcity,sethcity]=useState();const updatehcity = (event) => { sethcity(event.target.value);}; const[hcity_er,sethcity_er]=useState();
const[hstate,sethstate]=useState();const updatehstate = (event) => { sethstate(event.target.value);}; const[hstate_er,sethstate_er]=useState();
const[hpincode,sethpincode]=useState();const updatehpincode = (event) => { sethpincode(event.target.value);}; const[hpincode_er,sethpincode_er]=useState();
const[hemail,sethemail]=useState();const updatehemail = (event) => { sethemail(event.target.value);}; const[hemail_er,sethemail_er]=useState();
const[hcontact,sethcontact]=useState();const updatehcontact = (event) => { sethcontact(event.target.value);}; const[hcontact_er,sethcontact_er]=useState();

  //ERROR VARIABLES 
  const[error_msg,seterror_msg]=useState();

  
  // gdrive data
  const[gdrive_data,setgdrive_data]=useState([],);


 useEffect(()=>{
   
  getData();
  
  },[])
 

//******************* VIEW LOAN APPLICATION FUNCTION START  ********************//
function getData(){
 

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&(editId>0)&&(branch_id>0)){
 
 axios.post("crm-employee-view-loan-application", {
   Log_id:logid,
   Token:token,
   Edit_id:editId,
   Branch_id:branch_id


   })
 .then((response) => {


  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("logName00");
     sessionStorage.removeItem("EditId00");
     window.location.replace("/");
  }

  //Data Not Found
  if((response.data.error)==103){  seterror_msg("Data Not Found for Branch Id !");}

  //Success Data Fetched
  if(response.data.successvar==1){
   //Setting Data
   setapplication_no(response.data.application_no);
   setcustomer_id(response.data.customer_id);
   setaccount_number(response.data.account_number);
   setbranch_code(response.data.branch_code);
   setaccount_number(response.data.account_number);
   setfirst_name(response.data.first_name);
   setlast_name(response.data.last_name);
   setcontact(response.data.contact);
   setemail(response.data.email);
   setscheme_code(response.data.scheme_code);
   setscheme_name(response.data.scheme_name);
   setloan_amount(response.data.loan_amount);
   setinterest_rate(response.data.interest_rate);
   setpf_per(response.data.pf_per);
   setpf_amount(response.data.pf_amount);
   setdeath_insurance(response.data.death_insurance);
   sethealth_insurance(response.data.health_insurance);
   setgst_per(response.data.gst_per);
   setgst_amount(response.data.gst_amount);
   settotal_additions(response.data.total_additions);
   setprocessing_fee(response.data.processing_fee);
   setloan_interest(response.data.loan_interest);
   setduration(response.data.duration);
   setminimum_monthly_income(response.data.minimum_monthly_income);
   setrepayable_amount(response.data.repayable_amount);
   setemi(response.data.emi);
   setmonthly_income(response.data.monthly_income);
   setaccount_type(response.data.account_type);
   setcibil_score(response.data.cibil_score);
   setdate_of_cibil(response.data.date_of_cibil);
   setloan_purpose(response.data.loan_purpose);
   setbank_name(response.data.bank_name);
   setbank_person_name(response.data.bank_person_name);
   setifsc_code(response.data.ifsc_code);
   setbank_account_number(response.data.bank_account_number);
   setapplication_remarks(response.data.application_remarks);
   setdocument_file_name(response.data.document_file_name);
   setdocument_file_link(response.data.document_file_link);
   setcibil_proof_image((response.data.cibil_proof_image).slice(7));
   setself_certification_image_link((response.data.self_certification_image_link).slice(7));
   setloan_status(response.data.loan_status);
   setapplication_status(response.data.application_status);
   setemployee_remarks(response.data.employee_remarks);
   setloan_remarks(response.data.loan_remarks);
   setemis_paid(response.data.emis_paid);
   setemis_due(response.data.emis_due);
   setgroup_name(response.data.group_name);
   setdate_of_birth(response.data.date_of_birth);
   setgender(response.data.gender);
   setaddress(response.data.address);
   setcity(response.data.city);
   setstate(response.data.state);
   setpincode(response.data.pincode);
   setcoapplicant_name(response.data.coapplicant_name);
   setcoapplicant_fathers_name(response.data.coapplicant_fathers_name);
   setcoapplicant_contact(response.data.coapplicant_contact);
   setcoapplicant_relation(response.data.coapplicant_relation);
   setcoapplicant_cibil_score(response.data.coapplicant_cibil_score);
   setcoapplicant_cibil_date(response.data.coapplicant_cibil_date);
   setdisbursal_date(response.data.disbursal_date);
   setdisbursement_status(response.data.disbursement_status);
   setcustomer_image_path((response.data.customer_image_path).slice(7));

   //Setting gdrive data
   setgdrive_data(response.data.gdrive_data);


  }

  //FILTER DATA
  //Validation Errors
  if(response.data.validatorerror){ seterror_msg("Validation error!"); }

  //Data Violation
  if(response.data.error==102){  seterror_msg("Violation Error"); }

 
 
 });
 
 }
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("logName00");
   sessionStorage.removeItem("EditId00");
   window.location.replace("/");
 
  }
 
 }
  
 //******************* VIEW LOAN APPLICATION FUNCTION END  ********************//


 
 

return(
    <>

<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Pragati Prathmik Sakh Sahkari Samiti Maryadit | Admin Dashboard</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>
    <div className="container-scroller">

   {/*}   <Sidebar /> */}
    <div className="container-fluid page-body-wrapper">


       <Navbar />
        {/*} partial */}
        <div className="main-panel">

        

          <div className="content-wrapper pb-0">

          

          <div className="page-header flex-wrap">
            <div className="header-left"></div>
              <div className="header-right d-flex flex-wrap mt-2 mt-sm-0">
                <div className="d-flex align-items-center">
                  <a href="#">
                    <p className="m-0 pe-3">EMPLOYEE PANEL</p>
                  </a>
                  <a className="ps-3 me-4" href="#">
                    <p className="m-0">LOAN APPLICATION</p>
                  </a>
                </div>
        
              </div>
            </div>


            <button class="btn btn-warning" onClick={()=>{
            document.getElementById("upd-header-card").style.display="block";
            }}>update header</button>
             <button hidden={loan_status!="approved"} type="button" style={{backgroundColor:"#196fb0", marginLeft:"2rem"}} class="btn btn-secondary btn-icon-text" onClick={handlePrint}> Print <i class="mdi mdi-file-document"></i>
             </button>
             <a href="/admin-dashboard-customer-draft"><button type="button" style={{marginLeft:"2rem"}} class="btn btn-secondary"> Customer Agreement Draft
             </button></a>


           {/* UPDATE HEADER INFO SECTION START */}
            <div class="card" id="upd-header-card" style={{display:"none"}}>
              
              <div class="card-body" style={{textAlign:"left"}}>
                <h4 class="card-title">Update Header Information</h4>
                <div class="form-sample">
                  <p class="card-description">update header information</p>
                  
                  
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Header Address</label>
                        <div class="col-sm-9">
                          <input type="text" class="form-control" id="haddress" value={haddress} onChange={updatehaddress} />
                          <code>{haddress_er}</code>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Header City</label>
                        <div class="col-sm-9">
                          <input type="text" class="form-control" id="hcity" value={hcity} onChange={updatehcity} />
                          <code>{hcity_er}</code>
                        </div>
                      </div>
                    </div>
                    </div>
                    <div class="row">
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Header State</label>
                        <div class="col-sm-9">
                          <input type="text" class="form-control" id="hstate" value={hstate} onChange={updatehstate} />
                          <code>{hstate_er}</code>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Header Pincode</label>
                        <div class="col-sm-9">
                          <input type="text" class="form-control" id="hpincode" value={hpincode} onChange={updatehpincode} />
                          <code>{hpincode_er}</code>
                        </div>
                      </div>
                    </div>
                    </div>
                    <div class="row">
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Header Email</label>
                        <div class="col-sm-9">
                          <input type="text" class="form-control" id="hemail" value={hemail} onChange={updatehemail} />
                          <code>{hemail_er}</code>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Header Contact</label>
                        <div class="col-sm-9">
                          <input type="text" class="form-control" id="hcontact" value={hcontact} onChange={updatehcontact} />
                          <code>{hcontact_er}</code>
                        </div>
                      </div>
                    </div>
                    </div>

                  
                  
                   {/*} <button class="btn btn-lg btn-success" style={{fontWeight:"bold"}} onClick={()=>{
                       sethaddress(document.getElementById("haddress").value);
                       sethcity(document.getElementById("hcity").value);
                       sethstate(document.getElementById("hstate").value);
                       sethpincode(document.getElementById("hpincode").value);
                       sethemail(document.getElementById("hemail").value);
                       sethcontact(document.getElementById("hcontact").value);
                    }}>Update</button> */}

                    <button class="btn btn-lg btn-outline-danger" style={{fontWeight:"bold"}} onClick={()=>{ 
                      document.getElementById("upd-header-card").style.display="none";
                    }}>
                      Close
                    </button>
                   

                 
               
                </div>
              </div>
            </div>
            {/* UPDATE HEADER INFO SECTION END */}

                <div class="card" style={{marginTop:"2rem"}}>
              
                  <div class="card-body" style={{textAlign:"left"}}>

                  {/* Print Section Start */}
                  <div class="" style={{padding:"1rem"}}  id="invoice" ref={componentRef}>
                  {/* SANCTION LETTER HEADER START */}


                  <div class="offer-letter-head" style={{borderBottom:"0.2rem solid #038d8e"}}>
                  <div style={{flexDirection:"row", display:"flex"}}>
                  <div style={{width:"25%"}} >
                    <img src="images/crmlogo.png" alt="profile" style={{width:"16rem", height:"16rem"}} />
                  </div>
                  <div class="row" style={{width:"75%"}}>

                    <div class="row col-md-12">
                    <h1 style={{color:"#038d8e", marginBottom:"0", textAlign:"center"}}><b>Pragati Prathmik Sakh Sahkari Samiti Maryadit</b></h1>
                    
                    <p style={{marginTop:"0", textAlign:"center"}}><b>Address:</b> {haddress} <br/>
                    {hcity} ({hstate}), ({hpincode}) <br/><br/>
                    <b>Email:</b> {hemail}, <b>Contact:</b> {hcontact}</p>

                  
                    </div>
                    </div>
                    </div>
                    </div>
                    
                    {/* SANCTION LETTER HEADER END */}
                    
                      <h2 style={{textAlign:"center", marginTop:"2rem"}}>Loan Sanction Letter</h2>
                      <hr/>
                      <div class="row d-print-flex mt-3">
                      <div class="row col-md-4 col d-print-inline-block"><b>Customer Image:</b></div>
                      <div class="row col-md-4 col d-print-inline-block"></div>
                      <div class="row col-md-4 col d-print-inline-block"><img style={{height:"18rem", width:"80%"}} src={imgpath+customer_image_path} /></div>
                      </div>
                      
                    
                    <h3 class="card-title">CUSTOMER DETAILS</h3>
                    <div class="form-sample" style={{textAlign:"left"}}>
                    
                    <div class="row d-print-flex" style={{textAlign:"left"}}>

                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Customer Id:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{customer_id}</p></div>
                    </div>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Customer Name:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{first_name} {last_name}</p></div>
                    </div>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Contact:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{contact}</p></div>
                    </div>
                    </div>
                    <div class="row d-print-flex" style={{textAlign:"left"}}>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Email:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{email}</p></div>
                    </div>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Group Name:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{group_name}</p></div>
                    </div>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Date of Birth:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{date_of_birth}</p></div>
                    </div>
                    </div>
                    <div class="row d-print-flex" style={{textAlign:"left"}}>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Gender:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{gender}</p></div>
                    </div>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Account Number:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{account_number}</p></div>
                    </div>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Branch Code:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{branch_code}</p></div>
                    </div>
                    </div>
                    <div class="row d-print-flex" style={{textAlign:"left"}}>
                    <div class="row col-md-12">
                    <div class="row col-sm-3"> <p><b>Address:</b></p></div>
                    <div class="row col-sm-9" > <p>{address}</p></div>
                    </div>
                    </div>
                    <div class="row d-print-flex" style={{textAlign:"left"}}>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>City:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{city}</p></div>
                    </div>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>State:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{state}</p></div>
                    </div>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Pincode:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{pincode}</p></div>
                    </div>
                    </div>

                    <hr/>
                    <h3 class="card-title">COAPPLICANT DETAILS</h3>
                    <div class="row d-print-flex" style={{textAlign:"left"}}>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Full Name:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{coapplicant_name}</p></div>
                    </div>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Father's Name:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{coapplicant_fathers_name}</p></div>
                    </div>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Relation:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{coapplicant_relation}</p></div>
                    </div>
                    </div>
                    <div class="row d-print-flex" style={{textAlign:"left"}}>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Cibil Score:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{coapplicant_cibil_score}</p></div>
                    </div>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Cibil Date:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{coapplicant_cibil_date}</p></div>
                    </div>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Contact:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{coapplicant_contact}</p></div>
                    </div>
                    </div>
                    <div class="row d-print-flex" style={{textAlign:"left"}}>
                    <div class="row col-md-12 col d-print-inline-block">
                    <div class="row col-sm-3 col d-print-inline-block"> <p><b>Address:</b></p></div>
                    <div class="row col-sm-9 col d-print-inline-block"> <p>{coapplicant_address}</p></div>
                    </div>
                    </div>
                   
                

                      
                      <hr/>


                      <h3 class="card-title">LOAN DETAILS</h3>
                    <div class="row d-print-flex" style={{textAlign:"left"}}>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Application No.:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{application_no}</p></div>
                    </div>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Scheme Code:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{scheme_code}</p></div>
                    </div>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Scheme Name:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{scheme_name}</p></div>
                    </div>
                    </div>
                    <div class="row d-print-flex" style={{textAlign:"left"}}>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Loan Amount:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{loan_amount}</p></div>
                    </div>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Interest Rate:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{interest_rate}</p></div>
                    </div>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Interest Amount:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{loan_interest}</p></div>
                    </div>
                    </div>
                    <div class="row d-print-flex" style={{textAlign:"left"}}>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>PF %:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{pf_per}</p></div>
                    </div>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>PF Amount:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{pf_amount}</p></div>
                    </div>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Health Insurance:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{health_insurance}</p></div>
                    </div>
                    </div>
                    <div class="row d-print-flex" style={{textAlign:"left"}}>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>GST %:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{gst_per}</p></div>
                    </div>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>GST Amount:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{gst_amount}</p></div>
                    </div>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Death Insurance:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{death_insurance}</p></div>
                    </div>
                    </div>
                    <div class="row d-print-flex" style={{textAlign:"left"}}>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Processing Fees:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{processing_fee}</p></div>
                    </div>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Total Additions:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{total_additions}</p></div>
                    </div>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Loan Purpose:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{loan_purpose}</p></div>
                    </div>
                    </div>
                    <div class="row d-print-flex" style={{textAlign:"left"}}>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Duration:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{duration} weeks</p></div>
                    </div>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Repayable Amount:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{repayable_amount}</p></div>
                    </div>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>EMI:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{emi}</p></div>
                    </div>
                    </div>
                    <div class="row d-print-flex" style={{textAlign:"left"}}>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Monthly Income:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{monthly_income}</p></div>
                    </div>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Cibil Score:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{cibil_score}</p></div>
                    </div>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Date of Cibil:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{date_of_cibil}</p></div>
                    </div>
                   </div>
                   <div class="row d-print-flex" style={{textAlign:"left"}}>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Disbursal Date:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{disbursal_date}</p></div>
                    </div>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Disbursement Status:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{disbursement_status}</p></div>
                    </div>
                   {/*} <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Date of Cibil:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{date_of_cibil}</p></div>
                    </div> */}
                   </div> 

                    <hr/>
                    <h3 class="card-title">BANK DETAILS</h3>
                    <div class="row d-print-flex" style={{textAlign:"left"}}>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Name as per Bank:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{bank_person_name}</p></div>
                    </div>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Bank Name:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{bank_name}</p></div>
                    </div>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>IFSC Code:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{ifsc_code}</p></div>
                    </div>
                    </div>
                    <div class="row d-print-flex" style={{textAlign:"left"}}>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Account Number:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{bank_account_number}</p></div>
                    </div>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Account Type:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{account_type}</p></div>
                    </div>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Loan Status:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block">
                    <p>
                    <label className="badge badge-success" hidden={(loan_status=="under review")||(loan_status=="rejected")||(loan_status=="completed")}>approved</label>
                    <label className="badge badge-warning" hidden={(loan_status=="approved")||(loan_status=="rejected"||(loan_status=="completed"))}>under review</label>
                    <label className="badge badge-danger" hidden={(loan_status=="under review")||(loan_status=="approved")||(loan_status=="completed")}>rejected</label>                       
                    <label className="badge badge-primary" hidden={(loan_status=="under review")||(loan_status=="approved"||(loan_status=="rejected"))}>completed</label>                       
                    </p>
                    </div>
                    </div>
                    </div>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <hr/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    
                    <div class="row col-md-12 d-print-flex">
                    <div class="row col-sm-4 col d-print-inline-block text-center"> <p><b>Customer Signature</b></p></div>
                    <div class="row col-sm-4 col d-print-inline-block text-center"> <p><b>Loan Officer Signature</b></p></div>
                    <div class="row col-sm-4 col d-print-inline-block text-center"> <p><b>Branch Seal & Signature</b></p></div>
                    </div>
                    </div>
                    
                    </div>
                    </div>

                    {/* Print Section End */}

                     {/* G DRIVE DATA SECTION START */}
                     <hr/>
                    <h3 style={{textAlign:"center"}}>Gdrive Documents</h3>
                    <div className="table-responsive">
                      <table className="table table-hover table-bordered">
                        <thead>
                          <tr >
                     {/*}     <th style={{fontWeight:"bold"}} >Doc Id</th> */}
                            <th  style={{fontWeight:"bold"}} >Doc Name</th>
                            <th  style={{fontWeight:"bold"}} >Doc Type</th>
                            <th  style={{fontWeight:"bold"}} >Doc Link</th>
                            <th  style={{fontWeight:"bold"}} >Updated At</th>
                         {/*}   <th  style={{fontWeight:"bold"}} >Updated By</th> */}
                          </tr>
                        </thead>
                        <tbody>
                        {
                       gdrive_data.map((curElm1,ind)=>{

                         return(
                         <>
                        <tr>
                        {/*}  <td>{curElm1.Doc_id}</td> */}
                          <td>{curElm1.Doc_name}</td>
                          <td>{curElm1.Doc_type}</td>
                          <td><a href={curElm1.Doc_link}>{curElm1.Doc_link}</a></td>
                          <td>{curElm1.Date_updated} ({curElm1.Time_updated})</td>   
                        {/*}  <td>{curElm1.Updated_by}</td>        */}          
                        </tr>
                        </>
                         )
                         }
					   
	                  		 )
                         }
                      
                       
                          
                         
                        </tbody>
                      </table>
                    </div>
                    {/* G DRIVE DATA SECTION END */}

                    <hr />
                    <h3 class="card-title">DOCUMENTS AND ATTACHMENTS</h3>
                    <div class="row d-print-flex" style={{textAlign:"left"}}>
                    <div class="row col-md-12">
                    <div class="row col-sm-6"> <p><b>Employee Remarks:</b></p></div>
                    <div class="row col-sm-6"> <p>{employee_remarks}</p></div>
                    </div>
                    <div class="row col-md-12">
                    <div class="row col-sm-6"> <p><b>Loan Remarks:</b></p></div>
                    <div class="row col-sm-6"> <p>{loan_remarks}</p></div>
                    </div>
                  
                    <div class="row mt-2" style={{textAlign:"left"}}>
                    <div class="row col-sm-6"><p><b>Document File: </b></p></div>
                    <div class="row col-sm-6"><p>{document_file_name}(<a href={document_file_link}>{document_file_link}</a>)</p></div>
                    </div>
                    <br/>
                    <a href={imgpath+self_certification_image_link}><button class="btn btn-dark" >View Document Attachment</button></a>


                    

                    <row class="row mt-2">
                    <div class="row col-md-12"><img src={imgpath+cibil_proof_image} style={{height:"90vh", width:"100%"}} /></div>
                    </row>
                {/*}    <row class="row mt-2">
                    <div class="row col-md-12"><img src={imgpath+self_certification_image_link} style={{height:"90vh", width:"100%"}} /></div>
                    </row>*/}
                   
                    </div>
                  </div>
                

                </div>
          {/*} content-wrapper ends */}
          {/*} partial:partials/_footer.html */}
       {/*}   <footer className="footer">
            <div className="d-sm-flex justify-content-center justify-content-sm-between">
              <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2021 <a href="https://www.bootstrapdash.com/" target="_blank">BootstrapDash</a>. All rights reserved.</span>
              <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i className="mdi mdi-heart text-danger"></i></span>
            </div>
          </footer> */}
          {/*} partial */}
        </div>
        {/*} main-panel ends */}
      </div>
      {/*} page-body-wrapper ends */}
    </div>
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>
    </>

);
}
//}

export default Employee_view_loan_application;