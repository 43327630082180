import React from 'react';
import {useState,useEffect,Component} from 'react';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';





/* Import home from File */





import Home from './home.js';
import Adminlogin from './Admin/login.js';
import Customerlogin from './Customers/login.js';
import Employeelogin from './Employees/login.js';
import Admin_cash from './Admin/Admin_cash.js';
import Admin_bank from './Admin/Admin_bank.js';
import Admin_customers from './Admin/Admin_customers.js';
import Admin_newaccounts from './Admin/Admin_newaccounts.js';
import Admin_incomes from './Admin/Admin_incomes.js';
import Admin_expenses from './Admin/admin_expenses.js';
import Admin_employees from './Admin/Admin_employees.js';
import Admin_loanpayments from './Admin/Admin_loanpayments.js';
import Admin_loanrequests from './Admin/Admin_loanrequests.js';
import Employee_cash from './Employees/Employee_cash.js';
import Employee_bank from './Employees/Employee_bank.js';
import Employee_customers from './Employees/Employee_customers.js';
import Employee_newaccounts from './Employees/Employee_newaccounts.js';
import Employee_incomes from './Employees/Employee_incomes.js';
import Employee_expenses from './Employees/Employee_expenses.js';
import Employee_loanpayments from './Employees/Employee_loanpayments.js';
import Employee_loanrequests from './Employees/Employee_loanrequests.js';
import Employee_add_customer from './Employees/Employee_add_customer.js';
import Employee_edit_customer from './Employees/Employee_edit_customer.js';
import Employee_customer_profile from './Employees/Employee_customer_profile.js';
import Employee_customer_loans from './Employees/Employee_customer_loans.js';
import Employee_create_cash from './Employees/Employee_create_cash.js';
import Employee_create_bank from './Employees/Employee_create_bank.js';
import Employee_create_expense from './Employees/Employee_create_expense.js';
import Employee_create_income from './Employees/Employee_create_income.js';
import Employee_edit_cash from './Employees/Employee_edit_cash.js';
import Employee_edit_bank from './Employees/Employee_edit_bank.js';
import Employee_edit_expense from './Employees/Employee_edit_expense.js';
import Employee_edit_income from './Employees/Employee_edit_income.js';
import Admin_create_cash from './Admin/Admin_create_cash.js';
import Admin_edit_cash from './Admin/Admin_edit_cash.js';
import Admin_create_bank from './Admin/Admin_create_bank.js';
import Admin_edit_bank from './Admin/Admin_edit_bank.js';
import Admin_create_expense from './Admin/Admin_create_expense.js';
import Admin_edit_expense from './Admin/Admin_edit_expense.js';
import Admin_create_income from './Admin/Admin_create_income.js';
import Admin_edit_income from './Admin/Admin_edit_income.js';
import Admin_customer_profile from './Admin/Admin_customer_profile.js';
import Admin_loanschemes from './Admin/Admin_loanschemes.js';
import Admin_create_loanscheme from './Admin/Admin_create_loanscheme.js';
import Admin_edit_loanscheme from './Admin/Admin_edit_loanscheme.js';
import Employee_loanschemes from './Employees/Employee_loanschemes.js';
import Employee_customer_updations from './Employees/Employee_customer_updations.js';
import Admin_customer_updations from './Admin/Admin_customer_updations.js';
import Admin_transaction_history from './Admin/Admin_transaction_history.js';
import Employee_transaction_history from './Employees/Employee_transaction_history.js';
import Admin_branch from './Admin/Admin_branch.js';
import Admin_create_branch from './Admin/Admin_create_branch.js';
import Admin_edit_branch from './Admin/Admin_edit_branch.js';
import Admin_employee_profile from './Admin/Admin_employee_profile.js';
import Admin_add_employee from './Admin/Admin_add_employee.js';
import Admin_edit_employee from './Admin/Admin_edit_employee.js';
import Admin_employee_salary from './Admin/Admin_employee_salary.js';
import Admin_create_salary from './Admin/Admin_create_salary.js';
import Admin_edit_salary from './Admin/Admin_edit_salary.js';
import Employee_loan_application from './Employees/Employee_loan_application.js';
import Employee_customer_emis from './Employees/Employee_customer_emis.js';
import Admin_customer_loans from './Admin/Admin_customer_loans.js';
import Admin_customer_emis from './Admin/Admin_customer_emis.js';
import Customer_customer_profile from './Customers/Customer_customer_profile.js';
import Customer_customer_loans from './Customers/Customer_customer_loans.js';
import Customer_customer_emis from './Customers/Customer_customer_emis.js';
import Customer_dashboard from './Customers/Customer_dashboard.js';
import Admin_loan_scheme_details from './Admin/Admin_loan_scheme_details.js';
import Admin_add_bank from './Admin/Admin_add_bank.js';
import Admin_edit_customer from './Admin/Admin_edit_customer.js';
import Admin_view_customer_application from './Admin/Admin_view_customer_application.js';
import Employee_view_loan_application from './Employees/Employee_view_loan_application.js';
import Admin_view_loan_application from './Admin/Admin_view_loan_application.js';
import Admin_loan_approval from './Admin/Admin_loan_approval.js';
import Employee_pay_emi from './Employees/Employee_pay_emi.js';
import Home1 from './home1.js';
import Customer_customer_updations from './Customers/Customer_customer_updations.js';
import Customer_customer_emis_history from './Customers/Customer_emis_history.js';
import Admin_view_salary from './Admin/Admin_view_salary.js';
import Admin_view_emi from './Admin/Admin_view_emi.js';
import Employee_view_emi from './Employees/Employee_view_emi.js';
import Employee_loan_closure from './Employees/Employee_loan_closure.js';
import Employee_closure_requests from './Employees/Employee_closure_requests.js';
import Employee_view_closure from './Employees/Employee_view_closure.js';
import Admin_closure_requests from './Admin/Admin_closure_requests.js';
import Admin_view_closure from './Admin/Admin_view_closure.js';
import Admin_offer_letter from './Admin/Admin_offer_letter.js';
import Admin_create_offer_letter from './Admin/Admin_create_offer_letter.js';
import Admin_manage_employee_permissions from './Admin/Admin_manage_employee_permissions.js';
import Admin_cust_lp from './Admin/Admin_cust_lp.js';
import Employee_drive1 from './Employees/Employee_drive1.js';
import Admin_offerletters_history from './Admin/Admin_offerletters_history.js';
import Employee_customer_emicard from './Employees/Employee_customer_emicard.js';
import Admin_customer_emicard from './Admin/Admin_customer_emicard.js';
import Admin_loandisbursals from './Admin/Admin_loandisbursals.js';
import Employee_loandisbursals from './Employees/Employee_loandisbursals.js';
import Employee_disbursalcollection from './Employees/Employee_disbursalcollection.js';

function Routestosite(){

    return(
<>
<Router>
   <Routes>

   <Route exact path='/home' element={<Home1 />} />
   <Route exact path='/' element={<Home1 />} />

   <Route exact path='/home1' element={<Home />} />


   /* PRAGATI PRATHMIK SAKH SAHKARI SAMITI MARYADIT  LOAN CRM */

   /* ADMIN PANEL */
   <Route exact path='/adminpanel-login' element={<Adminlogin />} />
   <Route exact path='/adminpanel-dashboard-cash' element={<Admin_cash />} />
   <Route exact path='/adminpanel-dashboard-customers' element={<Admin_customers />} />
   <Route exact path='/adminpanel-dashboard-new-account-requests' element={<Admin_newaccounts />} />
   <Route exact path='/adminpanel-dashboard-add-bank' element={<Admin_add_bank />} />
   <Route exact path='/adminpanel-dashboard-bank' element={<Admin_bank />} />
   <Route exact path='/adminpanel-dashboard-incomes' element={<Admin_incomes />} />
   <Route exact path='/adminpanel-dashboard-expenses' element={<Admin_expenses />} />
   <Route exact path='/adminpanel-dashboard-employees' element={<Admin_employees />} />
   <Route exact path='/adminpanel-dashboard-loan-payments' element={<Admin_loanpayments />} />
   <Route exact path='/adminpanel-dashboard-loan-requests' element={<Admin_loanrequests />} />
   <Route exact path='/adminpanel-dashboard-create-cash' element={<Admin_create_cash />} />
   <Route exact path='/adminpanel-dashboard-edit-cash' element={<Admin_edit_cash />} />
   <Route exact path='/adminpanel-dashboard-create-bank' element={<Admin_create_bank />} />
   <Route exact path='/adminpanel-dashboard-edit-bank' element={<Admin_edit_bank />} />
   <Route exact path='/adminpanel-dashboard-create-expense' element={<Admin_create_expense />} />
   <Route exact path='/adminpanel-dashboard-edit-expense' element={<Admin_edit_expense />} />
   <Route exact path='/adminpanel-dashboard-create-income' element={<Admin_create_income />} />
   <Route exact path='/adminpanel-dashboard-edit-income' element={<Admin_edit_income />} />
   <Route exact path='/adminpanel-dashboard-customer-profile' element={<Admin_customer_profile />} />
   <Route exact path='/adminpanel-dashboard-edit-customer' element={<Admin_edit_customer />} />
   <Route exact path='/adminpanel-dashboard-loan-schemes' element={<Admin_loanschemes />} />
   <Route exact path='/adminpanel-dashboard-create-loanscheme' element={<Admin_create_loanscheme />} />
   <Route exact path='/adminpanel-dashboard-edit-loanscheme' element={<Admin_edit_loanscheme />} />
   <Route exact path='/admin-dashboard-customer-updations' element={<Admin_customer_updations />} />
   <Route exact path='/admin-dashboard-transaction-history' element={<Admin_transaction_history />} />
   <Route exact path='/admin-dashboard-branch' element={<Admin_branch />} />
   <Route exact path='/admin-dashboard-create-branch' element={<Admin_create_branch />} />
   <Route exact path='/admin-dashboard-edit-branch' element={<Admin_edit_branch />} />
   <Route exact path='/adminpanel-dashboard-employee-profile' element={<Admin_employee_profile />} />
   <Route exact path='/adminpanel-dashboard-add-employee' element={<Admin_add_employee />} />
   <Route exact path='/adminpanel-dashboard-edit-employee' element={<Admin_edit_employee />} />
   <Route exact path='/adminpanel-dashboard-employee-salary' element={<Admin_employee_salary />} />
   <Route exact path='/adminpanel-dashboard-create-salary' element={<Admin_create_salary />} />
   <Route exact path='/adminpanel-dashboard-customer-profile' element={<Admin_customer_profile />} />
   <Route exact path='/adminpanel-dashboard-edit-salary' element={<Admin_edit_salary />} />
   <Route exact path='/adminpanel-dashboard-customer-loans' element={<Admin_customer_loans />} />
   <Route exact path='/adminpanel-dashboard-customer-emis' element={<Admin_customer_emis /> } />
   <Route exact path='/adminpanel-dashboard-loanscheme-details' element={<Admin_loan_scheme_details />} />
   <Route exact path='/adminpanel-dashboard-view-customer-account-application' element={<Admin_view_customer_application />} />
   <Route exact path='/adminpanel-dashboard-view-loan-application' element={<Admin_view_loan_application />} />
   <Route exact path='/adminpanel-dashboard-loan-approval' element={<Admin_loan_approval />} />
   <Route exact path='/adminpanel-dashboard-view-salary' element={<Admin_view_salary />} />
   <Route exact path='/adminpanel-dashboard-view-emi' element={<Admin_view_emi />} />

   /* CUSTOMER PANEL */
   <Route exact path='/customer-login' element={<Customerlogin />} />
   <Route exact path='/customer-dashboard' element={<Customer_dashboard />} />
   <Route exact path='/customer-dashboard-profile' element={<Customer_customer_profile />} />
   <Route exact path='/customer-dashboard-loans' element={<Customer_customer_loans />} />
   <Route exact path='/customer-dashboard-emis' element={<Customer_customer_emis />} />
   <Route exact path='/customer-dashboard-updations' element={<Customer_customer_updations />} />
   <Route exact path='/customer-dashboard-emis-history' element={<Customer_customer_emis_history />} />

   /* EMPLOYEE PANEL */
   <Route exact path='/employee-login' element={<Employeelogin />} />
   <Route exact path='/employee-dashboard-cash' element={<Employee_cash />} />
   <Route exact path='/employee-dashboard-bank' element={<Employee_bank />} />
   <Route exact path='/employee-dashboard-customers' element={<Employee_customers />} />
   <Route exact path='/employee-dashboard-new-account-requests' element={<Employee_newaccounts />} />
   <Route exact path='/employee-dashboard-incomes' element={<Employee_incomes />} />
   <Route exact path='/employee-dashboard-expenses' element={<Employee_expenses />} />
   <Route exact path='/employee-dashboard-loan-payments' element={<Employee_loanpayments />} />
   <Route exact path='/employee-dashboard-loan-requests' element={<Employee_loanrequests />} />
   <Route exact path='/employee-dashboard-add-customer' element={<Employee_add_customer />} />
   <Route exact path='/employee-dashboard-edit-customer' element={<Employee_edit_customer />} />
   <Route exact path='/employee-dashboard-customer-profile' element={<Employee_customer_profile />} />
   <Route exact path='/employee-dashboard-customer-loans' element={<Employee_customer_loans />} />
   <Route exact path='/employee-dashboard-create-cash' element={<Employee_create_cash />} />
   <Route exact path='/employee-dashboard-create-bank' element={<Employee_create_bank />} />
   <Route exact path='/employee-dashboard-create-expense' element={<Employee_create_expense />} />
   <Route exact path='/employee-dashboard-create-income' element={<Employee_create_income />} />
   <Route exact path='/employee-dashboard-edit-cash' element={<Employee_edit_cash />} />
   <Route exact path='/employee-dashboard-edit-bank' element={<Employee_edit_bank />} />
   <Route exact path='/employee-dashboard-edit-expense' element={<Employee_edit_expense />} />
   <Route exact path='/employee-dashboard-edit-income' element={<Employee_edit_income />} />
   <Route exact path='/employee-dashboard-loan-schemes' element={<Employee_loanschemes />} />
   <Route exact path='/employee-dashboard-customer-updations' element={<Employee_customer_updations />} />
   <Route exact path='/employee-dashboard-transaction-history' element={<Employee_transaction_history />} />
   <Route exact path='/employee-dashboard-customer-loan-application' element={<Employee_loan_application />} />
   <Route exact path='/employee-dashboard-view-loan-application' element={<Employee_view_loan_application />} />
   <Route exact path='/employee-dashboard-customer-emis' element={<Employee_customer_emis />} />
   <Route exact path='/employee-dashboard-customer-pay-emi' element={<Employee_pay_emi />} />
   <Route exact path='/employee-dashboard-view-emi' element={<Employee_view_emi />} />
   <Route exact path='/employee-dashboard-loan-closure' element={<Employee_loan_closure />} />
   <Route exact path='/employee-dashboard-loan-closure-requests' element={<Employee_closure_requests />} />
   <Route exact path='/employee-dashboard-view-loan-closure' element={<Employee_view_closure />} />
   <Route exact path='/admin-dashboard-loan-closure-requests' element={<Admin_closure_requests />} />
   <Route exact path='/admin-dashboard-view-loan-closure' element={<Admin_view_closure />} />
   <Route exact path='/admin-dashboard-employee-offer-letter' element={<Admin_offer_letter />} />
   <Route exact path='/admin-dashboard-create-offer-letter' element={<Admin_create_offer_letter />} />
   <Route exact path='/adminpanel-dashboard-offer-letters-history' element={<Admin_offerletters_history />} />
   <Route exact path='/admin-dashboard-manage-employee-permissions' element={<Admin_manage_employee_permissions />} />
   <Route exact path='/employee-dashboard-customer-emicard' element={<Employee_customer_emicard />} />
   <Route exact path='/admin-dashboard-customer-emicard' element={<Admin_customer_emicard />} />
   <Route exact path='/admin-dashboard-loan-disbursals' element={<Admin_loandisbursals />} />
   <Route exact path='/employee-dashboard-loan-disbursals' element={<Employee_loandisbursals />} />
   <Route exact path='/employee-dashboard-disbursal-collection' element={<Employee_disbursalcollection />} />

   <Route exact path='/admin-dashboard-customer-draft' element={<Admin_cust_lp />} />

   //UPLOAD DOCUMENTS TO DRIVE
   <Route exact path='/employee-dashboard-uploadtodrive' element={<Employee_drive1 />} />

   </Routes>
   </Router>
</>
    );


}

export default Routestosite;