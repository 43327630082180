import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';

import './Resources/dashboard.css';
import axios from './../axios.js';
import Navbar from './Supportingfiles/navbar';
import Sidebar from './Supportingfiles/sidebar';
//import Create_loanrequests from './create_newaccounts';
import {useRef as useref} from 'react';
import {useReactToPrint} from 'react-to-print';


function Admin_cust_lp(){

    
    //Invoice Print Function
    const componentRef = useref();
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });


    return(
        <>
        
<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Pragati Prathmik Sakh Sahkari Samiti Maryadit | Dashboard</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>
  {/* Print Section Start */}
  <div class="" style={{padding:"1rem"}}  id="invoice" ref={componentRef}>
 <div class="" style={{padding:"2rem", textAlign:"left"}}>

<p>प्रगति प्राथमिक सहकारी समिति मर्यादित,</p>
<p>तह. बिरसिंहपुर जिला - सतना</p>
<p>&nbsp;</p>
<p>आज दिनांक ................................. को प्रगति प्राथमिक साख सहकारी समिति मर्यादित ने ऋणी ............................................................................. को राशि रूपये ......................... ऋण के रूप में प्रदान किये हैं। इस उक्त ऋण की वापसी ऋण पुर्नभुगतान हेतु निम्न शर्तों के अधीन अनुबंध निष्पादित किया जाता है -</p>
<ol>
<li>किस्त भुगतान का नियत समय प्रत्येक माह की 07 तारीख तक होगा।</li>
<li>यदि ऋणी द्वारा किस्त भुगतान में विलम्ब किया जाता है, तो विलम्ब शुल्क रूपये ............. प्रतिदिन की दर से वसूल किया जावेगा।</li>
<li>यदि ऋणी द्वारा किस्त भुगतान में विलम्ब किया जाता है तो समिति कार्यालय द्वारा डाक एवं अन्य माध्यम से ऋणी को इसकी सूचना दी जावेगी जिसका समस्त व्यय ऋणी को ही प्रदान करना होगा।</li>
<li>यदि ऋणी द्वारा प्रथम बार विलम्ब से किस्त भुगतान करने के उपरांत अन्य कभी भी पुनः किस्त देने में विलम्ब किया जाता है तो समिति के अधिवक्ता द्वारा लीगल नोटिस जारी किया जायेगा एवं लीगल नोटिस प्राप्ति दिनांक से एक सप्ताह बाद विलम्ब शुल्क ............. रूपये की दर से वसूला जायेगा।</li>
<li>किस्त भुगतान में अधिकतम 2 बार विलम्ब होने के पश्चात ऋण की बची शेष राशि मूलधन $ ब्याज $ विलम्ब शुल्क एक मुश्त ही लिया जावेगा।</li>
<li>यदि किसी ऋणी द्वारा किस्त भुगतान में किसी भी प्रकार की अनियमितता की जाती है। तो समिति इस ऋणी एवं ऋणी के सगे संबंधियों को सदा के लिये ऋण लेने हेतु अपात्र घोषित कर सकती है।</li>
<li>समिति द्वारा प्रत्येक ऋणी को ऋण लेने के पूर्व ऋण भुगतान संबंधी समस्त शर्तें, ब्याज, विलम्ब शुल्क, कानूनी कार्यवाही एवं अपात्र होने संबंधी स्थितियों के बारे में अवगत कराया जायेगा।</li>
<li>प्रत्येक ऋणी द्वारा ऋण लेते समय एवं उसके भुगतान की प्रक्रिया संबंधी समस्त नियम ऋणी द्वारा पढ़लिये गये, समझ लिये गये, एवं इसमें उसकी सहमति है, ऐसा माना जावेगा।</li>
<li>समिति ऐसे समस्त ऋणी जो कि बार-बार किस्त भुगतान संबंधी शर्तों का उल्लंघन कर रहे हैं। उनके विरूद्ध, भारतीय न्याय संहिता की विभिन्न धाराओं के अंतर्गत सक्षम माननीय न्यायालय वैधानिक कार्यवाही करने हेतु स्वतंत्र होगी।</li>
<li>यदि समिति द्वारा किसी ऋणी के विरूद्ध बताई गई शर्तों के उल्लंघन उपरांत माननीय न्यायालय के समक्ष मामला लाया जाता है तो ऐसी स्थिति में समस्त कानूनी व्यय की वसूली ऋणी से की जावेगी।</li>
</ol>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; क्रमश पृष्ठ-2 पर</p>
<p>- 2 -</p>
<ol start="11">
<li>समिति द्वारा बताई गई समस्त ऋण भुगतान की शर्तें दो गवाह के समक्ष निर्धारित .................. राशि के स्टाम्प पेपर स्व-हस्ताक्षरित करते हुये स्वीकार की जावेगी।</li>
<li>समिति द्वारा बताई गई ऋण भुगतान की शर्तों के उल्लंघन होने पर गवाह भी ऋणी के समान दोषी माने जावेंगे। अतः समिति यह आगाह करना चाहती है कि ऋणी के दो गवाह भी सोच समझकर, पढ़कर गवाही में अपने हस्ताक्षर करेंगे।</li>
<li>समिति ऋणी एवं उनके दो गवाहों को यह भी अवगत कराना चाहती है कि किसी भी प्रकार से अपात्र होने की कार्यवाही से बचें। क्योंकि एक बार यदि आप तीनों में से कोई भी अपात्र हो जाता है, तो वह सदैव के लिये अपात्र माना जावेगा।</li>
<li>समिति द्वारा बताई गई पुर्नभुगतान की शर्तों के अलावा यदि परिस्थितिजन्य कोई अन्य शर्तें लगाई जाती हैं, तो समिति उसके लिये स्वतंत्र होगी, यद्धपि समिति द्वारा ऋणी को अवगत कराया जायेगा। परन्तु किसी भी दशा में ऋणी को यह अधिकार नहीं होगा कि वह समिति द्वारा बताई गई किन्हीं शर्तों को मानने से मना कर सके। क्योंकि यह विशेषाधिकार मात्र समिति का होगा।</li>
<li>समिति प्रत्येक ऋणी को इस शर्तों से अवगत कराना चाहती है कि आपके द्वारा लिये गये ऋण राषि ........................ के वापसी की किस्त, प्रति सप्ताह, प्रति 15 दिवस, प्रतिमाह हो सकती है। परन्तु समिति सर्वदा इस निर्णय के लिये स्वतंत्र होगी कि, ऋण वापसी किस्त की प्राप्ति का तरीका उक्त तीन प्रकार में से किस प्रकार का रखा जावे।</li>
<li>मैं, .............................................................. ऋणधारक ने समिति द्वारा दिये गये ऋण एवं उसकी वापसी की प्रक्रिया, ब्याज, विलम्ब शुल्क एवं अन्य शर्तों के संबंध में अच्छे से समझ एवं पढ़ लिया है। अतः मैं, ................................................... उक्त सभी शर्तों से सहमत होने के बाद ही समिति से ऋण प्राप्त कर रहा हूॅं।</li>
</ol>
<p>&nbsp;</p>
<p>&nbsp; ऋणी के हस्ताक्षर समिति द्वारा अनुमोदन</p>
<ol>
<li>नाम - <br/><br/>
पता -  <br/><br/><br/><br/><br/><br/>
गवाह के हस्ताक्षर</li>
<br/><br/>
<li>नाम - <br/><br/>
पता -  <br/><br/><br/><br/><br/><br/>
गवाह के हस्ताक्षर</li>
<br/><br/>
<li>
 नाम -<br/><br/>
 पता - <br/><br/><br/><br/><br/><br/>
  करने वाले प्राधिकारी के हस्ताक्षर</li>
</ol>
{/*<p> <br/>
 पता -&nbsp; </p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; हस्ताक्षर -&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; नाम -</p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; पता -</p>
<ol start="2">
<li>नाम -</li>
</ol>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; पता &nbsp;&nbsp; -</p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; हस्ताक्षर -</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>*/}

</div>
</div>
<hr/>
<br/><br/>
<button type="button" style={{backgroundColor:"#196fb0", marginLeft:"2rem"}} class="btn btn-secondary btn-icon-text" onClick={handlePrint}> Print <i class="mdi mdi-file-document"></i>
</button>
<br/><br/><br/><br/><br/>

  {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>
</>
    );

}

    export default Admin_cust_lp;