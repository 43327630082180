import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';

import '../Resources/dashboard.css';

function Navbar(){

   //Checking Sessions
 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logtype=sessionStorage.getItem("logType00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");
 const logname=sessionStorage.getItem("logName00");


 function logOut(){

   //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("logName00");
   sessionStorage.removeItem("EditId00");
   window.location.replace("/adminpanel-login");

 }

return(
    <>
        {/* partial */}
        {/* partial:partials/_navbar.html */}
        <nav class="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
          <div class="navbar-menu-wrapper d-flex align-items-stretch">
            <button class="navbar-toggler navbar-toggler align-self-center" type="button" data-toggle="minimize">
              <span class="mdi mdi-chevron-double-left"></span>
            </button>
            <div class="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
              <a class="navbar-brand brand-logo-mini" href="index.html"><img src="panel/assets/images/logo-mini.svg" alt="logo" /></a>
            </div>
            <ul class="navbar-nav">
              <li class="nav-item dropdown">
                <a class="nav-link" id="messageDropdown" href="#" data-bs-toggle="dropdown" aria-expanded="false">
                {/*}  <i class="mdi mdi-email-outline"></i> */}  <i class="mdi mdi-account-circle" style={{fontSize:"2rem"}}></i>
                </a>
                <div class="dropdown-menu dropdown-menu-left navbar-dropdown preview-list" aria-labelledby="messageDropdown" style={{border:"0.2rem solid #1c4ecb"}}>
                  <h6 class="p-3 mb-0 font-weight-semibold">Hi, <span style={{color:"#067e8b", fontWeight:"bold"}}>{logname}</span> !</h6>
                  <div class="dropdown-divider"></div>
                {/*}  <a class="dropdown-item preview-item">
                  {/*} <div class="preview-thumbnail">
                      <img src="panel/assets/images/faces/face1.jpg" alt="image" class="profile-pic" />
                    </div>  
                    <div class="preview-item-content d-flex align-items-start flex-column justify-content-center">
                      <h6 class="preview-subject ellipsis mb-1 font-weight-normal"><b>Plan Details</b></h6>
                      <h6 class="preview-subject ellipsis mb-1 font-weight-normal">BASIC INDIVIDUAL PLAN</h6>
                      <h6 class="preview-subject ellipsis mb-1 font-weight-normal">Businesses : <b>1/3</b></h6>
                      <h6 class="preview-subject ellipsis mb-1 font-weight-normal">Counters : <b>2/3</b></h6>
                      <h6 class="preview-subject ellipsis mb-1 font-weight-normal">Team : <b>2/5</b></h6>
                      <p class="text-gray mb-0 text-primary">upgrade <i class="mdi mdi-chevron-double-right"></i></p>
                    </div>
                  </a> */}
                  <div class="dropdown-divider"></div>
                
                  <div class="dropdown-divider"></div>
                  <p style={{padding:"1rem"}}>View and manage your profile and transactions from the dashboard.</p>
                {/*  <a class="dropdown-item preview-item" >
                  
                  {/*}  <div class="preview-thumbnail">
                      <img src="panel/assets/images/faces/face7.jpg" alt="image" class="profile-pic" />
                    </div> */}
                   {/*} <div class="preview-item-content d-flex align-items-start flex-column justify-content-center">
                      <h6 class="preview-subject ellipsis mb-1 font-weight-normal"><b>Transactions</b></h6>
                      <h6 class="preview-subject ellipsis mb-1 font-weight-normal">This Month : <b class="text-success">240</b></h6>
                      <h6 class="preview-subject ellipsis mb-1 font-weight-normal">Today : <b class="text-danger">140</b></h6>
                    </div>
                    <div class="preview-item-content d-flex align-items-start flex-column justify-content-center">
                      <h6 class="preview-subject ellipsis mb-1 font-weight-normal"><b>Customers</b></h6>
                      <h6 class="preview-subject ellipsis mb-1 font-weight-normal">This Month : <b class="text-success">240</b></h6>
                      <h6 class="preview-subject ellipsis mb-1 font-weight-normal">Today : <b class="text-danger">140</b></h6>
                    </div>
                  </a>
                {/*}  <a class="dropdown-item preview-item" >
                  {/*}  <div class="preview-thumbnail">
                      <img src="panel/assets/images/faces/face7.jpg" alt="image" class="profile-pic" />
                    </div> 
                    <div class="preview-item-content d-flex align-items-start flex-column justify-content-center">
                    <h6 class="preview-subject ellipsis mb-1 font-weight-normal"><b>Additional</b></h6>
                      <h6 class="preview-subject ellipsis mb-1 font-weight-normal">Transactions : <b>150</b></h6>
                      <p class="text-gray mb-0">Valid Till : <b>24-02-2024 </b></p>
                    </div>
                  </a> */}
                  <div class="dropdown-divider"></div>
                  <button class="btn btn-lg btn-light" onClick={logOut}>
                  <h6 class="p-3 mb-0 text-center text-primary font-13">LogOut</h6>
                  </button>
                </div>
              </li>
            </ul>
            <ul class="navbar-nav navbar-nav-right">
             {/*} <li class="nav-item nav-logout d-none d-md-block me-3">
                <a class="nav-link" href="#">Status</a>
              </li> */}
            {/*}  <li class="nav-item nav-logout d-none d-md-block">
                <button class="btn btn-sm btn-danger">Trailing</button>
              </li> */}
          {/*}   <li class="nav-item nav-profile dropdown d-none d-md-block">
                <a class="nav-link dropdown-toggle" id="profileDropdown" href="#" data-bs-toggle="dropdown" aria-expanded="false">
                <i class="mdi mdi-account-circle" style={{fontSize:"3rem"}}></i>
                </a>
                <div class="dropdown-menu center navbar-dropdown" aria-labelledby="profileDropdown" style={{marginRight:"2rem"}}>
                  <a class="dropdown-item" href="#">
                    <i class="flag-icon flag-icon-bl me-3"></i> My Profile </a>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item" href="#">
                    <i class="flag-icon flag-icon-cn me-3"></i> Team </a>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item" href="#">
                    <i class="flag-icon flag-icon-de me-3"></i> <b>Plan Details : </b> Basic </a>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item" href="#">
                    <i class="flag-icon flag-icon-ru me-3"></i><b>Valid Till : </b> 12-02-2024</a>
                </div>
              </li> 
              <li></li>
              <li></li> */}
             {/*} <li class="nav-item nav-logout d-none d-lg-block">
                <a class="nav-link" href="index.html">
                  <i class="mdi mdi-home-circle"></i>
                </a>
              </li> */}
            </ul>
            <button class="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="offcanvas">
              <span class="mdi mdi-menu"></span>
            </button> 
          </div>
        </nav>
    </>
);

}
export default Navbar;