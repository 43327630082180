import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';

import './Resources/dashboard.css';
import axios from './../axios.js';
import Navbar from './Supportingfiles/navbar';
import Sidebar from './Supportingfiles/sidebar';
//import Create_loanrequests from './create_newaccounts';



function Customer_customer_profile(){

  //const imgpath = 'http://127.0.0.1:8000/storage/';
  //const imgpath = 'https://pragatiprathmik.com/api/';
  const imgpath = 'https://api.pragatiprathmik.com/storage/';
  

 //Checking Sessions
 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logtype=sessionStorage.getItem("logType00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");
 const logname=sessionStorage.getItem("logName00");
 const editId=sessionStorage.getItem("EditId00");

 
 //SETTING DATA VARIABLES
 const[customer_id,setcustomer_id]=useState();
 const[branch_code,setbranch_code]=useState();
 const[first_name,setfirst_name]=useState();
 const[last_name,setlast_name]=useState();
 const[username,setusername]=useState();
 //const[confirm_username,setconfirm_username]=useState();
 //const[password,setpassword]=useState();
 //const[confirm_password,setconfirm_password]=useState();const updateconfirm_password = (event) => { setconfirm_password(event.target.value);}; const[confirm_password_er,setconfirm_password_er]=useState();
 const[email,setemail]=useState();
 const[contact,setcontact]=useState();
 const[gender,setgender]=useState();
 const[date_of_birth,setdate_of_birth]=useState();
 const[whatsapp,setwhatsapp]=useState();
 const[alternate_contact,setalternate_contact]=useState();
 const[address,setaddress]=useState();
 const[city,setcity]=useState();
 const[state,setstate]=useState();
 const[country,setcountry]=useState();
 const[pincode,setpincode]=useState();
 const[account_number,setaccount_number]=useState();
 const[document_file_name,setdocument_file_name]=useState();
 const[document_file_link,setdocument_file_link]=useState();
 const[employee_remarks,setemployee_remarks]=useState();
 const[admin_remarks,setadmin_remarks]=useState();
 const[customer_status,setcustomer_status]=useState();
 const[type,settype]=useState();
 const[customer_image_link,setcustomer_image_link]=useState();
 const[customer_docs_link,setcustomer_docs_link]=useState();

  //ERROR VARIABLES 
  const[error_msg,seterror_msg]=useState();


 useEffect(()=>{
   
  getData();
  
  },[])
 

//******************* GET CUSTOMER DATA FUNCTION START  ********************//
function getData(){
 

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))){
 
 axios.post("crm-customer-get-profile-details-by-id", {
   Log_id:logid,
   Token:token


   })
 .then((response) => {

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("logName00");
     sessionStorage.removeItem("EditId00");
     window.location.replace("/");
  }

  //Data Not Found
  if((response.data.error)==103){  seterror_msg("Data Not Found !");}

  //Success Data Fetched
  if(response.data.successvar==1){
   //Setting Data
   setcustomer_id(response.data.customer_id);
   //setbranch_id(response.data.branch_id);
   setbranch_code(response.data.branch_code);
   setfirst_name(response.data.first_name);
   setlast_name(response.data.last_name);
   setusername(response.data.username);
   setcontact(response.data.contact);
   setemail(response.data.email);
   settype(response.data.type);
   setwhatsapp(response.data.whatsapp);
   setalternate_contact(response.data.alternate_contact);
   setdate_of_birth(response.data.date_of_birth);
   setgender(response.data.gender);
   setaddress(response.data.address);
   setcity(response.data.city);
   setstate(response.data.state);
   setcountry(response.data.country);
   setpincode(response.data.pincode);
   setaccount_number(response.data.account_number);
   setdocument_file_name(response.data.document_file_name);
   setdocument_file_link(response.data.document_file_link);
   setcustomer_image_link((response.data.customer_image_link).slice(7));
   setcustomer_docs_link((response.data.customer_docs_link).slice(7));
   setemployee_remarks(response.data.employee_remarks);
   setadmin_remarks(response.data.admin_remarks);
   setcustomer_status(response.data.customer_status);

  }

  //FILTER DATA
  //Validation Errors
  if(response.data.validatorerror){ seterror_msg("Validation error!"); }

  //Data Violation
  if(response.data.error==102){  seterror_msg("Violation Error"); }

 
 
 });
 
 }
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("logName00");
   sessionStorage.removeItem("EditId00");
   window.location.replace("/");
 
  }
 
 }
  
 //******************* GET CUSTOMER DATA FUNCTION END  ********************//

 

return(
    <>

<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Pragati Prathmik Sakh Sahkari Samiti Maryadit | Customer Dashboard</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>
    <div className="container-scroller">

   {/*}   <Sidebar /> */}
    <div className="container-fluid page-body-wrapper">


       <Navbar />
        {/*} partial */}
        <div className="main-panel">

        

          <div className="content-wrapper pb-0">

          


          <div className="page-header flex-wrap">
            <div className="header-left"></div>
              <div className="header-right d-flex flex-wrap mt-2 mt-sm-0">
                <div className="d-flex align-items-center">
                  <a href="#">
                    <p className="m-0 pe-3">CUSTOMER PANEL</p>
                  </a>
                  <a className="ps-3 me-4" href="#">
                    <p className="m-0">MY PROFILE</p>
                  </a>
                </div>
          {/*}     <button type="button" className="btn btn-primary mt-2 mt-sm-0 btn-icon-text"  onClick={()=>{ 
               const createForm = document.getElementById('create-form');
               const currentDisplay = createForm.style.display;
               createForm.style.display = currentDisplay === 'none' ? 'block' : 'none'; }}>
                  <i className="mdi mdi-plus-circle"></i>New Loan Request</button>  */}
              </div>
            </div>


                <div class="card">
              
                  <div class="card-body" style={{textAlign:"left"}}>
                    <h3 class="card-title">CUSTOMER INFORMATION</h3>
                    <div class="form-sample" style={{textAlign:"left"}}>
                    
                    <div class="row" style={{textAlign:"left"}}>
                    <div class="col-md-4">
                      <img src={imgpath+customer_image_link} style={{height:"42vh", width:"100%"}} />
                     
                    </div>
                    <div class="col-md-8">
                    <div class="row" style={{textAlign:"left"}}>
                    <div class="row col-sm-6"><h3><b>Customer Name: </b></h3></div>
                    <div class="row col-sm-6"><h3> {first_name} {last_name}</h3></div>
                    </div>
                      
                      <hr/>
                    <div class="row mt-2" style={{textAlign:"left"}}>
                    <div class="row col-sm-6"><h4><b>Username: </b></h4></div>
                    <div class="row col-sm-6"><h4>{username}</h4></div>
                    </div>
                    <div class="row mt-2" style={{textAlign:"left"}}>
                    <div class="row col-sm-6"><h4><b>Contact: </b></h4></div>
                    <div class="row col-sm-6"><h4>{contact}</h4></div>
                    </div>
                    <div class="row mt-2" style={{textAlign:"left"}}>
                    <div class="row col-sm-6"><h4><b>WhatsApp: </b></h4></div>
                    <div class="row col-sm-6"><h4>{whatsapp}</h4></div>
                    </div>
                    <div class="row mt-2" style={{textAlign:"left"}}>
                    <div class="row col-sm-6"><h4><b>Alternate Contact: </b></h4></div>
                    <div class="row col-sm-6"><h4>{alternate_contact}</h4></div>
                    </div>
                    <div class="row mt-2" style={{textAlign:"left"}}>
                    <div class="row col-sm-6"><h4><b>Email: </b></h4></div>
                    <div class="row col-sm-6"><h4>{email}</h4></div>
                    </div>
                    <div class="row mt-2" style={{textAlign:"left"}}>
                    <div class="row col-sm-6"><h4><b>Status</b></h4></div>
                    <div class="row col-sm-6"><div><label className="badge badge-danger" hidden={customer_status=="active"}>inactive</label><label className="badge badge-success" hidden={customer_status=="inactive"}>active</label></div></div>
                    </div>
                    <div class="row mt-2" style={{textAlign:"left"}}>
                    <div class="row col-sm-6"><h4><b>Gender: </b></h4></div>
                    <div class="row col-sm-6"><h4>{gender}</h4></div>
                    </div>
                    <div class="row mt-2" style={{textAlign:"left"}}>
                    <div class="row col-sm-6"><h4><b>Date of Birth: </b></h4></div>
                    <div class="row col-sm-6"><h4>{date_of_birth}</h4></div>
                    </div>
                    <div class="row mt-2" style={{textAlign:"left"}}>
                    <div class="row col-sm-6"><h4><b>Branch:</b></h4></div>
                    <div class="row col-sm-6"><h4>{branch_code}</h4></div>
                    </div>
                    <div class="row mt-2" style={{textAlign:"left"}}>
                    <div class="row col-sm-6"><h4><b>Address: </b></h4></div>
                    <div class="row col-sm-6"><h4>{address}</h4></div>
                    </div>
                    <div class="row mt-2" style={{textAlign:"left"}}>
                    <div class="row col-sm-6"><h4><b>City: </b></h4></div>
                    <div class="row col-sm-6"><h4>{city}</h4></div>
                    </div>
                    <div class="row mt-2" style={{textAlign:"left"}}>
                    <div class="row col-sm-6"><h4><b>State: </b></h4></div>
                    <div class="row col-sm-6"><h4>{state}</h4></div>
                    </div>
                    <div class="row mt-2" style={{textAlign:"left"}}>
                    <div class="row col-sm-6"><h4><b>Country: </b></h4></div>
                    <div class="row col-sm-6"><h4>{country}</h4></div>
                    </div>
                    <div class="row mt-2" style={{textAlign:"left"}}>
                    <div class="row col-sm-6"><h4><b>Pincode: </b></h4></div>
                    <div class="row col-sm-6"><h4>{pincode}</h4></div>
                    </div>
                    <div class="row mt-2" style={{textAlign:"left"}}>
                    <div class="row col-sm-6"><h4><b>Document File: </b></h4></div>
                    <div class="row col-sm-6"><h4>{document_file_name}(<a href="">{document_file_link}</a>)</h4></div>
                    <br/>
                    <a href={imgpath+customer_docs_link}><button class="btn btn-dark" >View Document Attachment</button></a>
                    </div>

                    </div>
                    </div>
               {/*}   <hr/>
                  <h3><b>Admin Remarks</b></h3>
                  <p>{admin_remarks}</p>
                    
                  <hr style={{marginTop:"3rem"}} />
                  <h3><b>Employee Remarks</b></h3>
                  <p>{employee_remarks}</p> */}

    
                     
                   
                    </div>
                  </div>
                </div>

                </div>
          {/*} content-wrapper ends */}
          {/*} partial:partials/_footer.html */}
       {/*}   <footer className="footer">
            <div className="d-sm-flex justify-content-center justify-content-sm-between">
              <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2021 <a href="https://www.bootstrapdash.com/" target="_blank">BootstrapDash</a>. All rights reserved.</span>
              <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i className="mdi mdi-heart text-danger"></i></span>
            </div>
          </footer> */}
          {/*} partial */}
        </div>
        {/*} main-panel ends */}
      </div>
      {/*} page-body-wrapper ends */}
    </div>
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>
    </>

);
}
//}

export default Customer_customer_profile;