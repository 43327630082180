import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';

import './Resources/dashboard.css';
import axios from './../axios.js';
import Navbar from './Supportingfiles/navbar';
import Sidebar from './Supportingfiles/sidebar';
//import Create_loanrequests from './create_newaccounts';
import moment from 'moment/moment';


function Admin_add_employee(){

  //const imgpath = 'http://127.0.0.1:8000/storage/';
  const imgpath = 'https://pragatiprathmik.com/api/';
  
  //Checking Sessions
 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");
 const logname=sessionStorage.getItem("logName00");
  

 //SETTING DATA VARIABLES
 const[employee_code,setemployee_code]=useState();const updateemployee_code = (event) => { setemployee_code(event.target.value);}; const[employee_code_er,setemployee_code_er]=useState();
 const[branch_code,setbranch_code]=useState();const updatebranch_code = (event) => { setbranch_code(event.target.value);}; const[branch_code_er,setbranch_code_er]=useState();
 const[first_name,setfirst_name]=useState();const updatefirst_name = (event) => { setfirst_name(event.target.value);}; const[first_name_er,setfirst_name_er]=useState();
 const[last_name,setlast_name]=useState();const updatelast_name = (event) => { setlast_name(event.target.value);}; const[last_name_er,setlast_name_er]=useState();
 const[username,setusername]=useState();const updateusername = (event) => { setusername(event.target.value);}; const[username_er,setusername_er]=useState();
 const[confirm_username,setconfirm_username]=useState();const updateconfirm_username = (event) => { setconfirm_username(event.target.value);}; const[confirm_username_er,setconfirm_username_er]=useState();
 const[password,setpassword]=useState();const updatepassword = (event) => { setpassword(event.target.value);}; const[password_er,setpassword_er]=useState();
 const[confirm_password,setconfirm_password]=useState();const updateconfirm_password = (event) => { setconfirm_password(event.target.value);}; const[confirm_password_er,setconfirm_password_er]=useState();
 const[email,setemail]=useState();const updateemail = (event) => { setemail(event.target.value);}; const[email_er,setemail_er]=useState();
 const[contact,setcontact]=useState();const updatecontact = (event) => { setcontact(event.target.value);}; const[contact_er,setcontact_er]=useState();
 const[gender,setgender]=useState();const updategender = (event) => { setgender(event.target.value);}; const[gender_er,setgender_er]=useState();
 const[date_of_birth,setdate_of_birth]=useState();const updatedate_of_birth = (event) => { setdate_of_birth(event.target.value);}; const[date_of_birth_er,setdate_of_birth_er]=useState();
 const[whatsapp,setwhatsapp]=useState();const updatewhatsapp = (event) => { setwhatsapp(event.target.value);}; const[whatsapp_er,setwhatsapp_er]=useState();
 const[alternate_contact,setalternate_contact]=useState();const updatealternate_contact = (event) => { setalternate_contact(event.target.value);}; const[alternate_contact_er,setalternate_contact_er]=useState();
 const[address,setaddress]=useState();const updateaddress = (event) => { setaddress(event.target.value);}; const[address_er,setaddress_er]=useState();
 const[city,setcity]=useState();const updatecity = (event) => { setcity(event.target.value);}; const[city_er,setcity_er]=useState();
 const[state,setstate]=useState();const updatestate = (event) => { setstate(event.target.value);}; const[state_er,setstate_er]=useState();
 const[country,setcountry]=useState();const updatecountry = (event) => { setcountry(event.target.value);}; const[country_er,setcountry_er]=useState();
 const[pincode,setpincode]=useState();const updatepincode = (event) => { setpincode(event.target.value);}; const[pincode_er,setpincode_er]=useState();
 const[position,setposition]=useState();const updateposition = (event) => { setposition(event.target.value);}; const[position_er,setposition_er]=useState();
 const[date_of_joining,setdate_of_joining]=useState();const updatedate_of_joining = (event) => { setdate_of_joining(event.target.value);}; const[date_of_joining_er,setdate_of_joining_er]=useState();
 const[basic_salary,setbasic_salary]=useState();const updatebasic_salary = (event) => { setbasic_salary(event.target.value);}; const[basic_salary_er,setbasic_salary_er]=useState();
 const[incentives_payable,setincentives_payable]=useState();const updateincentives_payable = (event) => { setincentives_payable(event.target.value);}; const[incentives_payable_er,setincentives_payable_er]=useState();
 const[incentive_remarks,setincentive_remarks]=useState();const updateincentive_remarks = (event) => { setincentive_remarks(event.target.value);}; const[incentive_remarks_er,setincentive_remarks_er]=useState();
 const[travel_allowance,settravel_allowance]=useState();const updatetravel_allowance = (event) => { settravel_allowance(event.target.value);}; const[travel_allowance_er,settravel_allowance_er]=useState();
 const[dearance_allowance,setdearance_allowance]=useState();const updatedearance_allowance = (event) => { setdearance_allowance(event.target.value);}; const[dearance_allowance_er,setdearance_allowance_er]=useState();
 const[provident_fund,setprovident_fund]=useState();const updateprovident_fund = (event) => { setprovident_fund(event.target.value);}; const[provident_fund_er,setprovident_fund_er]=useState();
 const[salary_deductions,setsalary_deductions]=useState();const updatesalary_deductions = (event) => { setsalary_deductions(event.target.value);}; const[salary_deductions_er,setsalary_deductions_er]=useState();
 const[payable_salary,setpayable_salary]=useState();const updatepayable_salary = (event) => { setpayable_salary(event.target.value);}; const[payable_salary_er,setpayable_salary_er]=useState();
 const[mode,setmode]=useState();const updatemode = (event) => { setmode(event.target.value);}; const[mode_er,setmode_er]=useState();
 const[bank_name,setbank_name]=useState();const updatebank_name = (event) => { setbank_name(event.target.value);}; const[bank_name_er,setbank_name_er]=useState();
 const[ifsc_code,setifsc_code]=useState();const updateifsc_code = (event) => { setifsc_code(event.target.value);}; const[ifsc_code_er,setifsc_code_er]=useState();
 const[account_number,setaccount_number]=useState();const updateaccount_number = (event) => { setaccount_number(event.target.value);}; const[account_number_er,setaccount_number_er]=useState();
 const[confirm_account_number,setconfirm_account_number]=useState();const updateconfirm_account_number = (event) => { setconfirm_account_number(event.target.value);}; const[confirm_account_number_er,setconfirm_account_number_er]=useState();
 const[document_file_name,setdocument_file_name]=useState();const updatedocument_file_name = (event) => { setdocument_file_name(event.target.value);}; const[document_file_name_er,setdocument_file_name_er]=useState();
 const[document_file_link,setdocument_file_link]=useState();const updatedocument_file_link = (event) => { setdocument_file_link(event.target.value);}; const[document_file_link_er,setdocument_file_link_er]=useState();
 const[employee_remarks,setemployee_remarks]=useState();const updateemployee_remarks = (event) => { setemployee_remarks(event.target.value);}; const[employee_remarks_er,setemployee_remarks_er]=useState();
 const[type,settype]=useState();const updatetype = (event) => { settype(event.target.value);}; const[type_er,settype_er]=useState();

 
 //ERROR VARIABLES 
 const[error_msg,seterror_msg]=useState();

//image upload
//File Upload
const [selectedFile, setSelectedFile] = React.useState(null);
const handleFileChange = (event) => {  const file = event.target.files[0];  setSelectedFile(file); };
const[employee_image_link_er,setemployee_image_link_er]=useState();

//image upload
//File Upload
const [selectedFile1, setSelectedFile1] = React.useState(null);
const handleFileChange1 = (event) => {  const file1 = event.target.files[0];  setSelectedFile1(file1); };
const[employee_docs_link_er,setemployee_docs_link_er]=useState();


/***************** ADD EMPLOYEE FUNCTION START  ******************/
function add_employee(){

  
  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((logname!=null)||(logname!=" "))){

    //Setting Error Variables
    setemployee_code_er(" ");setbranch_code_er(" ");setfirst_name_er(" ");setlast_name_er(" ");setusername_er(" ");setconfirm_username_er(" ");setpassword_er(" ");setconfirm_password_er(" ");
    setemail_er(" ");setcontact_er(" ");setdate_of_birth_er(" ");setgender_er(" ");setwhatsapp_er(" ");setalternate_contact_er(" ");setaddress_er(" ");setcity_er(" ");setstate_er(" ");setcountry_er(" ");setpincode_er(" ");
    setdate_of_joining_er(" ");setbasic_salary_er(" ");setincentives_payable_er(" ");setincentive_remarks_er(" ");settravel_allowance_er(" ");setdearance_allowance_er(" ");setprovident_fund_er(" ");setsalary_deductions_er(" ");setpayable_salary_er(" ");
    setmode_er(" ");setbank_name_er(" ");setifsc_code_er(" ");setaccount_number_er(" ");setconfirm_account_number_er(" ");setdocument_file_name_er(" ");setdocument_file_link_er(" ");
    setposition_er(" ");setemployee_remarks_er(" ");seterror_msg(" ");settype_er(" ");
   
    
//Fetching Form Data
let noerror = 0;
const $employee_code = document.getElementById("employee_code").value; if($employee_code==""){ setemployee_code_er("Employee Code is required !");noerror = noerror+1; }//employee_code
const $branch_code = document.getElementById("branch_code").value; if($branch_code==""){ setbranch_code_er("Branch Code is required !");noerror = noerror+1; }//branch_code
const $first_name = document.getElementById("first_name").value; if($first_name==""){ setfirst_name_er("First Name is required !");noerror = noerror+1; }//first_name
const $last_name = document.getElementById("last_name").value; if($last_name==""){ setlast_name_er("Last Name is required !");noerror = noerror+1; }//last_name
const $username = document.getElementById("username").value; if($username==""){ setusername_er("Username is required !");noerror = noerror+1; }//username
const $confirm_username = document.getElementById("confirm_username").value; if($confirm_username==""){ setconfirm_username_er("Confirm Username is required !");noerror = noerror+1; }//confirm_username
const $password = document.getElementById("password").value; if($password==""){ setpassword_er("Password is required !");noerror = noerror+1; }//password
const $confirm_password = document.getElementById("confirm_password").value; if($confirm_password==""){ setconfirm_password_er("Confirm Password is required !");noerror = noerror+1; }//confirm_password
const $email = document.getElementById("email").value; if($email==""){ setemail_er("Email is required !");noerror = noerror+1; }//email
const $contact = document.getElementById("contact").value; if($contact==""){ setcontact_er("Contact is required !");noerror = noerror+1; }//contact
const $gender = document.getElementById("gender").value; if($gender==""){ setgender_er("Gender is required !");noerror = noerror+1; }//gender
const $date_of_birth = document.getElementById("date_of_birth").value; if($date_of_birth==""){ setdate_of_birth_er("Date of Birth is required !");noerror = noerror+1; }//date_of_birth
const $whatsapp = document.getElementById("whatsapp").value; if($whatsapp==""){ setwhatsapp_er("Whatsapp is required !");noerror = noerror+1; }//whatsapp
const $alternate_contact = document.getElementById("alternate_contact").value; if($alternate_contact==""){ setalternate_contact_er("Alternate Contact is required !");noerror = noerror+1; }//alternate_contact
const $address = document.getElementById("address").value; if($address==""){ setaddress_er("Address is required !");noerror = noerror+1; }//address
const $city = document.getElementById("city").value; if($city==""){ setcity_er("City is required !");noerror = noerror+1; }//city
const $state = document.getElementById("state").value; if($state==""){ setstate_er("State is required !");noerror = noerror+1; }//state
const $pincode = document.getElementById("pincode").value; if($pincode==""){ setpincode_er("Pincode is required !");noerror = noerror+1; }//pincode
const $date_of_joining = document.getElementById("date_of_joining").value; if($date_of_joining==""){ setdate_of_joining_er("Date of Joining is required !");noerror = noerror+1; }//date_of_joining
const $basic_salary = document.getElementById("basic_salary").value; if($basic_salary==""){ setbasic_salary_er("Basic Salary is required !");noerror = noerror+1; }//basic_salary
const $incentives_payable = document.getElementById("incentives_payable").value; if($incentives_payable==""){ setincentives_payable_er("Incentives Payable is required !");noerror = noerror+1; }//incentives_payable
const $incentive_remarks = document.getElementById("incentive_remarks").value; if($incentive_remarks==""){ setincentive_remarks_er("Incentive Remarks is required !");noerror = noerror+1; }//incentive_remarks
const $travel_allowance = document.getElementById("travel_allowance").value; if($travel_allowance==""){ settravel_allowance_er("Travel Allowance is required !");noerror = noerror+1; }//travel_allowance
const $dearance_allowance = document.getElementById("dearance_allowance").value; if($dearance_allowance==""){ setdearance_allowance_er("Dearance Allowance is required !");noerror = noerror+1; }//dearance_allowance
const $provident_fund = document.getElementById("provident_fund").value; if($provident_fund==""){ setprovident_fund_er("Provient Fund is required !");noerror = noerror+1; }//provident_fund
const $salary_deductions = document.getElementById("salary_deductions").value; if($salary_deductions==""){ setsalary_deductions_er("Salary Deductions is required !");noerror = noerror+1; }//salary_deductions
const $payable_salary = document.getElementById("payable_salary").value; if($payable_salary==""){ setpayable_salary_er("Monthly Payable Salary is required !");noerror = noerror+1; }//payable_salary
const $mode = document.getElementById("mode").value; if($mode==""){ setmode_er("Mode is required !");noerror = noerror+1; }//mode
const $bank_name = document.getElementById("bank_name").value; if($bank_name==""){ setbank_name_er("Bank Name is required !");noerror = noerror+1; }//bank_name
const $ifsc_code = document.getElementById("ifsc_code").value; if($ifsc_code==""){ setifsc_code_er("IFSC Code is required !");noerror = noerror+1; }//ifsc_code
const $account_number = document.getElementById("account_number").value; if($account_number==""){ setaccount_number_er("Account Number is required !");noerror = noerror+1; }//account_number
const $confirm_account_number = document.getElementById("confirm_account_number").value; if($confirm_account_number==""){ setconfirm_account_number_er("Confirm Account Number is required !");noerror = noerror+1; }//confirm_account_number
const $document_file_name = document.getElementById("document_file_name").value; if($document_file_name==""){ setdocument_file_name_er("Document File Name is required !");noerror = noerror+1; }//document_file_name
const $document_file_link = document.getElementById("document_file_link").value; if($document_file_link==""){ setdocument_file_link_er("Document File Link is required !");noerror = noerror+1; }//document_file_link
const $employee_remarks = document.getElementById("employee_remarks").value; if($employee_remarks==""){ setemployee_remarks_er("Employee Remarks is required !");noerror = noerror+1; }//employee_remarks
const $position = document.getElementById("position").value; if($position==""){ setposition_er("Position is required !");noerror = noerror+1; }//position
const $type = document.getElementById("type").value; if($type==""){ settype_er("type is required !");noerror = noerror+1; }//type



//No primary errors
if(noerror==0){

    //disabling button
    document.getElementById('emp-submit-btn').disabled = true; 



  //Sending Data through axios
  axios.post("crm-add-new-employee", { 
  Log_id:logid,
  Token:token,
  Employee_code:$employee_code,
  Branch_code:$branch_code,
  First_name:$first_name,
  Last_name:$last_name,
  Username:$username,
  Confirm_username:$confirm_username,
  Password:$password,
  Confirm_password:$confirm_password,
  Email:$email,
  Contact:$contact,
  Gender:$gender,
  Date_of_birth:moment($date_of_birth).format('YYYY-MM-DD'),
  Whatsapp:$whatsapp,
  Alternate_contact:$alternate_contact,
  Address:$address,
  City:$city,
  State:$state,
  Country:"India",
  Pincode:$pincode,
  Date_of_joining:moment($date_of_joining).format('YYYY-MM-DD'),
  Basic_salary:$basic_salary,
  Incentives_payable_amt:$incentives_payable,
  Incentive_remarks:$incentive_remarks,
  Travel_allowance:$travel_allowance,
  Dearance_allowance:$dearance_allowance,
  Provident_fund:$provident_fund,
  Salary_deductions:$salary_deductions,
  Monthly_payable_salary:$payable_salary,
  Mode:$mode,
  Bank_name:$bank_name,
  Ifsc_code:$ifsc_code,
  Account_number:$account_number,
  Confirm_account_number:$confirm_account_number,
  Document_file_name:$document_file_name,
  Document_file_link:$document_file_link,
  Position:$position,
  Employee_remarks:$employee_remarks,
  Employee_image_link:selectedFile,
  Employee_docs_link:selectedFile1,
  Type:$type

  },{
    headers: { "Content-Type": "multipart/form-data" },
    }
    )
  
  .then((response) => {

        //enabling button
        document.getElementById('emp-submit-btn').disabled = false; 


  //Validation Errors
  if(response.data.validatorerror){
  setemployee_code_er(response.data.validatorerror.Employee_code);
  setbranch_code_er(response.data.validatorerror.Branch_code);
  setfirst_name_er(response.data.validatorerror.First_name);
  setlast_name_er(response.data.validatorerror.Last_name);
  setusername_er(response.data.validatorerror.Username);
  setconfirm_username_er(response.data.validatorerror.Confirm_username);
  setpassword_er(response.data.validatorerror.Password);
  setconfirm_password_er(response.data.validatorerror.Confirm_password);
  setcontact_er(response.data.validatorerror.Contact);
  setemail_er(response.data.validatorerror.Email);
  setgender_er(response.data.validatorerror.Gender);
  setdate_of_birth_er(response.data.validatorerror.Date_of_birth);
  setalternate_contact_er(response.data.validatorerror.Alternate_contact);
  setwhatsapp_er(response.data.validatorerror.Whatsapp);
  setaddress_er(response.data.validatorerror.Address);
  setcity_er(response.data.validatorerror.City);
  setstate_er(response.data.validatorerror.State);
  setcountry_er(response.data.validatorerror.Country);
  setpincode_er(response.data.validatorerror.Pincode);
  setdate_of_joining_er(response.data.validatorerror.Date_of_joining);
  setbasic_salary_er(response.data.validatorerror.Basic_salary);
  setincentives_payable_er(response.data.validatorerror.Incentives_payable_amt);
  setincentive_remarks_er(response.data.validatorerror.Incentive_remarks);
  settravel_allowance_er(response.data.validatorerror.Travel_allowance);
  setdearance_allowance_er(response.data.validatorerror.Dearance_allowance);
  setprovident_fund_er(response.data.validatorerror.Provident_fund);
  setsalary_deductions_er(response.data.validatorerror.Salary_deductions);
  setpayable_salary_er(response.data.validatorerror.Payable_salary);
  setmode_er(response.data.validatorerror.Mode);
  setbank_name_er(response.data.validatorerror.Bank_name);
  setifsc_code_er(response.data.validatorerror.Ifsc_code);
  setaccount_number_er(response.data.validatorerror.Account_number);
  setconfirm_account_number_er(response.data.validatorerror.Confirm_account_number);
  setdocument_file_name_er(response.data.validatorerror.Document_file_name);
  setdocument_file_link_er(response.data.validatorerror.Document_file_link);
  setemployee_image_link_er(response.data.validatorerror.Employee_image_link);
  setemployee_docs_link_er(response.data.validatorerror.Employee_docs_link);
  setemployee_remarks_er(response.data.validatorerror.Employee_remarks);
  settype_er(response.data.validatorerror.Type);
  seterror_msg("Cannot Add Employee !");
  
  
  }
  //Invalid Entries
  if(response.data.error==102){ 

  setemployee_code_er(response.data.employee_code_er);
  setbranch_code_er(response.data.branch_code_er);
  setfirst_name_er(response.data.first_name_er);
  setlast_name_er(response.data.last_name_er);
  setusername_er(response.data.username_er);
  setconfirm_username_er(response.data.confirm_username_er);
  setpassword_er(response.data.password_er);
  setconfirm_password_er(response.data.confirm_password_er);
  setcontact_er(response.data.contact_er);
  setemail_er(response.data.email_er);
  setgender_er(response.data.gender_er);
  setdate_of_birth_er(response.data.date_of_birth_er);
  setalternate_contact_er(response.data.alternate_contact_er);
  setwhatsapp_er(response.data.whatsapp_er);
  setaddress_er(response.data.address_er);
  setcity_er(response.data.city_er);
  setstate_er(response.data.state_er);
  setcountry_er(response.data.country_er);
  setpincode_er(response.data.pincode_er);
  setdate_of_joining_er(response.data.date_of_joining_er);
  setbasic_salary_er(response.data.basic_salary_er);
  setincentives_payable_er(response.data.incentives_payable_amt_er);
  setincentive_remarks_er(response.data.incentive_remarks_er);
  settravel_allowance_er(response.data.travel_allowance_er);
  setdearance_allowance_er(response.data.dearance_allowance_er);
  setprovident_fund_er(response.data.provident_fund_er);
  setsalary_deductions_er(response.data.salary_deductions_er);
  setpayable_salary_er(response.data.payable_salary_er);
  setmode_er(response.data.mode_er);
  setbank_name_er(response.data.bank_name_er);
  setifsc_code_er(response.data.ifsc_code_er);
  setaccount_number_er(response.data.account_number_er);
  setconfirm_account_number_er(response.data.confirm_account_number_er);
  setdocument_file_name_er(response.data.document_file_name_er);
  setdocument_file_link_er(response.data.document_file_link_er);
  setemployee_image_link_er(response.data.Employee_image_link_er);
  setemployee_docs_link_er(response.data.employee_docs_link_er);
  setemployee_remarks_er(response.data.employee_remarks_er);
  settype_er(response.data.type_er);
  seterror_msg("Cannot Add Employee !");
  
  
  }
  
  //Branch Name or Branch Code Already Exists
  if(response.data.error==103){  seterror_msg(response.data.error_msg); return; }
  
  //Success
  if(response.data.successvar==1){ document.getElementById("success-card").style.display="block"; }

  //failure
  if(response.data.successvar==6){ seterror_msg("Branch Not Created !"); }

  //Session Expired
  if(response.data.successvar==100){ seterror_msg("Session Expired !");  }


  }    );
  
  }
  

  }
  //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
 sessionStorage.removeItem("logStatus00");
 sessionStorage.removeItem("logId00");
 sessionStorage.removeItem("logToken00");
 sessionStorage.removeItem("logName00");
 
 window.location.replace("/");
 
 
 }    

}

/***************** ADD EMPLOYEE FUNCTION END  ******************/




return(
    <>

<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Pragati Prathmik Sakh Sahkari Samiti Maryadit | Admin Dashboard</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>
    <div className="container-scroller">

   {/*}   <Sidebar /> */}
    <div className="container-fluid page-body-wrapper">


       <Navbar />
        {/*} partial */}
        <div className="main-panel">

        

          <div className="content-wrapper pb-0">

          

                 {/*  SUCCESS CARD START */}
                 <div class="d-flex justify-content-center">
               <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="success-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-success">Employee Added Successfully !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to continue.</p>
                      <a href="/adminpanel-dashboard-employees"><button class="mt-3 btn btn-outline-success">Continue</button></a>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/*  SUCCESS CARD END */}


          <div className="page-header flex-wrap">
            <div className="header-left"></div>
              <div className="header-right d-flex flex-wrap mt-2 mt-sm-0">
                <div className="d-flex align-items-center">
                  <a href="#">
                    <p className="m-0 pe-3">ADMIN PANEL</p>
                  </a>
                  <a className="ps-3 me-4" href="#">
                    <p className="m-0">ADD NEW EMPLOYEE</p>
                  </a>
                </div>
          {/*}     <button type="button" className="btn btn-primary mt-2 mt-sm-0 btn-icon-text"  onClick={()=>{ 
               const createForm = document.getElementById('create-form');
               const currentDisplay = createForm.style.display;
               createForm.style.display = currentDisplay === 'none' ? 'block' : 'none'; }}>
                  <i className="mdi mdi-plus-circle"></i>New Loan Request</button>  */}
              </div>
            </div>


                <div class="card">
              
                  <div class="card-body" style={{textAlign:"left"}}>
                    <h4 class="card-title">Add New Employee</h4>
                    <div class="form-sample">
                      <p class="card-description">add new employee details here</p>
                      <code>{error_msg}</code>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Employee_code</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="EMP0001" id="employee_code" value={employee_code} onChange={updateemployee_code} />
                              <code>{employee_code_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Type</label>
                            <div class="col-sm-9">
                            <select class="form-control" style={{padding:"1rem"}} placeholder="Branch Level" id="type" value={type} onChange={updatetype}  >
                            <option value="Branch Head">Branch Head</option>
                            <option value="Sales">Sales</option>
                            <option value="Operations">Operations</option>
                            <option value="HR">HR</option>
                            <option value="Other">Other</option>
                            </select>
                            <code>{type_er}</code>
                            </div>
                          </div>
                        </div>   
                        </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">First Name</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="First Name" id="first_name" value={first_name} onChange={updatefirst_name} />
                              <code>{first_name_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Last Name</label>
                            <div class="col-sm-9">
                            <input type="text" class="form-control" placeholder="Last Name" id="last_name" value={last_name} onChange={updatelast_name}  />
                            <code>{last_name_er}</code>
                            </div>
                          </div>
                        </div>   
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Username</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="My Username"  id="username" value={username} onChange={updateusername} />
                              <code>{username_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Confirm Username</label>
                            <div class="col-sm-9">
                            <input type="text" class="form-control" placeholder="My Username"  id="confirm_username" value={confirm_username} onChange={updateconfirm_username} />
                            <code>{confirm_username_er}</code>
                            </div>
                          </div>
                        </div>   
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Password</label>
                            <div class="col-sm-9">
                              <input type="password" class="form-control" placeholder="My Password"  id="password" value={password} onChange={updatepassword} />
                              <code>{password_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Confirm Password</label>
                            <div class="col-sm-9">
                            <input type="password" class="form-control" placeholder="My Password"  id="confirm_password" value={confirm_password} onChange={updateconfirm_password} />
                            <code>{confirm_password_er}</code>
                            </div>
                          </div>
                        </div>   
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Email</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="myemail@gmail.com"  id="email" value={email} onChange={updateemail} />
                              <code>{email_er}</code> 
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Contact</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="91 9000000000"  id="contact" value={contact} onChange={updatecontact} />
                              <code>{contact_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Gender</label>
                            <div class="col-sm-9">
                              <select class="form-control" style={{padding:"1rem"}}  id="gender" value={gender} onChange={updategender}>
                              <option value="male">male</option>
                              <option value="female">female</option>
                              <option value="undisclosed">undisclosed</option>
                              </select>
                              <code>{gender_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Date of Birth</label>
                            <div class="col-sm-9">
                              <input type="date" class="form-control"  id="date_of_birth" value={date_of_birth} onChange={updatedate_of_birth} />
                              <code>{date_of_birth_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">WhatsApp</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="91 9000000000"  id="whatsapp" value={whatsapp} onChange={updatewhatsapp} />
                              <code>{whatsapp_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Alternate Contact</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="91 9000000000" id="alternate_contact" value={alternate_contact} onChange={updatealternate_contact} />
                              <code>{alternate_contact_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>
                      <div class="form-group">
                        <label for="exampleTextarea1">Address</label>
                        <textarea  class="form-control" rows="2" placeholder="My address" id="address" value={address} onChange={updateaddress} />
                        <code>{address_er}</code>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">City</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Satna" id="city" value={city} onChange={updatecity} />
                              <code>{city_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">State</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Madhya Pradesh" id="state" value={state} onChange={updatestate} />
                              <code>{state_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Country</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Satna" id="country" value={country} onChange={updatecountry} />
                              <code>{country_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Pincode</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Madhya Pradesh" id="pincode" value={pincode} onChange={updatepincode} />
                              <code>{pincode_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>
                      <hr/>
                      <h4>Salary Details</h4>
                      <div class="row mt-3">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Position</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Branch Head" id="position" value={position} onChange={updateposition} />
                              <code>{position_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Branch Code</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="BNC0001" id="branch_code" value={branch_code} onChange={updatebranch_code} />
                              <code>{branch_code_er}</code>
                             {/*} <option value="Satna (513)">Satna (513)</option>
                              <option value="Rewa (514)">Rewa (514)</option>
                              </select>*/}
                            </div>
                          </div>
                        </div>                     
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Date of Joining</label>
                            <div class="col-sm-9">
                              <input type="date" class="form-control" id="date_of_joining" value={date_of_joining} onChange={updatedate_of_joining} />
                              <code>{date_of_joining_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Basic Salary</label>
                            <div class="col-sm-9">
                            <input type="text" class="form-control" placeholder="10000.00" id="basic_salary" value={basic_salary} onChange={updatebasic_salary} />
                            <code>{basic_salary_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Incentives Payable</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="10000.00" id="incentives_payable" value={incentives_payable} onChange={updateincentives_payable} />
                              <code>{incentives_payable_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Incentive Remarks</label>
                            <div class="col-sm-9">
                            <input type="text" class="form-control" placeholder="10% on sales" id="incentive_remarks" value={incentive_remarks} onChange={updateincentive_remarks} />
                            <code>{incentive_remarks_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Travel Allowance (T.A.)</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="500.00" id="travel_allowance" value={travel_allowance} onChange={updatetravel_allowance} />
                              <code>{travel_allowance_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Dearance Allowance (D.A.)</label>
                            <div class="col-sm-9">
                            <input type="text" class="form-control" placeholder="500.00" id="dearance_allowance" value={dearance_allowance} onChange={updatedearance_allowance} />
                            <code>{dearance_allowance_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">P.F. and Other Salary Add ons</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="1000.00" id="provident_fund" value={provident_fund} onChange={updateprovident_fund} />
                              <code>{provident_fund_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Salary Deductions</label>
                            <div class="col-sm-9">
                            <input type="text" class="form-control" placeholder="1000.00" id="salary_deductions" value={salary_deductions} onChange={updatesalary_deductions} />
                            <code>{salary_deductions_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Monthly Salary Payable</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="21000.00" id="payable_salary" value={payable_salary} onChange={updatepayable_salary} />
                              <code>{payable_salary_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Mode</label>
                            <div class="col-sm-9">
                            <input type="text" class="form-control" placeholder="cash" id="mode" value={mode} onChange={updatemode} />
                            <code>{mode_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>
                      <hr/>
                      <h4>Banking Details</h4>
                      <div class="row mt-3">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Bank Name</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="SBI Bank Cherital" id="bank_name" value={bank_name} onChange={updatebank_name} />
                              <code>{bank_name_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">IFSC code</label>
                            <div class="col-sm-9">
                            <input type="text" class="form-control" placeholder="SBI9900000" id="ifsc_code" value={ifsc_code} onChange={updateifsc_code} />
                            <code>{ifsc_code_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>
                      <div class="row mt-3">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Account Number</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="1200000000000" id="account_number" value={account_number} onChange={updateaccount_number} />
                              <code>{account_number_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Confirm Account Number</label>
                            <div class="col-sm-9">
                            <input type="password" class="form-control" placeholder="*******************" id="confirm_account_number" value={confirm_account_number} onChange={updateconfirm_account_number} />
                            <code>{confirm_account_number_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>

                      <hr/>
                      <h4>Other Documents</h4>
                      <div class="row mt-3">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Documents File Name</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Employee 1234" id="document_file_name" value={document_file_name} onChange={updatedocument_file_name} />
                              <code>{document_file_name_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Documents Link</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="https://mydrivelink.com/mydrive" id="document_file_link" value={document_file_link} onChange={updatedocument_file_link} />
                            <code>{document_file_link_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>
                      <div class="col-md-12">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Upload Employee Image (Passport Size)</label>
                            <div class="col-sm-3">
                              <input type="file" class="form-control"  id="logo_link"  onChange={handleFileChange} multiple />
                              {selectedFile && <p>Selected file: {selectedFile.name}</p>}
                              <code>{employee_image_link_er}</code>
                           
                            </div>
                          <div class="col-sm-5">
                          <p>Allowed File format (.png, .jpg), Max. file Size Allowed 1mb. </p>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Upload Employee Documents</label>
                            <div class="col-sm-3">
                              <input type="file" class="form-control"  id="logo_link1"  onChange={handleFileChange1} multiple />
                              {selectedFile1 && <p>Selected file: {selectedFile1.name}</p>}
                              <code>{employee_docs_link_er}</code>
                           
                            </div>
                          <div class="col-sm-5">
                          <p>Allowed File format (.pdf), Max. file Size Allowed 1mb. </p>
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="exampleTextarea1">Remarks</label>
                        <textarea  class="form-control" rows="2" placeholder="Add your Remarks here" id="employee_remarks" value={employee_remarks} onChange={updateemployee_remarks} />
                        <code>{employee_remarks_er}</code>
                      </div>

                
                      
                        <button class="btn btn-lg btn-success" style={{fontWeight:"bold"}} id="emp-submit-btn" onClick={add_employee}>Submit</button>
                      
                       
    
                     
                   
                    </div>
                  </div>
                </div>

                </div>
          {/*} content-wrapper ends */}
          {/*} partial:partials/_footer.html */}
       {/*}   <footer className="footer">
            <div className="d-sm-flex justify-content-center justify-content-sm-between">
              <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2021 <a href="https://www.bootstrapdash.com/" target="_blank">BootstrapDash</a>. All rights reserved.</span>
              <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i className="mdi mdi-heart text-danger"></i></span>
            </div>
          </footer> */}
          {/*} partial */}
        </div>
        {/*} main-panel ends */}
      </div>
      {/*} page-body-wrapper ends */}
    </div>
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>
    </>

);
}
//}

export default Admin_add_employee;