import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';

import './Resources/dashboard.css';
import axios from './../axios.js';
import Navbar from './Supportingfiles/navbar';
import Sidebar from './Supportingfiles/sidebar';
//import Create_loanrequests from './create_newaccounts';
import moment from 'moment/moment';


function Admin_create_salary(){

  
  
  //Checking Sessions
 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");
 const logname=sessionStorage.getItem("logName00");
 const empId=sessionStorage.getItem("EmpId00");


  //SETTING DATA VARIABLES
 //date
 const[date,setdate]=useState();const updatedate = (event) => { setdate(event.target.value);}; const[date_er,setdate_er]=useState();
 const[transaction,settransaction]=useState();const updatetransaction = (event) => { settransaction(event.target.value);}; const[transaction_er,settransaction_er]=useState();
 const[previous_salary_balance,setprevious_salary_balance]=useState();const updateprevious_salary_balance = (event) => { setprevious_salary_balance(event.target.value);}; const[previous_salary_balance_er,setprevious_salary_balance_er]=useState();
 const[working_days,setworking_days]=useState();const updateworking_days = (event) => { setworking_days(event.target.value); calculate(); }; const[working_days_er,setworking_days_er]=useState();
 const[basic_pay,setbasic_pay]=useState();const updatebasic_pay = (event) => { setbasic_pay(event.target.value);}; const[basic_pay_er,setbasic_pay_er]=useState();
 const[incentives,setincentives]=useState();const updateincentives = (event) => { setincentives(event.target.value); calculate(); }; const[incentives_er,setincentives_er]=useState();
 const[travel_allowance,settravel_allowance]=useState();const updatetravel_allowance = (event) => { settravel_allowance(event.target.value); calculate(); }; const[travel_allowance_er,settravel_allowance_er]=useState();
 const[dearance_allowance,setdearance_allowance]=useState();const updatedearance_allowance = (event) => { setdearance_allowance(event.target.value); calculate(); }; const[dearance_allowance_er,setdearance_allowance_er]=useState();
 const[pf_allowances,setpf_allowances]=useState();const updatepf_allowances = (event) => { setpf_allowances(event.target.value); calculate(); }; const[pf_allowances_er,setpf_allowances_er]=useState();
 const[salary_deductions,setsalary_deductions]=useState();const updatesalary_deductions = (event) => { setsalary_deductions(event.target.value); calculate(); }; const[salary_deductions_er,setsalary_deductions_er]=useState();
 const[salary_payable,setsalary_payable]=useState();const updatesalary_payable = (event) => { setsalary_payable(event.target.value); calculate(); }; const[salary_payable_er,setsalary_payable_er]=useState();
 const[salary_paid,setsalary_paid]=useState();const updatesalary_paid = (event) => { setsalary_paid(event.target.value); calculate(); }; const[salary_paid_er,setsalary_paid_er]=useState();
 //const[basic_salary,setbasic_salary]=useState();const updatebasic_salary = (event) => { setbasic_salary(event.target.value); calculate(); }; const[basic_salary_er,setbasic_salary_er]=useState();
 const[transaction_id,settransaction_id]=useState();const updatetransaction_id = (event) => { settransaction_id(event.target.value); calculate(); }; const[transaction_id_er,settransaction_id_er]=useState();
 const[mode,setmode]=useState();const updatemode = (event) => { setmode(event.target.value);}; const[mode_er,setmode_er]=useState();
 const[balance_salary,setbalance_salary]=useState();const updatebalance_salary = (event) => { setbalance_salary(event.target.value); calculate(); }; const[balance_salary_er,setbalance_salary_er]=useState();
 const[details,setdetails]=useState();const updatedetails = (event) => { setdetails(event.target.value);}; const[details_er,setdetails_er]=useState();
 const[bank_id,setbank_id]=useState();const updatebank_id = (event) => { setbank_id(event.target.value);}; const[bank_id_er,setbank_id_er]=useState();
 const[bank_name,setbank_name]=useState();const updatebank_name = (event) => { setbank_name(event.target.value);}; const[bank_name_er,setbank_name_er]=useState();

 
 //ERROR VARIABLES 
  const[error_msg,seterror_msg]=useState();

 //image upload
 //File Upload
 const [selectedFile, setSelectedFile] = React.useState(null);
 const handleFileChange = (event) => {  const file = event.target.files[0];  setSelectedFile(file); };
 const[attachment_link_er,setattachment_link_er]=useState();


 
 
//Variables for pagination
const [currentPage, setCurrentPage] = useState(1);
const [pageNumber, setpageNumber] = useState(1);

 //Error Variable
 const[errorvar,seterrorvar] = useState();
  
 //FILTER VARIABLES
//filter_val
const[filterval,setfilterval]=useState(0);

 
//Variables
const[data,setData] = useState([]);//SetData Variable


useEffect(()=>{
   
    getData();
    //getData1();
    
    },[])


    

//******************* GET BANKS DATA FUNCTION START  ********************//
function getData(){
 

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))){
 
 axios.post("crm-admin-get-banks-list", {
   Log_id:logid,
   Token:token,
   
   //Code Edits for pagination
   'page' : pageNumber,

   //Filters
   'Filter_val' :filterval,


   })
 .then((response) => {


  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("logName00");

     window.location.replace("/");
  }
  //Success Data Fetched
  if(response.data.successvar==1){
   //Setting Data
   setData(response.data.data.data);
  }

  //FILTER DATA
  //Validation Errors
  if(response.data.validatorerror){

    seterrorvar("Validation error!");

  }

  //Data Violation
  if(response.data.error==102){ 
  
     //Setting Filter Violation Errors
     seterrorvar("Violation Error");


  }


  //Cannot Fetch Data
  else{
   seterrorvar("Cannot Fetch Data !");
  }
 
 
 });
 
 }
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("logName00");

   window.location.replace("/");
 
  }
 
 }
  
 //******************* GET BANKS DATA FUNCTION END  ********************//


 
//******************* GET SALARY DATA FUNCTION START  ********************//
function getData1(){
 

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&(empId>0)){
 
 axios.post("crm-admin-create-employee-salary-default-load", {
   Log_id:logid,
   Token:token,
   Edit_id:empId


   })
 .then((response) => {

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("logName00");
     sessionStorage.removeItem("EditId00");
     window.location.replace("/");
  }

  //Data Not Found
  if((response.data.error)==103){  seterror_msg("Data Not Found for Scheme Id !");}

  //Success Data Fetched
  if(response.data.successvar==1){
   //Setting Data
   setprevious_salary_balance(response.data.previous_salary_balance);
   setbasic_pay(response.data.basic_pay);
   setincentives(response.data.incentives);
   settravel_allowance(response.data.travel_allowance);
   setdearance_allowance(response.data.dearance_allowance);
   setpf_allowances(response.data.pf_allowances);
   setsalary_deductions(response.data.salary_deductions);
   setsalary_deductions(response.data.salary_deductions);
   setsalary_payable(response.data.salary_payable);



  }

  //FILTER DATA
  //Validation Errors
  if(response.data.validatorerror){ seterror_msg("Validation error!"); }

  //Data Violation
  if(response.data.error==102){  seterror_msg("Violation Error"); }

 
 
 });
 
 }
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("logName00");
   sessionStorage.removeItem("EditId00");
   window.location.replace("/");
 
  }
 
 }
  
 //******************* GET SALARY DATA FUNCTION END  ********************//

 


  
  
function calculate(){
  
  //Fetching Values
  /*let loan_amount = (document.getElementById("loan_amount").value);if(loan_amount==" "){ loan_amount=0;}else{ loan_amount=parseFloat(loan_amount);}
  let interest_rate = (document.getElementById("interest_rate").value);if( interest_rate==" "){ interest_rate=0;}else{ interest_rate=parseFloat(interest_rate);}
  let pf_per = (document.getElementById("pf_per").value);if( pf_per==" "){ pf_per=0;}else{ pf_per=parseFloat(pf_per);}
  let pf_amount = (document.getElementById("pf_amount").value);if(pf_amount==" "){ pf_amount=0;}else{ pf_amount=parseFloat(pf_amount);}
  let gst_per = (document.getElementById("gst_per").value);if( gst_per==" "){ gst_per=0;}else{ gst_per=parseFloat(gst_per);}
  let gst_amount = (document.getElementById("gst_amount").value);if(gst_amount==" "){ gst_amount=0;}else{ gst_amount=parseFloat(gst_amount);}
  let death_insurance = (document.getElementById("death_insurance").value);if( death_insurance==" "){ death_insurance=0;}else{ death_insurance=parseFloat(death_insurance);}
  let health_insurance = (document.getElementById("health_insurance").value);if(health_insurance==" "){ health_insurance=0;}else{ health_insurance=parseFloat(health_insurance);}
  let total_additions = (document.getElementById("total_additions").value);if( total_additions==" "){ total_additions=0;}else{ total_additions=parseFloat(total_additions);}
  let loan_interest = (document.getElementById("loan_interest").value);if(loan_interest==" "){ loan_interest=0;}else{ loan_interest=parseFloat(loan_interest);}
  let repayable_amount = (document.getElementById("repayable_amount").value);if(repayable_amount==" "){ repayable_amount=0;}else{ repayable_amount=parseFloat(repayable_amount);}
  let emi = (document.getElementById("emi").value);if(emi==" "){ emi=0;}else{ emi=parseFloat(emi);}
  let processing_fee = (document.getElementById("processing_fee").value);if(processing_fee==" "){ processing_fee=0;}else{ processing_fee=parseFloat(processing_fee);}

  //Interest Calculation
 if(interest_rate>0){ loan_interest = loan_amount*interest_rate/100; }
 //PF Calculation
 if(pf_per>0){ pf_amount = loan_amount*pf_per/100; }

 let pre_additions = loan_interest+pf_amount+death_insurance+health_insurance;
 if(gst_per>0){ gst_amount = loan_amount*gst_per/100; }
 total_additions = pre_additions+gst_amount+processing_fee;
 repayable_amount = loan_amount+total_additions;
 emi = repayable_amount/duration;

setloan_interest(loan_interest);
setgst_amount(gst_amount);
setpf_amount(pf_amount);
settotal_additions(total_additions);
setrepayable_amount(repayable_amount);
setemi(emi);
 */

}


/***************** CREATE NEW SALARY FUNCTION START  ******************/
function create_salary(){

  
  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((logname!=null)||(logname!=" "))&&(empId>0)){


    //Setting Error Variables
    setdate_er(" ");settransaction_er(" ");setprevious_salary_balance_er(" "); setworking_days_er(" ");setbasic_pay_er(" ");setincentives_er(" ");settravel_allowance_er(" ");
    setdearance_allowance_er(" ");setpf_allowances_er(" ");setsalary_deductions_er(" ");setsalary_payable_er(" ");setsalary_paid_er(" ");settransaction_id_er(" ");
    setmode_er(" ");setdetails_er(" ");seterror_msg(" ");setbalance_salary_er();
   
    
//Fetching Form Data
let noerror = 0;
const $date = document.getElementById("date").value; if($date==""){ setdate_er("Date is required !");noerror = noerror+1; }//date
const $transaction = document.getElementById("transaction").value; if($transaction==""){ settransaction_er("Transaction is required !");noerror = noerror+1; }//transaction
const $previous_salary_balance = document.getElementById("previous_salary_balance").value; if($previous_salary_balance==""){ setprevious_salary_balance_er("Previous Salary Balance is required !");noerror = noerror+1; }//previous_salary_balance
const $working_days = document.getElementById("working_days").value; if($working_days==""){ setworking_days_er("Working Days is required !");noerror = noerror+1; }//working_days
const $basic_pay = document.getElementById("basic_pay").value; if($basic_pay==""){ setbasic_pay_er("Basic Pay is required !");noerror = noerror+1; }//basic_pay
const $incentives = document.getElementById("incentives").value; if($incentives==""){ setincentives_er("Incentives is required !");noerror = noerror+1; }//incentives
const $travel_allowance = document.getElementById("travel_allowance").value; if($travel_allowance==""){ settravel_allowance_er(" Travel Allowance is required !");noerror = noerror+1; }//travel_allowance
const $dearance_allowance = document.getElementById("dearance_allowance").value; if($dearance_allowance==""){ setdearance_allowance_er("Dearance Allowance is required !");noerror = noerror+1; }//dearance_allowance
const $pf_allowances = document.getElementById("pf_allowances").value; if($pf_allowances==""){ setpf_allowances_er("PF Allowances is required !");noerror = noerror+1; }//pf_allowances
const $salary_deductions = document.getElementById("salary_deductions").value; if($salary_deductions==""){ setsalary_deductions_er("Salary Deductions is required !");noerror = noerror+1; }//salary_deductions
const $salary_payable = document.getElementById("salary_payable").value; if($salary_payable==""){ setsalary_payable_er("Salary Payable is required !");noerror = noerror+1; }//salary_payable
const $salary_paid = document.getElementById("salary_paid").value; if($salary_paid==""){ setsalary_paid_er("Salary Paid is required !");noerror = noerror+1; }//salary_paid
const $transaction_id = document.getElementById("transaction_id").value; if($transaction_id==""){ settransaction_id_er("Transaction Id is required !");noerror = noerror+1; }//transaction_id
const $mode = document.getElementById("mode").value; if($mode==""){ setmode_er("Mode is required !");noerror = noerror+1; }//mode
const $details = document.getElementById("details").value; if($details==""){ setdetails_er("Details is required !");noerror = noerror+1; }//details
const $balance_salary = document.getElementById("balance_salary").value; if($balance_salary==""){ setbalance_salary_er("Balance Salary is required !");noerror = noerror+1; }//balance_salary
const $bank_id = document.getElementById("bank_id").value;// if($bank_id==""){ setbank_id_er("Bank is required !");noerror = noerror+1; }//bank_id



//No primary errors
if(noerror==0){

      //disabling button
      document.getElementById('emp-submit-btn').disabled = true; 

  //Sending Data through axios
  axios.post("crm-admin-create-employee-salary", { 
  Log_id:logid,
  Token:token,
  Employee_id:empId,
  Date:moment($date).format('YYYY-MM-DD'),
  Transaction:$transaction,
  Bank_id: $bank_id,
  Previous_salary_balance:$previous_salary_balance,
  Working_days:$working_days,
  Basic_pay:$basic_pay,
  Basic_salary:$basic_pay,
  Incentives:$incentives,
  Travel_allowance:$travel_allowance,
  Dearance_allowance:$dearance_allowance,
  PF_allowances:$pf_allowances,
  Salary_deductions:$salary_deductions,
  Salary_payable:$salary_payable,
  Salary_paid:$salary_paid,
  Transaction_id:$transaction_id,
  Balance_salary:$balance_salary,
  Mode:$mode,
  Details:$details,
  File_attachment:selectedFile,
 
  },
  {
    headers: { "Content-Type": "multipart/form-data" },
  }

    )
  
  .then((response) => {

       //enabling button
       document.getElementById('emp-submit-btn').disabled = false; 
  
  //Validation Errors
  if(response.data.validatorerror){
  setdate_er(response.data.validatorerror.date);
  settransaction_er(response.data.validatorerror.Transaction);
  setprevious_salary_balance_er(response.data.validatorerror.Previous_salary_balance);
  setworking_days_er(response.data.validatorerror.Working_days);
  setbasic_pay_er(response.data.validatorerror.Basic_pay);
  setincentives_er(response.data.validatorerror.Incentives);
  settravel_allowance_er(response.data.validatorerror.Travel_allowance);
  setdearance_allowance_er(response.data.validatorerror.Dearance_allowance);
  setpf_allowances_er(response.data.validatorerror.PF_allowances);
  setsalary_deductions_er(response.data.validatorerror.Salary_deductions);
  setsalary_payable_er(response.data.validatorerror.Salary_payable);
  setsalary_paid_er(response.data.validatorerror.Salary_paid);
  settransaction_id_er(response.data.validatorerror.Transaction_id);
  setmode_er(response.data.validatorerror.Mode);
  setdetails_er(response.data.validatorerror.Details);
  seterror_msg("Cannot Add Salary !");
  
  
  }
  //Invalid Entries
  if(response.data.error==102){ 
    setdate_er(response.data.date_er);
    settransaction_er(response.data.Transaction_er);
    setprevious_salary_balance_er(response.data.Previous_salary_balance_er);
    setworking_days_er(response.data.Working_days_er);
    setbasic_pay_er(response.data.Basic_pay_er);
    setincentives_er(response.data.Incentives_er);
    settravel_allowance_er(response.data.Travel_allowance_er);
    setdearance_allowance_er(response.data.Dearance_allowance_er);
    setpf_allowances_er(response.data.PF_allowances_er);
    setsalary_deductions_er(response.data.Salary_deductions_er);
    setsalary_payable_er(response.data.Salary_payable_er);
    setsalary_paid_er(response.data.Salary_paid_er);
    settransaction_id_er(response.data.Transaction_id_er);
    setmode_er(response.data.Mode_er);
    setdetails_er(response.data.Details_er);
  seterror_msg("Cannot Create Loan Scheme !");
  
  
  }
  
  //Branch Name or Branch Code Already Exists
  if(response.data.error==103){  seterror_msg(response.data.error_msg); return; }
  
  //Success
  if(response.data.successvar==1){ document.getElementById("success-card").style.display="block"; }

  //failure
  if(response.data.successvar==6){ seterror_msg("Loan Scheme Not Created !"); }

  //Session Expired
  if(response.data.successvar==100){ seterror_msg("Session Expired !");  }


  }    );
  
  }
  

  }
  //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
 sessionStorage.removeItem("logStatus00");
 sessionStorage.removeItem("logId00");
 sessionStorage.removeItem("logToken00");
 sessionStorage.removeItem("logName00");
 
 window.location.replace("/");
 
 
 }    

}

/***************** CREATE NEW SALARY FUNCTION END  ******************/



return(
    <>

<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Pragati Prathmik Sakh Sahkari Samiti Maryadit | Admin Dashboard</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>
    <div className="container-scroller">

   {/*}   <Sidebar /> */}
    <div className="container-fluid page-body-wrapper">


       <Navbar />
        {/*} partial */}
        <div className="main-panel">

        

          <div className="content-wrapper pb-0">

          

                 {/*  SUCCESS CARD START */}
                 <div class="d-flex justify-content-center">
               <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="success-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-success">Salary Added Successfully !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to continue.</p>
                      <a href="/adminpanel-dashboard-employee-salary"><button class="mt-3 btn btn-outline-success">Continue</button></a>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/*  SUCCESS CARD END */}


          <div className="page-header flex-wrap">
            <div className="header-left"></div>
              <div className="header-right d-flex flex-wrap mt-2 mt-sm-0">
                <div className="d-flex align-items-center">
                  <a href="#">
                    <p className="m-0 pe-3">ADMIN PANEL</p>
                  </a>
                  <a className="ps-3 me-4" href="#">
                    <p className="m-0">CREATE SALARY</p>
                  </a>
                </div>
          {/*}     <button type="button" className="btn btn-primary mt-2 mt-sm-0 btn-icon-text"  onClick={()=>{ 
               const createForm = document.getElementById('create-form');
               const currentDisplay = createForm.style.display;
               createForm.style.display = currentDisplay === 'none' ? 'block' : 'none'; }}>
                  <i className="mdi mdi-plus-circle"></i>New Loan Request</button>  */}
              </div>
            </div>


                <div class="card">
              
                  <div class="card-body" style={{textAlign:"left"}}>
                    <h4 class="card-title">Create New Salary</h4>
                    <div class="form-sample">
                      <p class="card-description">enter salary details here</p>
                      <code>{error_msg}</code>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Date</label>
                            <div class="col-sm-9">
                              <input type="date" class="form-control" id="date" value={date} onChange={updatedate} />
                              <code>{date_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Transaction</label>
                            <div class="col-sm-9">
                            <select class="form-control" style={{padding:"1rem"}} id="transaction" value={transaction} onChange={updatetransaction}>
                            <option> - select transaction - </option>
                            <option value="create salary">create salary</option>
                            <option value="create and pay salary">create and pay salary</option>
                            </select>
                            <code>{transaction_er}</code>
                            </div>
                          </div>
                        </div>   
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Previous Salary Balance</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="00000.00" id="previous_salary_balance" value={previous_salary_balance} onChange={updateprevious_salary_balance} />
                              <code>{previous_salary_balance_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Working Days</label>
                            <div class="col-sm-9">
                            <input type="text" class="form-control" placeholder="21" id="working_days" value={working_days} onChange={updateworking_days} />
                            <code>{working_days_er}</code>
                            </div>
                          </div>
                        </div>   
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Basic Pay</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="10000.00" id="basic_pay" value={basic_pay} onChange={updatebasic_pay} />
                              <code>{basic_pay_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Incentives</label>
                            <div class="col-sm-9">
                            <input type="text" class="form-control" placeholder="10000.00" id="incentives" value={incentives} onChange={updateincentives} />
                            <code>{incentives_er}</code>
                            </div>
                          </div>
                        </div>   
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Travel Allowance (T.A.)</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="500.00" id="travel_allowance" value={travel_allowance} onChange={updatetravel_allowance} />
                              <code>{travel_allowance_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Dearance Allowance (D.A.)</label>
                            <div class="col-sm-9">
                            <input type="text" class="form-control" placeholder="500.00" id="dearance_allowance" value={dearance_allowance} onChange={updatedearance_allowance} />
                            <code>{dearance_allowance_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">P.F. and Other Salary Add ons</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="1000.00" id="pf_allowances" value={pf_allowances} onChange={updatepf_allowances} />
                              <code>{pf_allowances_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Salary Deductions</label>
                            <div class="col-sm-9">
                            <input type="text" class="form-control" placeholder="1000.00" id="salary_deductions" value={salary_deductions} onChange={updatesalary_deductions} />
                            <code>{salary_deductions_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Total Salary Payable</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="25000.00" id="salary_payable" value={salary_payable} onChange={updatesalary_payable} />
                              <code>{salary_payable_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Salary to Pay</label>
                            <div class="col-sm-9">
                            <input type="text" class="form-control" placeholder="15000.00" id="salary_paid" value={salary_paid} onChange={updatesalary_paid} />
                            <code>{salary_paid_er}</code>
                            </div>
                          </div>
                        </div>   
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Balance Salary (c.f.)</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="10000.00" id="balance_salary" value={balance_salary} onChange={updatebalance_salary} />
                              <code>{balance_salary_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Transaction Id</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="TXN998876544" id="transaction_id" value={transaction_id} onChange={updatetransaction_id} />
                              <code>{transaction_id_er}</code>
                            </div>
                          </div>
                        </div>   
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Mode</label>
                            <div class="col-sm-9">
                              <select class="form-control" style={{padding:"1rem"}} id="mode" value={mode} onChange={updatemode}>
                              <option value="cash">cash</option>
                              <option value="bank">bank</option>
                              </select>
                              <code>{mode_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Bank</label>
                            <div class="col-sm-9">
                            <select class="form-control" style={{padding:"1rem"}} id="bank_id" value={bank_id} onChange={updatebank_id}>
                            <option value=""> -- select bank -- </option>
                            {
                       data.map((curElm,ind)=>{

                         return(
                         <>
                            <option value={curElm.Bank_id}> {curElm.Bank_name} </option>
                            </>
                         )
                         }
					   
	                  		 )
                         }
                            </select>
                            <code>{bank_id_er}</code>
                            </div>
                          </div>
                        </div> 
                        </div>
                                  
                        <div class="col-md-12">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Attachment</label>
                            <div class="col-sm-3">
                              <input type="file" class="form-control"  id="logo_link"  onChange={handleFileChange} multiple />
                              {selectedFile && <p>Selected file: {selectedFile.name}</p>}
                              <code>{attachment_link_er}</code>
                           
                            </div>
                          <div class="col-sm-5">
                          <p>Allowed File format (.png, .jpg), Max. file Size Allowed 1mb. </p>
                          </div>
                        </div>
                      </div>


                      <div class="form-group">
                        <label for="exampleTextarea1"> Salary Remarks</label>
                        <textarea  class="form-control" rows="2" placeholder="Add your Remarks here" id="details" value={details} onChange={updatedetails} />
                        <code>{details_er}</code>
                      </div>

                
                      
                        <button class="btn btn-lg btn-success" style={{fontWeight:"bold"}} id="emp-submit-btn" onClick={create_salary}>Submit</button>
                      
                       
    
                     
                   
                    </div>
                  </div>
                </div>

                </div>
          {/*} content-wrapper ends */}
          {/*} partial:partials/_footer.html */}
       {/*}   <footer className="footer">
            <div className="d-sm-flex justify-content-center justify-content-sm-between">
              <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2021 <a href="https://www.bootstrapdash.com/" target="_blank">BootstrapDash</a>. All rights reserved.</span>
              <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i className="mdi mdi-heart text-danger"></i></span>
            </div>
          </footer> */}
          {/*} partial */}
        </div>
        {/*} main-panel ends */}
      </div>
      {/*} page-body-wrapper ends */}
    </div>
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>
    </>

);
}
//}

export default Admin_create_salary;