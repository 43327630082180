import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';

import '../Resources/dashboard.css';

function Adminsidebar(){

return(
    <>
      {/*} partial:partials/_sidebar.html */}
      <nav class="sidebar sidebar-offcanvas" id="sidebar">
        <ul class="nav">
          <li class="nav-item">
            <a href="/adminpanel-login" class="nav-link flex-column">
           <div  /*class="nav-profile-image" */ > 
                <img src="images/crmlogo.png" alt="profile" style={{height:"9rem", width:"9rem"}} />
                {/*}change to offline or busy as needed*/}
            </div>
            {/*}  <div class="nav-profile-text d-flex ms-0 mb-3 flex-column">
                <span class="font-weight-semibold mb-1 mt-2 text-center">Sumit Enterprises</span>
                <span class="text-secondary icon-sm text-center">Id : 9887771</span>
              </div>*/}
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link d-block" href="/adminpanel-login">
            <div class="nav-profile-text d-flex ms-0 mb-3 flex-column">
            {/*}    <span class="font-weight-semibold mb-1 mt-2 text-center">Sumit Enterprises</span>
                <span class="text-secondary icon-sm text-center">Id : 9887771</span> */}
              </div>
            </a>
          </li>
          <hr/>
          <li class="pt-2 pb-1">
            <span class="nav-item-head">Dashboard</span>
          </li>
          <li class="nav-item">
          <a class="nav-link" href="/adminpanel-dashboard-customers">
              <i class="mdi mdi-account-multiple-outline menu-icon"></i>
              <span class="menu-title">Customers</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/adminpanel-dashboard-new-account-requests">
              <i class="mdi mdi-account-card-details menu-icon"></i>
              <span class="menu-title">New Account Requests</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/adminpanel-dashboard-cash">
              <i class="mdi mdi-cash-multiple menu-icon"></i>
              <span class="menu-title">Cash Management</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/adminpanel-dashboard-bank">
              <i class="mdi mdi-bank menu-icon"></i>
              <span class="menu-title">Bank Management</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/adminpanel-dashboard-incomes">
              <i class="mdi mdi-drawing-box menu-icon"></i>
              <span class="menu-title">Income Management</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/adminpanel-dashboard-expenses">
              <i class="mdi mdi-format-float-none menu-icon"></i>
              <span class="menu-title">Expenses Management</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/admin-dashboard-branch">
              <i class="mdi mdi-castle menu-icon"></i>
              <span class="menu-title">Branches</span>
            </a>
          </li> 
          <li class="nav-item">
            <a class="nav-link" href="/adminpanel-dashboard-employees">
              <i class="mdi mdi-account-circle menu-icon"></i>
              <span class="menu-title">Employees</span>
            </a>
          </li> 
          <li class="nav-item">
            <a class="nav-link" href="/adminpanel-dashboard-loan-payments">
              <i class="mdi mdi-chart-areaspline menu-icon"></i>
              <span class="menu-title">Loan EMIs</span>
            </a>
          </li>     
          <li class="nav-item">
            <a class="nav-link" href="/adminpanel-dashboard-loan-requests">
              <i class="mdi mdi-clipboard-check menu-icon"></i>
              <span class="menu-title">Loan Requests</span>
            </a>
          </li>
          <li class="nav-item" /* hidden={customer_history_pm==0} */>
            <a class="nav-link" href="/admin-dashboard-loan-disbursals">
              <i class="mdi mdi mdi-book-multiple-variant menu-icon"></i>
              <span class="menu-title">Loan Disbursals</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/admin-dashboard-loan-closure-requests">
              <i class="mdi mdi-animation menu-icon"></i>
              <span class="menu-title">Loan Closure Requests</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/adminpanel-dashboard-loan-schemes">
              <i class="mdi mdi-assistant menu-icon"></i>
              <span class="menu-title">Loan Schemes</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/admin-dashboard-transaction-history">
              <i class="mdi mdi-alarm-multiple menu-icon"></i>
              <span class="menu-title">Transaction History</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/admin-dashboard-customer-updations">
              <i class="mdi mdi-animation menu-icon"></i>
              <span class="menu-title">Customer History</span>
            </a>
          </li>
       {/*}   <li class="nav-item">
            <a class="nav-link" data-bs-toggle="collapse" href="#returns" aria-expanded="false" aria-controls="ui-basic">
              <i class="mdi mdi-truck-delivery menu-icon"></i>
              <span class="menu-title">Returns</span>
              <i class="menu-arrow"></i>
            </a>
            <div class="collapse" id="returns">
              <ul class="nav flex-column sub-menu">
                <li class="nav-item">
                  <a class="nav-link" href="/businesstools-user-salesreturn">Inwards (Sales Return)</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="/businesstools-user-purchasereturn">Outwards (Purchases Return)</a>
                </li>
              </ul>
            </div>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/businesstools-user-incomes">
              <i class="mdi mdi-briefcase-upload menu-icon"></i>
              <span class="menu-title">Incomes</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/businesstools-user-expenses">
              <i class="mdi mdi-briefcase-download menu-icon"></i>
              <span class="menu-title">Expenses</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" data-bs-toggle="collapse" href="#outstanding" aria-expanded="false" aria-controls="ui-basic">
              <i class="mdi mdi-crosshairs-gps menu-icon"></i>
              <span class="menu-title">Outstanding</span>
              <i class="menu-arrow"></i>
            </a>
            <div class="collapse" id="outstanding">
              <ul class="nav flex-column sub-menu">
                <li class="nav-item">
                  <a class="nav-link" href="/businesstools-user-outstanding-incomes">Outstanding Incomes</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="/businesstools-user-outstanding-expenses">Outstanding Expenses</a>
                </li>
              </ul>
            </div>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/businesstools-user-creditor">
              <i class="mdi mdi-account-network menu-icon"></i>
              <span class="menu-title">Creditors</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/businesstools-user-debtors">
              <i class="mdi mdi-account-card-details menu-icon"></i>
              <span class="menu-title">Debtors</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" data-bs-toggle="collapse" href="#taxes" aria-expanded="false" aria-controls="ui-basic">
              <i class="mdi mdi-chart-gantt menu-icon"></i>
              <span class="menu-title">GST and Taxes</span>
              <i class="menu-arrow"></i>
            </a>
            <div class="collapse" id="taxes">
              <ul class="nav flex-column sub-menu">
                <li class="nav-item">
                  <a class="nav-link" href="/businesstools-user-sgst">SGST</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="/businesstools-user-cgst">CGST</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="/businesstools-user-igst">IGST</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="/businesstools-user-tax">Taxes</a>
                </li>
              </ul>
            </div>
          </li> */}
        {/*}  <li class="nav-item">
            <a class="nav-link" href="pages/tables/basic-table.html">
              <i class="mdi mdi-tag-multiple menu-icon"></i>
              <span class="menu-title">Offers</span>
            </a>
          </li> */}
         
        </ul>
      </nav>
    </>

);

}

export default Adminsidebar;